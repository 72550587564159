import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config";
import print from "./modules/print";
import sets from "./modules/sets";
import user from "./modules/user";
import vessels from "./modules/vessels";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    print,
    sets,
    user,
    vessels,
    config,
  },
  strict: debug,
  plugins: [],
});
