<template>
  <div class="app-main admin">
    <main>
      <div class="admin-content">
        <h1 class="navbar-brand">Administration</h1>

        <b-navbar type="light" variant="light" toggleable="sm" class="p-2">
          <b-navbar-brand class="mb-0" tag="h2">Users</b-navbar-brand>

          <b-navbar-toggle target="users-btns-collapse"></b-navbar-toggle>

          <b-collapse is-nav id="users-btns-collapse">
            <b-navbar-nav class="ml-auto">
              <b-nav-form>
                <b-button
                  class="nowrap mr-2"
                  size="sm"
                  variant="outline-secondary"
                  v-b-modal.emailFormModal
                  @click="sendPreviewEmail()"
                >
                  <i class="far fa-envelope mr-1"></i>Site Preview Email
                </b-button>

                <b-button class="nowrap" variant="primary" size="sm" v-b-modal.addUserModal>
                  <i class="fas fa-plus mr-1"></i>Create New User
                </b-button>
              </b-nav-form>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <b-table
          outlined
          responsive
          selectable
          select-mode="single"
          hover
          :items="items"
          :fields="fields"
        >
          <template v-slot:cell(id)="data">
            <span class="nowrap">{{ data.value }}</span>
          </template>

          <template v-slot:cell(rKey)="data">
            <span class="nowrap">{{ data.value }}</span>
          </template>

          <template v-slot:cell(rc)="data">{{ data.value ? 'Yes' : 'No' }}</template>

          <template class="d-flex flex-nowrap" v-slot:cell(actions)="data">
            <div class="d-flex flex-nowrap">
              <b-button
                class="mr-2"
                size="sm"
                variant="outline-secondary"
                v-b-modal.userPermissionsModal
                @click="editUser(data.item)"
              >
                <i class="fas fa-pen mr-1"></i>Edit Permissions
              </b-button>

              <b-button
                v-show="data.item.rc"
                class="mr-1 nowrap"
                size="sm"
                variant="outline-secondary"
                v-b-modal.resetPasswordConfirmationModal
                @click="prePasswordReset(data.item)"
              >
                <i class="fas fa-redo-alt mr-1"></i>Reset Password
              </b-button>

              <b-button
                v-show="!data.item.rc"
                class="mr-1 nowrap"
                size="sm"
                variant="outline-secondary"
                v-b-modal.emailFormModal
                @click="sendRegistrationEmail(data.item)"
              >
                <i class="far fa-envelope mr-1"></i>Registration Email
              </b-button>
            </div>
          </template>
        </b-table>

        <b-card header-tag="header">
          <template #header>
            <h2 class="navbar-brand m-0">Data Export</h2>
          </template>

          Export data collections from the database. The data will be copied to the OS clipboard as JSON data. You can copy this to a file with a .json file extension.

          <b-card-body>
            <b-card-text>
              Exporting from these collections pull from pre-loaded data.
            </b-card-text>

            <b-button
              variant="primary"
              slot="footer"
              size="sm"
              class="ml-3"
              v-clipboard="() => dbCollectionConfig"
              v-clipboard:success="clipboardSuccessHandler"
            >Collection: config</b-button>

            <b-button
              variant="primary"
              slot="footer"
              size="sm"
              class="ml-3"
              v-clipboard="() => dbCollectionUsers"
              v-clipboard:success="clipboardSuccessHandler"
            >Collection: users</b-button>

            <b-button
              variant="primary"
              slot="footer"
              size="sm"
              class="ml-3"
              v-clipboard="() => dbCollectionVesselsMetadata"
              v-clipboard:success="clipboardSuccessHandler"
            >Collection: vessels-metadata</b-button>
          </b-card-body>
          
          <b-card-body>
            <b-card-text>
              Exporting from these collections results in a fresh DB pull since this data can change more frequently.
            </b-card-text>

            <b-button
              variant="primary"
              slot="footer"
              size="sm"
              class="ml-3"
              v-on:click="getDbCollectionUserData"
            >Collection: users-data</b-button>

            <b-button
              variant="primary"
              slot="footer"
              size="sm"
              class="ml-3"
              v-on:click="getDbCollectionVesselSetsData"
            >Collection: vessel-sets</b-button>
          </b-card-body>
          
        </b-card>
      </div>
    </main>

    <AddUserModal></AddUserModal>
    <UserPermissionsModal :user="userForModal"></UserPermissionsModal>
    <EmailFormModal :user="userForModal" :mode="modeForModal"></EmailFormModal>

    <b-modal
      id="resetPasswordConfirmationModal"
      title="Send Password Reset Email?"
      header-bg-variant="warning"
      @ok="resetPassword"
      ok-title="Yes"
      ok-variant="outline-secondary"
      cancel-title="No"
      cancel-variant="primary"
      centered
    >{{passwordResetMsg}}</b-modal>
  </div>
</template>

<script>
  import Vue from "vue";
  import firebase from "firebase/app";
  import "firebase/auth";
  import "firebase/firestore";

  import AddUserModal from "../components/AddUserModal";
  import UserPermissionsModal from "../components/UserPermissionsModal";
  import EmailFormModal from "../components/EmailFormModal";

  export default {
    name: "Admin",
    components: {
      AddUserModal,
      UserPermissionsModal,
      EmailFormModal
    },
    data() {
      return {
        userForModal: {},
        userForPwReset: {},
        modeForModal: "register",
        fields: [
          {
            key: "fName",
            label: "First Name",
            sortable: true,
            sortDirection: "desc"
          },
          {
            key: "lName",
            label: "Last Name",
            sortable: true,
            sortDirection: "desc"
          },
          {
            key: "id",
            label: "Email",
            sortable: true,
            sortDirection: "desc"
          },
          {
            key: "rKey",
            label: "Registration Key",
            sortable: true,
            sortDirection: "desc"
          },
          {
            key: "rc",
            label: "Registered",
            sortable: true,
            sortDirection: "desc"
          },
          { key: "actions", label: "Actions" }
        ]
      };
    },
    computed: {
      dbCollectionConfig: {
        get() {
          let output = {};
          output['assets'] = this.$store.state.config.assets;
          output['game-cards'] = this.$store.state.config.gameCards;
          output['lists'] = this.$store.state.config.lists;
          return JSON.stringify(output, null, 4);
        }
      },
      dbCollectionUsers: {
        get() {
          const output = this.$store.state.user.all;
          return JSON.stringify(output, null, 4);
        }
      },
      dbCollectionVesselsMetadata: {
        get() {
          const output = Vue.$vesselsService.vesselsMetadata;
          return JSON.stringify(output, null, 4);
        }
      },
      items() {
        return this.$store.state.user.all;
      },
      passwordResetMsg() {
        return this.userForPwReset && this.userForPwReset.id
          ? "Are you sure you want to send a password reset email to " +
              this.userForPwReset.id +
              "?"
          : "";
      }
    },
    methods: {
      clipboardSuccessHandler() {
        this.$toasted.show("Clipboard updated!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 3000
        });
      },
      copyToClipboard(output) {
        const el = document.createElement('textarea');
        el.addEventListener('focusin', e => e.stopPropagation());
        el.value = output;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.clipboardSuccessHandler();
      },
      editUser(user) {
        this.userForModal = user;
      },
      sendRegistrationEmail(user) {
        this.modeForModal = "register";
        this.userForModal = user;
      },
      sendPreviewEmail() {
        this.modeForModal = "preview";
        firebase
          .firestore()
          .collection("config")
          .doc("dm")
          .get()
          .then(dmDocRef => {
            if (dmDocRef.exists) {
              let configDm = dmDocRef.data();
              //Decode values
              this.userForModal = { id: atob(configDm.e), pw: atob(configDm.p) };
            }
          });
      },
      prePasswordReset(user) {
        this.userForPwReset = user;
      },
      resetPassword() {
        let user = this.userForPwReset;
        if (user.rc) {
          let self = this;
          let email = user.id;
          firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(function() {
              // Email sent.
              self.$toasted.show(
                "A password reset email has been sent to " + email,
                {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 3000
                }
              );
            })
            .catch(function(error) {
              console.error(error);
            });
        } else {
          alert(
            "The user is not yet registered. There is no need to send a password reset email at this time."
          );
        }
      },
      getDbCollectionUserData() {
        Vue.$userService.getCollectionUserData().then(
          resp => {
            let output = JSON.stringify(resp, null, 4);
            this.copyToClipboard(output);
          },
          err => {
            console.warn(err);
          }
        );
      },
      getDbCollectionVesselSetsData() {
        Vue.$userService.getCollectionUserVesselSetsData().then(
          resp => {
            let output = JSON.stringify(resp, null, 4);
            this.copyToClipboard(output);
          },
          err => {
            console.warn(err);
          }
        );
      }
    },
    created() {
      this.$store.dispatch("user/loadAllUsers");
    }
  };
</script>

<style lang="scss">
  .admin {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    main {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 0;
      

      .admin-content {
        padding: 1rem 2rem 2rem 2rem;

        nav {
          flex: 0 0 auto;
        }

        .nowrap {
          white-space: nowrap;
        }

        .table-responsive {
          min-height: 400px;
        }

        .card .card-footer {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button {
            flex: 0 0 auto;
          }
        }

        .card + .card {
          margin-top: 40px;
        }
      }
    }
  }
</style>
