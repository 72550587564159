import "firebase/firestore";
import LocalForage from "localforage";
import Vue from "vue";
import CommonConstants from "../../common/constants";

// initial state
const state = {
  filterKeyword: "",
  filterVesselClass: "",
  filterVesselNation: "",
  filterDataSetVersion: "",
  forPrint: [],
  selected: {},
  selectedVesselCardTemplate: "2-gun",
  vesselTableData: null,
};

// getters
const getters = {
  getVesselData: (/*state*/) => (vesselKey) => {
    let vMetadata = Vue.$vesselsService.getVesselMetadata(vesselKey);

    return Vue.$vesselsService.getVesselData(vMetadata);
  },
};

// actions
const actions = {
  cleanup({ commit }) {
    commit("cleanup");
  },
  clearCachedData({ commit }) {
    commit("clearVesselCardsCachedData");
    //commit("clearVesselsDetailCachedData");
  },
  clearCachedNationImageData({ commit }) {
    commit("clearCachedNationImageData");
  },
  clearCachedVesselImageData({ commit }, vesselCacheKey) {
    commit("clearCachedVesselImageData", vesselCacheKey);
  },
  printVessels({ commit }, vessels) {
    commit("setForPrint", vessels);
  },
  setFilter({ commit }, keyword) {
    commit("setFilter", keyword);
  },
  setVesselClassFilter({ commit }, filterVal) {
    commit("setVesselClassFilter", filterVal);
  },
  setVesselNationFilter({ commit }, filterVal) {
    commit("setVesselNationFilter", filterVal);
  },
  setDataSetVersionFilter({ commit }, filterVal) {
    commit("setDataSetVersionFilter", filterVal);
  },
  setSelected({ commit, getters }, vessel) {
    getters.getVesselData(vessel ? vessel.key : null).then(
      (vesselData) => {
        commit("setSelected", vesselData);
      },
      (err) => {
        console.error(err);
      }
    );
  },
  setSelectedVesselCardTemplate({ commit }, template) {
    commit("setSelectedVesselCardTemplate", template);
  },
  setVesselTableData({ commit }, vesselTableData) {
    commit("setVesselTableData", vesselTableData);
  },
};

// mutations
const mutations = {
  cleanup(state) {
    state.filterKeyword = "";
    state.selected = {};
    state.selectedVesselCardTemplate = "2-gun";
  },
  clearVesselCardsCachedData(state, verbose = true) {
    Vue.$vesselsService.clearLocalCache(verbose);
  },
  clearVesselsDetailCachedData(state, verbose = true) {
    let newCachedData = null;

    // Clear Vessels Data Sheet cached data
    try {
      LocalForage.setItem(
        CommonConstants.LF_VESSELS_DETAIL_CACHE,
        newCachedData
      ).then(() => {
        if (verbose) {
          Vue.toasted.show("Vessels detail cache successfully cleared!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 3000,
          });
        }
      });
    } catch (e) {
      console.error(
        "There was a problem clearing LocalForage data: " + e.message
      );
    }
  },
  clearCachedNationImageData() {
    Vue.$utilService.clearLocalCache(CommonConstants.LF_FLAG_IMG_CACHE);
  },
  clearCachedVesselImageData(state, vesselCacheKey) {
    if (vesselCacheKey) {
      // Clear a specific item in the cache
      Vue.$utilService.setLocalCacheItem(
        CommonConstants.LF_VESSELS_IMG_CACHE,
        vesselCacheKey,
        {}
      );
    } else {
      // Clear all items in the cache
      Vue.$utilService.clearLocalCache(CommonConstants.LF_VESSELS_IMG_CACHE);
    }
  },
  setFilter(state, keyword) {
    state.filterKeyword = keyword;
  },
  setVesselClassFilter(state, filterVal) {
    state.filterVesselClass = filterVal;
  },
  setVesselNationFilter(state, filterVal) {
    state.filterVesselNation = filterVal;
  },
  setDataSetVersionFilter(state, filterVal) {
    state.filterDataSetVersion = filterVal;
  },
  setForPrint(state, vessels) {
    state.forPrint = vessels;
  },
  setSelected(state, vessel) {
    state.selected = vessel;
  },
  setSelectedVesselCardTemplate(state, template) {
    state.selectedVesselCardTemplate = template;
  },
  setVesselTableData(state, vesselTableData) {
    state.vesselTableData = vesselTableData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
