<template>
  <div class="app-main home">
    <div class="home-content">
      <div class="welcome-msg" v-if="inDm">
        <h3>Preview Mode</h3>
        <p>You are viewing this site in Preview mode. Certain features may not be available in this mode that are available for registered users.</p>
      </div>
      <p class="welcome-msg">
        <span>Welcome to the Black Smoke Blue Water companion site.</span>

        <span v-if="!(currentUser && validUi)">
          &nbsp;Click the&nbsp;
          <b><em>Sign In</em></b>&nbsp; button in the header to sign into the site.
        </span>

        <span
          v-if="validUi"
        >&nbsp;Visit the&nbsp;<b><em>Vessels</em></b>&nbsp;page to view your vessel game cards and the&nbsp;<b><em>Vessel Sets</em></b>&nbsp;page to create custom groups of vessel game cards.</span>
      </p>

      <div class="cards">
        <b-card class="no-pad" v-if="validUi">
          <div class="card-content">
            <div class="left-panel" v-bind:title="vesselCountDesc">{{vesselCount}}</div>

            <div class="card-info">
              <h4>Vessels</h4>
              <p>Where you view and print your vessel game cards.</p>

              <div class="button-wrapper">
                <b-button variant="outline-secondary" size="sm" to="/vessels">My Vessels</b-button>
              </div>
            </div>
          </div>
        </b-card>

        <b-card class="no-pad" v-if="validUi">
          <div class="card-content">
            <div class="left-panel" v-bind:title="scenarioCountDesc">{{scenarioCount}}</div>

            <div class="card-info">
              <h4>Vessel Sets</h4>
              <p>Where you create groups of vessel game cards.</p>

              <div class="button-wrapper">
                <b-button variant="outline-secondary" size="sm" to="/vessel-sets">My Vessel Sets</b-button>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <GameLinks class="row-layout"></GameLinks>
    </div>

    <AppFooter></AppFooter>
  </div>
</template>

<script>
  import AppFooter from "../components/AppFooter.vue";
  import GameLinks from "../components/GameLinks.vue";
  import Vue from "vue";

  export default {
    name: "home",
    components: { AppFooter, GameLinks },
    data: function() {
      return {
        vesselCount: 0
      };
    },
    computed: {
      currentUser: {
        get() {
          return this.$store.state.user.current;
        }
      },
      validUi: {
        get() {
          return this.$store.state.user.userInfo;
        }
      },
      vesselCountDesc: function() {
        let desc = "You don't have any vessels.";

        if (this.vesselCount > 0) {
          desc =
            "You have access to " +
            this.vesselCount +
            (this.vesselCount === 0 || this.vesselCount > 1
              ? " vessels."
              : " vessel.");
        }

        return desc;
      },
      inDm: {
        get() {
          return this.$store.state.user.inDm;
        }
      },
      scenarioCount: function() {
        return Array.isArray(this.$store.state.sets.vesselSetKeys)
          ? this.$store.state.sets.vesselSetKeys.length
          : 0;
      },
      scenarioCountDesc: function() {
        let desc = "You haven't created any vessel sets.";

        if (this.scenarioCount > 0) {
          desc =
            "You have created " +
            this.scenarioCount +
            (this.scenarioCount === 0 || this.scenarioCount > 1
              ? " vessel sets."
              : " vessel set.");
        }

        return desc;
      }
    },
    created() {
      let self = this;
      // https://github.com/vuejs/vue-rx
      this.$subscribeTo(Vue.$vesselsService.vesselsMetadataSubject, function (vMetadata) {
        if (Array.isArray(vMetadata)) {
          self.vesselCount = vMetadata.length;
        }
      });
    }
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";
  .home {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .home-content {
      position: relative;
      flex: 1 1 auto;
      overflow-y: auto;
      background-color: $bsbw_blue_800;

      .welcome-msg {
        color: #f1f4f7;
        font-size: 1.1em;
        padding: 20px 20px 0 20px;
        width: 100%;
      }

      .cards,
      .game-links {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        justify-content: space-evenly;
        width: 100%;

        .card {
          width: 100%;
          max-width: 500px;
          min-height: 60px;
          margin: 20px 5px;
        }
      }

      .cards {
        .card {
          border: 2px solid #ffffff;

          &.no-pad .card-body {
            padding: 0;
          }

          .card-body {
            display: flex;
            flex-direction: column;

            .card-content {
              flex: 1 1 auto;
              display: flex;
              flex-direction: row;
              align-items: stretch;

              &.centered {
                justify-content: center;
                align-items: center;
              }

              .left-panel {
                flex: 0 0 125px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 125px;
                color: white;
                background-color: $bsbw_blue_800;
                font-weight: bold;
                border-radius: 4px 0 0 4px;
                font-size: 3em;
                overflow: hidden;
              }

              .card-info {
                flex: 1 1 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                margin: 20px;

                &.centered {
                  justify-content: center;
                  align-items: center;
                }

                p {
                  font-size: 0.9em;
                  margin: 0;
                  width: 100%;
                }

                .button-wrapper {
                  display: flex;
                  justify-content: center;
                  margin: 20px 0 0 0;
                }
              }

              .bsbw-logo {
                display: block;
                width: 105px;
                height: 105px;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }

    .app-footer {
      flex: 0 0 auto;
    }
  }
</style>
