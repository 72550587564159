<template>
  <div class="app-main">
    <div
      class="unsupported-browser"
    >You are viewing this site in an unsupported browser. Please open this site in another browser such as Chrome, Edge, Firefox, or Safari.</div>

    <AppFooter></AppFooter>
  </div>
</template>

<script>
  import AppFooter from "../components/AppFooter.vue";

  export default {
    name: "unsupportedBrowser",
    components: { AppFooter }
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";
  .app-main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100px;

    .unsupported-browser {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 40px;
      color: #ffffff;
      background-color: $bsbw_blue_800;
    }

    .app-footer {
      flex: 0 0 auto;
    }
  }
</style>
