<template>
  <div>
    <b-modal
      id="userPermissionsModal"
      ref="userPermissionsModal"
      title="Manage Existing User"
      header-bg-variant="dark"
      header-text-variant="light"
      size="lg"
      @ok="saveUserData"
      ok-title="Save"
      cancel-variant="outline-secondary"
      @shown="initializeModal"
    >
      <form>
        <b-form-group label="User" label-class="font-weight-bold" label-for="userInput">
          <b-form-input id="userInput" type="text" plaintext v-model="userFullName"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Is Site Admin?"
          label-class="font-weight-bold"
          label-for="isSaCheckbox"
        >
          <b-form-checkbox id="isSaCheckbox" v-model="isSa">User is a site admin</b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="In Demo Mode?"
          label-class="font-weight-bold"
          label-for="isDmCheckbox"
          v-show="!isSa"
        >
          <b-form-checkbox id="isDmCheckbox" v-model="inDm">User is in demo mode</b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="Vessels"
          label-class="font-weight-bold"
          label-for="vesselsList"
          v-show="!isSa"
        >
          <b-alert
            variant="primary"
            show
          >Click a vessel in the list below to toggle its selected state. Blue list items indicate the vessel is turned ON for this user.</b-alert>
          <div class="vessels-filter">
            <b-form-input
              type="text"
              v-model="filterKeyword"
              size="sm"
              placeholder="Filter by country or vessel name"
            ></b-form-input>
            <b-button
              variant="outline-secondary"
              size="sm"
              v-on:click="clearFilter"
              :disabled="!filterKeyword.length"
            >Clear</b-button>
          </div>

          <b-alert
            :show="filterKeyword.length && !filteredVessels.length"
            variant="danger"
          >No vessels were found that match your filter.</b-alert>

          <b-list-group id="vesselsList">
            <b-list-group-item
              v-bind:class="{ active: vessel.selected }"
              button
              v-for="vessel of filteredVessels"
              :key="vessel.data.key"
              v-on:click="toggleSelectedVessel($event, vessel)"
            >{{vessel.data.key}}</b-list-group-item>
          </b-list-group>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import firebase from "firebase/app";
  import "firebase/firestore";
  import Vue from "vue";

  export default {
    name: "UserPermissionsModal",
    props: ["user"],
    data: function() {
      return {
        filterKeyword: "",
        isSa: false,
        inDm: false,
        vessels: []
      };
    },
    computed: {
      filteredVessels() {
        let items = this.vessels;
        // Filter the list
        if (this.filterKeyword && this.filterKeyword.length) {
          items = items.filter(vessel => {
            return (
              vessel.data.label
                .toLowerCase()
                .indexOf(this.filterKeyword.toLowerCase()) >= 0
            );
          });
        }

        return items;
      },
      userFullName() {
        return (
          this.user.fName + " " + this.user.lName + " (" + this.user.id + ")"
        );
      }
    },
    methods: {
      initializeModal() {
        let self = this;
        this.clearFilter();

        //Make sure the vessels list is set up
        self.initializeVesselsList();

        self.vessels.forEach(vessel => {
          vessel.selected = false;
        });

        firebase
          .firestore()
          .collection("permissions")
          .doc(self.user.id)
          .get()
          .then(permsDocRef => {
            if (permsDocRef.exists) {
              let userPerms = permsDocRef.data();

              self.isSa = !!(userPerms.sap && userPerms.sap.sa);
              self.inDm = !!(userPerms.sap && userPerms.sap.dm);

              self.vessels.forEach(vessel => {
                vessel.selected = !!(
                  Array.isArray(userPerms.vessels) &&
                  userPerms.vessels.indexOf(vessel.data.key) > -1
                );
              });
            }
          });
      },
      initializeVesselsList() {
        if (!this.vessels.length) {
          let vesselsForDisplay = [];
          let allVessels = Vue.$vesselsService.getVesselsMetadata();

          if (Array.isArray(allVessels)) {
            allVessels.forEach(aVessel => {
              vesselsForDisplay.push({
                selected: false,
                data: { key: aVessel.key, label: aVessel.label }
              });
            });
          }

          this.vessels = vesselsForDisplay;
        }
      },
      clearFilter() {
        this.filterKeyword = "";
      },
      saveUserData(event) {
        event.preventDefault();

        if (this.user) {
          let userInfo = this.user;
          let newPerms = {};

          if (this.isSa) {
            newPerms.sap = { sa: true };
          } else {
            let selectedVessels = this.vessels
              .filter(vessel => vessel.selected)
              .map(vessel => vessel.data.key);
            newPerms.vessels = selectedVessels;
            newPerms.vesselsLastUpdated = new Date();
          }

          if (this.inDm) {
            let sap = newPerms.sap || {};
            sap.dm = true;
            newPerms.sap = sap;
          }

          firebase
            .firestore()
            .collection("permissions")
            .doc(userInfo.id)
            .set(newPerms, { merge: false })
            .then(() => {
              Vue.toasted.show(
                "The permissions record for " +
                  userInfo.id +
                  " has been successfully updated.",
                {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 3000
                }
              );

              this.$refs.userPermissionsModal.hide();
            });
        } else {
          alert(
            "The user data supplied to this modal is incomplete. The Save action was not performed."
          );
        }
      },
      toggleSelectedVessel(event, vessel) {
        event.preventDefault();
        vessel.selected = !vessel.selected;
      }
    }
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";
  #userPermissionsModal {
    .modal-body {
      min-height: 0;
      max-height: 80vh;
      overflow-y: auto;

      .alert {
        font-size: 0.8em;
        padding: 10px;
      }

      .vessels-filter {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 20px;

        input {
          margin-right: 10px;
        }
      }

      .reset-pass-wrapper {
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-end;
      }

      .list-group {
        max-height: 400px;
        overflow-y: auto;

        .list-group-item {
          flex: 0 0 auto;
          &:hover {
            background-color: $bsbw_blue_400;
            cursor: pointer;
          }

          &.active {
            background-color: $bsbw_blue_800;
            border-color: $bsbw_blue_800;
          }
        }
      }
    }
  }
</style>
