// initial state
const state = {
  config: null,
  isOpen: false
};

// getters
const getters = {};

// actions
const actions = {
  openPrintPreview({ commit }, config) {
    commit("initializePrintPreview", config);
  },
  cleanup({ commit }) {
    commit("cleanup");
  },
  closePrintPreview({ commit }) {
    commit("cleanup");
  }
};

// mutations
const mutations = {
  cleanup(state) {
    state.config = null;
    state.isOpen = false;
  },
  initializePrintPreview(state, config) {
    state.config = config;
    state.isOpen = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
