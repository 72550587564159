<template>
  <div>
    <img :src="gcImgPath" alt="Game Card" :title="gcImgLabel" @error="fixBrokenLink" :style="inlineStyle" v-if="gcImgPath !== null && (!gcImgData || showAll === true)">
    <img :src="gcImgData" alt="Game Card (base64)" :title="gcImgLabel" :style="inlineStyle" v-if="gcImgData !== null || showAll === true">
  </div>
</template>

<script>
  import Vue from "vue";
  import firebase from "firebase/app";
  import "firebase/storage";
  import CommonConstants from '../common/constants';

  let assets = require.context("@/assets", true, /\.jpg$/);

  export default {
    name: "GameCardImg",
    props: ["gameCardKey", "gameCardType", "gameCardConfig", "gameCardExtType", "inlineStyle", "showAll"],
    emits: ['loaded'],
    data: function() {
      return {
        gcImgPath: null,
        gcImgLabel: null,
        gcImgData: null
      };
    },
    methods: {
      fixBrokenLink() {
        this.setDefaultPlaceholder();
      },
      setDefaultPlaceholder() {
        this.gcImgPath = assets("./placeholder.jpg");
        this.gcImgData = null;
      },
      saveToLocalCache(cacheItemKey, cacheItemData) {
        Vue.$utilService.setLocalCacheItem(CommonConstants.LF_GAME_CARDS_CACHE, cacheItemKey, cacheItemData);
      },
      downloadImageFromGCPStorageBucket(imgRef, imgStorageBucketRef, imgStorageBucketMetadataLastUpdated) {
        let self = this;
        
        Vue.$utilService.downloadImageFromGCPStorageBucket(imgRef, imgStorageBucketRef, imgStorageBucketMetadataLastUpdated, CommonConstants.LF_GAME_CARDS_CACHE)
          .then(
            (resp) => {
              self.gcImgData = resp.imgData;
              self.$emit('loaded', { imgRef: imgRef, imgData: resp.imgData, isPlaceholder: false });
            },
            () => {
              self.setDefaultPlaceholder();
              self.$emit('loaded', { imgRef: imgRef, imgData: null, isPlaceholder: true });
            }
          );
      },
      setGameCardImgLabel() {
        this.gcImgLabel = this.gameCardConfig && typeof this.gameCardConfig.label == 'string' && this.gameCardConfig.label.length > 0 ? this.gameCardConfig.label : 'Game Card';
      },
      setGameCardImgPath() {
        let self = this;
        let assetsConfig = this.$store.state.config.assets;

        if(this.gameCardType && this.gameCardConfig && assetsConfig) {
          //console.log("==> gcType: ", this.gameCardType);
          //console.log("==> gcConfig: ", this.gameCardConfig);
          //console.log("==> assetsConfig: ", assetsConfig);
          try {
            //:src="assets.base + assets[gcType.key] + gameCard.assetRef + assets[gcType.key + 'SvgExt']"
            const imgKey = this.gameCardConfig.assetRef;
            const imgBasePath = assetsConfig[this.gameCardType.key];
            const imgExtSuffix = this.gameCardExtType == 'SVG' ? 'SvgExt' : 'ImgExt';
            const imgExt = assetsConfig[this.gameCardType.key + imgExtSuffix];

            // Check if an asset exists for this flag in the GCP storage bucket
            let imgStorageBucketRef = firebase.storage().ref().child(imgBasePath + imgKey + imgExt);
            imgStorageBucketRef.getMetadata()
              .then(
                (metadataResp) => {
                  // File exists in GCP storage bucket.
                  self.gcImgPath = assetsConfig !== null ? (assetsConfig.base + imgBasePath + imgKey + imgExt) : ("https://storage.cloud.google.com/" + metadataResp.bucket + "/" + metadataResp.fullPath);
                  self.gcImgData = null;

                  // Check for corresponding data in the browser's local cache
                  Vue.$utilService.getLocalCacheItem(CommonConstants.LF_GAME_CARDS_CACHE, imgKey)
                    .then((cachedData) => {
                      if(cachedData && cachedData.cacheItemData && cachedData.cacheItemData.gcpStorageLastUpdated === metadataResp.updated && cachedData.cacheItemData.data) {
                        // Use the img data stored in the browser's local cache
                        self.gcImgData = cachedData.cacheItemData.data;
                        self.$emit('loaded', { imgRef: imgKey, imgData: cachedData.cacheItemData.data, isPlaceholder: false });
                      } else {
                        // We need to update the browser's local cache. Download the image from the GCP storage bucket.
                        self.downloadImageFromGCPStorageBucket(imgKey, imgStorageBucketRef, metadataResp.updated);
                      }
                    }, (e) => {
                      if(e.status === 404) {
                        // A corresponding record was not found in the browser's local storage. Download the image from the GCP storage bucket.
                        self.downloadImageFromGCPStorageBucket(imgKey, imgStorageBucketRef, metadataResp.updated);
                      }
                    });
                },
                (/* metadataErr */) => {
                  // There was a problem accessing the requested file metadata in the GCP storage bucket
                  self.setDefaultPlaceholder();
                  self.$emit('loaded', { imgRef: imgKey, imgData: null, isPlaceholder: true });
                });

          } catch (e) {
            this.setDefaultPlaceholder();
            self.$emit('loaded', { imgRef: null, imgData: null, isPlaceholder: true });
          }
        } else {
          this.setDefaultPlaceholder();
          self.$emit('loaded', { imgRef: null, imgData: null, isPlaceholder: true });
        }
      }
    },
    watch: {
      gameCardKey: function(/*nation*/) {
        this.setGameCardImgPath();
        this.setGameCardImgLabel();
      }
    },
    created() {
      this.setGameCardImgPath();
      this.setGameCardImgLabel();
    }
  };
</script>
