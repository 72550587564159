<template>
  <div>
    <b-modal
      id="vesselCardModal"
      v-bind:title="vesselLabel"
      header-bg-variant="dark"
      header-text-variant="light"
      @shown="initializeModal"
      ok-only
      ok-title="Close"
      size="lg"
    >
      <VesselCard :vessel-key="vesselKey" :in-three-gun-mode="!!inThreeGunMode" resize-to-fit="true"></VesselCard>
    </b-modal>
  </div>
</template>

<script>
  import VesselCard from "../components/VesselCard";

  export default {
    name: "VesselCardModal",
    props: ["vesselKey", "inThreeGunMode"],
    components: { VesselCard },
    data: function() {
      return {
        vesselLabel: "Vessel Card"
      };
    },
    methods: {
      initializeModal() {
        if (this.vesselKey) {
          this.vesselLabel = this.vesselKey.replace("_", " - ");
        }
      }
    }
  };
</script>

<style lang="scss">
  #vesselCardModal {
    .modal-body {
      min-height: 0;
      max-height: 80vh;
      overflow-y: auto;
    }
  }
</style>
