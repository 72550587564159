<template>
  <div class="app-main">
    <div class="login">
      <div class="login-wrapper">
        <b-card class="login-card" title="Sign In">
          <p>Enter your Black Smoke Blue Water companion site login information below. If you don't have an account, please visit the game's storefront for more information.</p>
          <b-form-input type="text" v-model="email" placeholder="Email"></b-form-input>
          <b-form-input type="password" v-model="password" placeholder="Password"></b-form-input>

          <div class="button-wrapper">
            <b-button
              class="mr-2"
              variant="light"
              size="sm"
              v-if="showResetPass"
              v-b-modal.resetPasswordModal
            >Reset Password</b-button>

            <b-button
              variant="primary" 
              size="sm" 
              type="submit" 
              v-on:click="onLogIn">Sign in</b-button>
          </div>
        </b-card>
      </div>

      <GameLinks class="col-layout"></GameLinks>
    </div>

    <AppFooter></AppFooter>

    <b-modal
      id="resetPasswordModal"
      title="Reset Password"
      header-bg-variant="light"
      ok-only
      centered
    >
      At this time, you will need to email the
      <a
        v-bind:href="passwordResetMailto"
      >site administrator</a> in order to reset the password for your registered account. You will need to provide the email address and registration key associated with your account.
    </b-modal>
  </div>
</template>

<script>
  import AppFooter from "../components/AppFooter.vue";
  import firebase from "firebase/app";
  import "firebase/auth";
  import GameLinks from "../components/GameLinks.vue";

  export default {
    name: "login",
    components: { AppFooter, GameLinks },
    data() {
      return {
        email: "",
        password: "",
        showResetPass: false
      };
    },
    computed: {
      passwordResetMailto: {
        get() {
          let mailto = "bsbw-admin@deadlywarfaregames.com";
          let subject =
            "BSBW%20Companion%20Site%20-%20Request%20to%20Reset%20Account%20Password";
          let body =
            "I'm%20requesting%20a%20password%20reset%20email%20for%20my%20Black%20Smoke%20Blue%20Water%20companion%20site%20" +
            this.email +
            "%20account.%20My%20account%20registration%20key%20is%20___________.";

          return "mailto:" + mailto + "?subject=" + subject + "&body=" + body;
        }
      }
    },
    methods: {
      onLogIn: function() {
        let self = this;
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(
            () => {
              self.showResetPass = false;
              this.$store.dispatch("user/initialize");
            },
            err => {
              if (err.code === "auth/wrong-password") {
                self.showResetPass = true;
              }
              alert(
                "There was a problem authenticating using the information you provided. " +
                  err.message
              );
            }
          );
      }
    }
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";
  .app-main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100px;

    .login {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 40px 20px;
      overflow-y: auto;
      background-color: $bsbw_blue_800;

      .login-wrapper {
        flex: 1 1 auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 20px;
      }

      .login-card {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        min-height: 60px;
        border: 2px solid #fff;

        input {
          width: 100%;
          margin-bottom: 10px;
        }

        .button-wrapper {
          display: flex;
          justify-content: flex-end;
        }
      }

      .game-links {
        flex: 1 1 auto;
      }
    }

    .app-footer {
      flex: 0 0 auto;
    }
  }
</style>
