<template>
  <div class="game-links">
    <b-card class="no-pad" v-if="showStoreCard">
      <div class="card-content">
        <div class="left-panel">
          <img src="../assets/bsbw-site-logo.jpg" title="BSBW Logo" class="bsbw-logo">
        </div>

        <div class="card-info centered">
          <p>Be sure to visit the Black Smoke Blue Water storefront for product updates and to build out your fleet!</p>

          <div class="button-wrapper">
            <b-button
              href="https://blacksmokebluewater.miiduu.com/"
              target="_blank"
              variant="outline-secondary"
              size="sm"
            >
              Visit Storefront
              <i class="fas fa-external-link-alt ml-1"></i>
            </b-button>
          </div>
        </div>
      </div>
    </b-card>

    <b-card class="no-pad">
      <div class="card-content centered">
        <a href="https://www.facebook.com/blacksmoke.bluewater/" class="fbr" target="_blank" aria-label="Find us on Facebook at Black Smoke Blue Water">
          <img src="FindUs-FB-RGB-BRC-Site-500.png" alt="Find Us On Facebook Logo" height="40">
        </a>
      </div>
    </b-card>
  </div>
</template>

<script>
  export default {
    name: "GameLinks",
    data: function() {
      return {
        showStoreCard: false
      };
    }
  };
</script>

<style lang="scss" scoped>
  @import "../assets/bsbw.globals.scss";
  .game-links {
    display: flex;

    &.col-layout {
      flex-direction: column;
      align-items: center;

      .card + .card {
        margin-top: 20px;
      }

      .card {
        max-width: 400px;
        min-height: 60px;
      }
    }

    .card {
      width: 100%;
      border: 2px solid #fff;

      &.no-pad .card-body {
        padding: 0;
      }

      .button-wrapper {
        display: flex;
        justify-content: flex-end;
      }

      .card-body {
        display: flex;
        flex-direction: column;

        .card-content {
          flex: 1 1 auto;
          display: flex;
          flex-direction: row;
          align-items: stretch;

          &.centered {
            justify-content: center;
            align-items: center;
          }

          .left-panel {
            flex: 0 0 125px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 125px;
            color: white;
            background-color: $bsbw_blue_800;
            font-weight: bold;
            font-size: 3em;
            border-radius: 4px 0 0 4px;
            overflow: hidden;
          }

          .card-info {
            flex: 1 1 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            margin: 20px;

            &.centered {
              justify-content: center;
              align-items: center;
            }

            p {
              font-size: 0.9em;
              margin: 0;
              width: 100%;
            }

            .button-wrapper {
              display: flex;
              justify-content: center;
              margin: 20px 0 0 0;
            }
          }

          .bsbw-logo {
            display: block;
            width: 105px;
            height: 105px;
            border-radius: 4px;
          }
        }
      }
    }

    .fbr {
      display: flex;
      text-decoration: none;
      padding: 3rem 2rem;

      &:hover {
        opacity: 0.7;
      }

      .cls-1 {
        fill: #4267b2;
      }

      .cls-2 {
        fill: #fff;
      }
    }
  }
</style>

