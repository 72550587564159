import _ from "lodash";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import LocalForage from "localforage";
import Vue from "vue";
import { BehaviorSubject } from "rxjs";


class VesselsService {
  /**
   * A list of basic vessel data (_lastUpdated, _version, _status, cls, key, nat, label).
   * This is used to list the vessels assigned to the user.
   */
  vesselsMetadata = null;

  vesselsMetadataMap = null;

  vesselsMetadataSubject = new BehaviorSubject(null);

  /**
   * A vessels metadata object to fall back to.
   * This will be set when the user first logs in and will be based on a data pull from the DB.
   * - vesselsMetadataSource: { localCacheKey: string, label: string, vesselKeys: Array<string> }
   */
  vesselsMetadataSources = {
    default: null,
  };

  vesselsMetadataSourcesSubject = new BehaviorSubject(null);

  selectedVesselsMetadataSource = null;

  vesselDataVersions = null;

  vesselDataVersionsSubject = new BehaviorSubject(null);

  getVesselsMetadataByVersion(dataVersion) {
    return firebase
      .firestore()
      .collection("vessels-metadata")
      .doc(dataVersion)
      .get()
      /*.then((vMetadataRef) => {
        let vMetadata = vMetadataRef.data();
        //vMetadata will be {keys, lastupdated}
      })*/;
  }

  /*constructor() {
    console.log("VesselsService");
  }*/

  /**
   * Prepares the vessel metadata object and map based on a list of vessel keys.
   * @param {*} vMetadata An Object with the following props: { localCacheKey: string, label: string, vesselKeys: Array<string> }
   */
  initializeVesselsMetadata(vMetadata) {
    if (
      this.selectedVesselsMetadataSource &&
      vMetadata &&
      vMetadata.localCacheKey ===
        this.selectedVesselsMetadataSource.localCacheKey
    ) {
      return;
    }

    let vesselKeys = [];
    let vesselsMap = {};
    if (Array.isArray(vMetadata.vesselKeys)) {
      vMetadata.vesselKeys.forEach((vessel) => {
        let modVessel = Object.assign({}, vessel, {
          label: vessel.nat + " - " + vessel.cls,
        });

        vesselKeys.push(modVessel);
        vesselsMap[vessel.key] = modVessel;
      });

      // Sort the vessels list before setting
      vesselKeys.sort(Vue.$utilService.sortVesselsAlphaNumeric);
    }

    if (!this.vesselsMetadataSources.default) {
      // Set the default vessels metadata source to the vessel set loaded from the DB when the user first logged in.
      this.vesselsMetadataSources.default = vMetadata;
    } else if (
      this.vesselsMetadataSources.default &&
      vMetadata.localCacheKey !==
        this.vesselsMetadataSources.default.localCacheKey
    ) {
      // Add this metadata to the sources object
      this.vesselsMetadataSources[vMetadata.localCacheKey] = vMetadata;
    }

    this.selectedVesselsMetadataSource = vMetadata;
    this.vesselsMetadata = vesselKeys;
    this.vesselsMetadataMap = vesselsMap;
    this.vesselsMetadataSubject.next(vesselKeys);
    this.vesselsMetadataSourcesSubject.next(this.getVesselsMetadataSources());
  }

  updateVesselsMetadata(vessel) {
    if (Array.isArray(this.vesselsMetadata)) {
      let vMatch = this.vesselsMetadata.find(
        (tVessel) => tVessel.key === vessel.key
      );

      if (vMatch) {
        vMatch._lastUpdated = vessel._lastUpdated;

        // TODO: if this doesn't trigger a change in listening views, we may need to copy the array
        this.vesselsMetadataSubject.next([...this.vesselsMetadata]);
      } else {
        // Add a label to the new vessel's metadata
        let modVessel = Object.assign({}, vessel, {
          label: vessel.nat + " - " + vessel.cls,
        });

        // Add to the list
        let _vMetadata = [...this.vesselsMetadata].concat([modVessel]);
        _vMetadata.sort(Vue.$utilService.sortVesselsAlphaNumeric);
        this.vesselsMetadata = _vMetadata;
        this.vesselsMetadataMap[vessel.key] = modVessel;
        this.vesselsMetadataSubject.next([...this.vesselsMetadata]);
      }
    }
  }

  removeVesselFromMetadata(vesselKey) {
    if (Array.isArray(this.vesselsMetadata)) {
      let filteredMetadata = this.vesselsMetadata.filter(
        (tVessel) => tVessel.key !== vesselKey
      );

      this.vesselsMetadata = filteredMetadata;
      delete this.vesselsMetadataMap[vesselKey];
      this.vesselsMetadataSubject.next([...filteredMetadata]);
    }
  }

  getVesselsMetadata() {
    if (!Array.isArray(this.vesselsMetadata)) {
      return [];
    }
    return this.vesselsMetadata;
  }

  getVesselsMetadataSources() {
    let sources = [];
    var prop = null;
    for (prop in this.vesselsMetadataSources) {
      if (Object.prototype.hasOwnProperty.call(this.vesselsMetadataSources, prop)) {
        sources.push(this.vesselsMetadataSources[prop]);
      }
    }
    return sources;
  }

  filterVesselsMetadata(vessels, filterOptions) {
    let items = vessels;
    // Filter the list
    if (filterOptions) {
      let vesselClass = filterOptions.vesselClass;
      let vesselNation = filterOptions.vesselNation;
      let dataSetVersion = filterOptions.dataSetVersion;

      let vesselClassEntered =
        typeof vesselClass === "string" && vesselClass.length > 0;
      let vesselNationEntered =
        typeof vesselNation === "string" && vesselNation.length > 0;
      let dataSetVersionEntered =
        typeof dataSetVersion === "string" && dataSetVersion.length > 0;

      if (vesselClassEntered) {
        items = items.filter((vessel) => {
          let vesselMetadata = vessel;
          if (!Object.prototype.hasOwnProperty.call(vessel, "cls") && vessel.key) {
            vesselMetadata = this.getVesselMetadata(vessel.key);
          }

          return (
            vesselMetadata &&
            vesselMetadata.cls &&
            vesselMetadata.cls
              .toLowerCase()
              .indexOf(vesselClass.toLowerCase()) >= 0
          );
        });
      }

      if (vesselNationEntered) {
        items = items.filter((vessel) => {
          let vesselMetadata = vessel;
          if (!Object.prototype.hasOwnProperty.call(vessel, "nat") && vessel.key) {
            vesselMetadata = this.getVesselMetadata(vessel.key);
          }

          return (
            vesselMetadata &&
            vesselMetadata.nat &&
            vesselMetadata.nat.toLowerCase() === vesselNation.toLowerCase()
          );
        });
      }

      if (dataSetVersionEntered) {
        items = items.filter((vessel) => {
          let vesselMetadata = vessel;
          if (!Object.prototype.hasOwnProperty.call(vessel, "_version") && vessel.key) {
            vesselMetadata = this.getVesselMetadata(vessel.key);
          }

          return (
            vesselMetadata &&
            vesselMetadata._version &&
            vesselMetadata._version.toLowerCase() ===
              dataSetVersion.toLowerCase()
          );
        });
      }
    }

    return items;
  }

  getVesselMetadata(vesselKey) {
    if (!vesselKey) {
      return null;
    }

    if (this.vesselsMetadataMap) {
      return Object.prototype.hasOwnProperty.call(this.vesselsMetadataMap, vesselKey)
        ? this.vesselsMetadataMap[vesselKey]
        : null;
    }

    let match = null;

    if (Array.isArray(this.vesselsMetadata)) {
      match = this.vesselsMetadata.find((vessel) => {
        return vessel.key === vesselKey;
      });
    }

    return match;
  }

  setVesselsDataVersions(versions) {
    this.vesselDataVersions = [
      { value: "", text: "Select vessel dataset version" },
    ].concat(versions);
    this.vesselDataVersionsSubject.next(this.vesselDataVersions);
  }

  getVesselsDataVersions() {
    return this.vesselDataVersions;
  }

  getVesselLabel(vesselKey) {
    let vesselMetadata = this.getVesselMetadata(vesselKey);
    return vesselMetadata
      ? vesselMetadata.label + " (" + vesselMetadata._version + ")"
      : "";
  }

  /**
   * Load data for a particular vessel given its metadata.
   */
  getVesselData(vesselMetadata, pullFromDb = false) {
    let self = this;
    let vesselKey = vesselMetadata ? vesselMetadata.key : null;
    let vesselTableDataPromise = new Promise(function(resolve, reject) {
      if (!vesselKey) {
        resolve(null);
      } else {
        const continueWithGet = (localVesselCardData = null) => {
          //Load the vessel's data from the DB
          let db = firebase.firestore();
          let vesselDocRef = db.collection("vessels").doc(vesselKey);
          if (vesselDocRef) {
            vesselDocRef
              .get()
              .then((vesselRef) => {
                if (vesselRef.exists) {
                  let vesselData = vesselRef.data();
                  let dbVesselCardData = {};
                  dbVesselCardData[vesselKey] = vesselData;
                  let newCachedData = Object.assign(
                    {},
                    localVesselCardData,
                    dbVesselCardData
                  );
  
                  try {
                    LocalForage.setItem(
                      self.selectedVesselsMetadataSource.localCacheKey,
                      newCachedData
                    ).then(() => {
                      console.log(
                        "Added",
                        vesselKey,
                        "data to LocalForage cache."
                      );
                    });
                  } catch (e) {
                    console.error(
                      "There was a problem setting LocalForage data: " + e.message
                    );
                  }
  
                  resolve(vesselData);
                } else {
                  reject(
                    "The document for vessel " + vesselKey + " does not exist."
                  );
                }
              })
              .catch(function(error) {
                reject(
                  "There was a problem loading the document for vessel " +
                    vesselKey +
                    ": " +
                    error
                );
              });
          }
        };
  
        // Check local storage before pulling from the database
        try {
          LocalForage.getItem(
            self.selectedVesselsMetadataSource.localCacheKey
          ).then(
            (cardData) => {
              if (!(cardData && Object.prototype.hasOwnProperty.call(cardData, vesselKey))) {
                // The vessel's data was not found in local storage. Pull from the DB.
                continueWithGet(cardData);
              } else {
                let vData = cardData[vesselKey];
                let vDataLastUpdated = new Date(vData._lastUpdated);
                let vMetadataLastUpdated = new Date(vesselMetadata._lastUpdated);
  
                if (
                  vDataLastUpdated.getTime() < vMetadataLastUpdated.getTime() ||
                  !!pullFromDb
                ) {
                  // The version in local storage is outdated. Pull a new version from the DB.
                  continueWithGet(cardData);
                } else {
                  // Return the version of data in local storage.
                  resolve(vData);
                }
              }
            },
            (cardDataErr) => {
              console.error(cardDataErr);
              continueWithGet();
            }
          );
        } catch (e) {
          console.error(
            "There was a problem accessing LocalForage: " + e.message
          );
          continueWithGet();
        }
      }
    });

    return vesselTableDataPromise;
  }

  processLocalVesselDataFile(vesselsDataFile) {
    let vesselsData = JSON.parse(vesselsDataFile);

    if (Array.isArray(vesselsData)) {
      let vMetadata = vesselsData.map((vessel) => {
        return {
          _lastUpdated: vessel._lastUpdated,
          _version: vessel._version,
          cls: vessel.cls,
          key: vessel.key,
          nat: vessel.nat,
          localOnly: true,
        };
      });

      let now = new Date();
      let localCacheKey = "LOCAL_VDATA_" + now.getTime();
      this.initializeVesselsMetadata({
        localCacheKey: localCacheKey,
        label: "Local Vessel Data - " + now.toLocaleString(),
        vesselKeys: vMetadata,
      });

      // Update the local cache
      this.updateLocalCache(vesselsData, localCacheKey);
    }
  }

  loadAllVesselsFromDb() {
    let self = this;
    let _Vue = Vue;
    let getVesselsPromise = new Promise(function(resolve, reject) {
      let db = firebase.firestore();
      db.collection("vessels")
        .get()
        .then(function(snapshot) {
          let vessels = [];
          snapshot.forEach((vessel) => {
            vessels.push(vessel.data());
          });
          vessels = vessels.sort(_Vue.$utilService.sortVesselsAlphaNumeric);
          self.updateLocalCache(
            vessels,
            self.vesselsMetadataSources.default.localCacheKey
          );
          resolve(vessels);
        })
        .catch(function(err) {
          console.log("Error getting vessels collection: ", err);
          reject(err);
        });
    });

    return getVesselsPromise;
  }

  vesselsCacheToJson() {
    let self = this;
    return new Promise((resolve, reject) => {
      try {
        LocalForage.getItem(
          self.selectedVesselsMetadataSource.localCacheKey
        ).then(
          (cardData) => {
            const sortAlphaNumeric = (itemA, itemB) => {
              let aCompare = itemA.key;
              let bCompare = itemB.key;
              return aCompare.localeCompare(bCompare, "en", { numeric: true });
            };

            let allVessels = [];

            for (const vesselKey in cardData) {
              allVessels.push(cardData[vesselKey]);
            }

            allVessels.sort(sortAlphaNumeric);
            resolve(JSON.stringify(allVessels, null, 4));
          },
          (cardDataErr) => {
            console.error(cardDataErr);
            reject(cardDataErr);
          }
        );
      } catch (e) {
        let errorMsg =
          "There was a problem accessing LocalForage: " + e.message;
        console.error(errorMsg);
        reject(errorMsg);
      }
    });
  }

  loadAllVesselsFromCache() {
    let self = this;
    let vessels = [];
    let _Vue = Vue;
    let getVesselsPromise = new Promise(function(resolve, reject) {
      try {
        LocalForage.getItem(
          self.selectedVesselsMetadataSource.localCacheKey
        ).then(
          (resp) => {
            _.forOwn(resp, (value/*, key*/) => {
              vessels.push(value);
            });

            vessels = vessels.sort(_Vue.$utilService.sortVesselsAlphaNumeric);
            resolve(vessels);
          },
          (err) => {
            console.error(err);
            reject(err);
          }
        );
      } catch (err) {
        let errMsg =
          "There was a problem accessing LocalForage: " + err.message;
        console.error(errMsg);
        reject(errMsg);
      }
    });

    return getVesselsPromise;
  }

  updateLocalCache(vessels, localCacheKey) {
    if (Array.isArray(vessels)) {
      let dbVesselCardData = {};

      vessels.forEach((vessel) => {
        dbVesselCardData[vessel.key] = vessel;
      });

      try {
        LocalForage.setItem(localCacheKey, dbVesselCardData).then(() => {
          console.log("Updated vessel data in LocalForage cache.");
        });
      } catch (e) {
        console.error(
          "There was a problem setting LocalForage vessel data: " + e.message
        );
      }
    }
  }

  clearLocalCache(verbose = true) {
    let cacheSources = [];

    //TODO: step through each prop of this.vesselsMetadataSources
    let prop = null;
    for (prop in this.vesselsMetadataSources) {
      if (Object.prototype.hasOwnProperty.call(this.vesselsMetadataSources, prop)) {
        cacheSources.push(this.vesselsMetadataSources[prop]);
      }
    }

    cacheSources.forEach((cacheSource) => {
      // Clear Vessel Cards cached data
      try {
        LocalForage.setItem(cacheSource.localCacheKey, null).then(() => {
          if (verbose) {
            Vue.toasted.show(
              `Vessel cache ${cacheSource.localCacheKey} successfully cleared!`,
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 3000,
              }
            );
          }
        });
      } catch (e) {
        console.error(
          "There was a problem clearing LocalForage data: " + e.message
        );
      }
    });

    if (
      this.selectedVesselsMetadataSource.localCacheKey !==
      this.vesselsMetadataSources.default.localCacheKey
    ) {
      //Reset vesselsMetadataSources back to the default and re-initialize the vessels metadata.
      let defaultVms = Object.assign({}, this.vesselsMetadataSources.default);
      this.vesselsMetadataSources = { default: null };
      this.initializeVesselsMetadata(defaultVms);
    }
  }

  updateVesselInLocalCache(vessel) {
    let self = this;
    return new Promise((resolve, reject) => {
      if (vessel && vessel.key) {
        try {
          LocalForage.getItem(
            self.selectedVesselsMetadataSource.localCacheKey
          ).then(
            (cardData) => {
              cardData[vessel.key] = vessel;

              try {
                LocalForage.setItem(
                  self.selectedVesselsMetadataSource.localCacheKey,
                  cardData
                ).then(
                  () => {
                    let successMsg =
                      "Updated vessel data in LocalForage cache.";
                    console.log(successMsg);
                    resolve({
                      msg: successMsg,
                      vessel: vessel,
                    });
                  },
                  (saveVesselError) => {
                    reject(saveVesselError);
                  }
                );
              } catch (e) {
                let setErrorMsg =
                  "There was a problem setting LocalForage vessel data: " +
                  e.message;
                console.error(setErrorMsg);
                reject(setErrorMsg);
              }
            },
            (cardDataErr) => {
              console.error(cardDataErr);
              reject(cardDataErr);
            }
          );
        } catch (e) {
          let errorMsg =
            "There was a problem accessing LocalForage: " + e.message;
          console.error(errorMsg);
          reject(errorMsg);
        }
      } else {
        reject("Vessel was not found.");
      }
    });
  }

  removeVesselFromLocalCache(vesselKey) {
    let self = this;
    return new Promise((resolve, reject) => {
      if (vesselKey) {
        try {
          LocalForage.getItem(
            self.selectedVesselsMetadataSource.localCacheKey
          ).then(
            (cardData) => {
              if (Object.prototype.hasOwnProperty.call(cardData, vesselKey)) {
                delete cardData[vesselKey];

                try {
                  LocalForage.setItem(
                    self.selectedVesselsMetadataSource.localCacheKey,
                    cardData
                  ).then(
                    () => {
                      let successMsg =
                        "Removed vessel from vessel data in LocalForage cache.";
                      console.log(successMsg);
                      resolve({
                        msg: successMsg,
                        vesselKey: vesselKey,
                      });
                    },
                    (saveVesselError) => {
                      reject(saveVesselError);
                    }
                  );
                } catch (e) {
                  let setErrorMsg =
                    "There was a problem removing the vessel from LocalForage vessel data: " +
                    e.message;
                  console.error(setErrorMsg);
                  reject(setErrorMsg);
                }
              } else {
                let notFoundErrorMsg =
                    "The vessel to remove could not be found in LocalForage vessel data";
                  console.error(notFoundErrorMsg);
                  reject(notFoundErrorMsg);
              }             
            },
            (cardDataErr) => {
              console.error(cardDataErr);
              reject(cardDataErr);
            }
          );
        } catch (e) {
          let errorMsg =
            "There was a problem accessing LocalForage: " + e.message;
          console.error(errorMsg);
          reject(errorMsg);
        }
      } else {
        reject("Vessel key was not provided.");
      }
    });
  }

  getBlankVesselData() {
    return {
      "key": null,
      "cls": null,
      "type": null,
      "nat": null,
      "vic": null,
      "pts": 0,
      "hull": 0,
      "ap1": 0,
      "ap2": 0,
      "apr": 0,
      "as1": 0,
      "as2": 0,
      "asr": 0,
      "qf": 0,
      "wl": 0,
      "uh": 0,
      "conn": 0,
      "deck": 0,
      "vit": 0,
      "spd": 0,
      "acl": 0,
      "cir": 0,
      "ram": 0,
      "hram": 0,
      "3g_gpno": 0,
      "3g_gpsz": 0,
      "3g_gpc": null,
      "3g_gps": 0,
      "3g_gpm": 0,
      "3g_gpl": 0,
      "3g_gpe": 0,
      "3g_gpd": 0,
      "3g_gpr": 0,
      "3g_gsno": 0,
      "3g_gssz": 0,
      "3g_gsc": null,
      "3g_gss": 0,
      "3g_gsm": 0,
      "3g_gsl": 0,
      "3g_gse": 0,
      "3g_gsd": 0,
      "3g_gsr": 0,
      "sat": null,
      "3g_gtno": 0,
      "3g_gtsz": 0,
      "3g_gtc": null,
      "3g_gts": 0,
      "3g_gtm": 0,
      "3g_gtl": 0,
      "3g_gte": 0,
      "3g_gtd": 0,
      "3g_gtr": 0,
      "qfg": 0,
      "qfsz": 0,
      "qfc": null,
      "qfs": 0,
      "qfm": 0,
      "qfd": 0,
      "gat": 0,
      "tno": 0,
      "tsz": 0,
      "tc": null,
      "tf": 0,
      "ts": 0,
      "yr": 0,
      "crew": 0,
      "det": 0,
      "gpno": 0,
      "gpsz": 0,
      "gpc": null,
      "gps": 0,
      "gpm": 0,
      "gpl": 0,
      "gpe": 0,
      "gpd": 0,
      "gpr": 0,
      "gsno": 0,
      "gssz": 0,
      "gsc": null,
      "gss": 0,
      "gsm": 0,
      "gsl": 0,
      "gse": 0,
      "gsd": 0,
      "gsr": 0,
      "_vic1": null,
      "_vic2": null,
      "_apri": 0,
      "_asec": 0,
      "_spd": 0,
      "_version": null,
      "_vesselImgRef": null,
      "_active": true,
      "_lastUpdated": null,
      "_name": null
    };
  }

  createNewVessel(vesselClass, nation, dataVersion, vesselTemplate) {
    let self = this;
    let now = new Date();

    vesselClass = vesselClass.replace(/"/g, "in");

    let vKey = "[" + dataVersion + "]_" + nation + "_" + vesselClass;
    vKey = Vue.$utilService.sanitizeVesselKey(vKey);

    let newVessel = vesselTemplate !== null ? vesselTemplate : self.getBlankVesselData();
    newVessel.key = vKey;
    newVessel.cls = vesselClass;
    newVessel.nat = nation;
    newVessel._lastUpdated = now.toISOString();
    newVessel._version = dataVersion;
    newVessel._name = vesselClass;

    let newVesselMetadata = {
      _lastUpdated: now,
      _version: dataVersion,
      cls: vesselClass,
      key: vKey,
      nat: nation
    };

    let createVesselPromise = new Promise(function(resolve, reject) {
      // Write newVessel to the DB
      firebase
        .firestore()
        .collection("vessels")
        .doc(vKey)
        .set(newVessel, { merge: false })
        .then(
          () => {
            // Write newVesselMetadata to the DB
            firebase
              .firestore()
              .collection("vessels-metadata")
              .doc(dataVersion)
              .get()
              .then(
                (vMetadataRef) => {
                  let vMetadata = vMetadataRef.data();

                  if (Array.isArray(vMetadata.keys)) {
                    vMetadata.keys.push(newVesselMetadata)
                    vMetadata.lastUpdated = now.toISOString();

                    firebase
                      .firestore()
                      .collection("vessels-metadata")
                      .doc(dataVersion)
                      .set(vMetadata, { merge: true })
                      .then(
                        () => {
                          //Update stored vessel metadata
                          //console.log("New Vessel Metadata: ", newVesselMetadata);

                          self.updateVesselsMetadata(newVesselMetadata);

                          //Update vessel entry in local storage
                          self.updateVesselInLocalCache(newVessel).then(
                            () => {
                              resolve({
                                msg: "SUCCESS",
                                newVessel: newVessel,
                              });
                            },
                            (errorMsg) => {
                              reject(errorMsg);
                            }
                          );
                        },
                        (updateVesselMetadataErr) => {
                          reject(updateVesselMetadataErr);
                        }
                      );
                    
                  } else {
                    reject("A list was not provided");
                  }
                },
                (vMetadataErr) => {
                  reject(vMetadataErr);
                }
              );
          },
          (err) => {
            reject(err);
          }
        );
    });

    return createVesselPromise;
  }

  updateVessel(updatedVessel) {
    let self = this;
    let lastUpdated = new Date();
    let vicLines = Vue.$utilService.splitVesselsInClassLabel(updatedVessel.vic);
    let vesselToUpdate = Object.assign({}, updatedVessel, {
      _lastUpdated: lastUpdated.toISOString(),
      _vic1: vicLines.ln1,
      _vic2: vicLines.ln2,
      _apri: Vue.$utilService.armorLabel(
        updatedVessel.ap1,
        updatedVessel.ap2,
        updatedVessel.apr
      ),
      _asec: Vue.$utilService.armorLabel(
        updatedVessel.as1,
        updatedVessel.as2,
        updatedVessel.asr
      ),
      _spd: Vue.$utilService.speedLabel(updatedVessel.spd),
      det: Vue.$utilService.escapeSpecialCharacters(updatedVessel.det),
    });
    let updateVesselPromise = new Promise(function(resolve, reject) {
      firebase
        .firestore()
        .collection("vessels")
        .doc(updatedVessel.key)
        .set(vesselToUpdate, { merge: true })
        .then(
          () => {
            //Update vessels-metadata collection this vessel records corresponds to
            firebase
              .firestore()
              .collection("vessels-metadata")
              .doc(updatedVessel._version)
              .get()
              .then(
                (vMetadataRef) => {
                  let vMetadata = vMetadataRef.data();

                  if (Array.isArray(vMetadata.keys)) {
                    let vesselMatch = vMetadata.keys.find(
                      (vesselMetadata) =>
                        vesselMetadata.key === updatedVessel.key
                    );

                    if (vesselMatch) {
                      vMetadata.lastUpdated = lastUpdated.toISOString();
                      vesselMatch._lastUpdated = lastUpdated.toISOString();

                      firebase
                        .firestore()
                        .collection("vessels-metadata")
                        .doc(updatedVessel._version)
                        .set(vMetadata, { merge: true })
                        .then(
                          () => {
                            //Update stored vessel metadata
                            self.updateVesselsMetadata(vesselMatch);

                            //Update vessel entry in local storage
                            self.updateVesselInLocalCache(vesselToUpdate).then(
                              () => {
                                resolve({
                                  msg: "SUCCESS",
                                  updatedVessel: vesselToUpdate,
                                });
                              },
                              (errorMsg) => {
                                reject(errorMsg);
                              }
                            );
                          },
                          (updateVesselMetadataErr) => {
                            reject(updateVesselMetadataErr);
                          }
                        );
                    } else {
                      reject("No vessel match");
                    }
                  } else {
                    reject("A list was not provided");
                  }
                },
                (vMetadataErr) => {
                  reject(vMetadataErr);
                }
              );
          },
          (err) => {
            reject(err);
          }
        );
    });

    return updateVesselPromise;
  }

  deleteVessel(vessel) {
    let self = this;
    let lastUpdated = new Date();
    let _key = vessel.key;
    let _version = vessel._version

    let deleteVesselPromise = new Promise(function(resolve, reject) {
      firebase
        .firestore()
        .collection("vessels")
        .doc(_key)
        .delete()
        .then(
          () => {
            //Update vessels-metadata collection this vessel records corresponds to
            firebase
              .firestore()
              .collection("vessels-metadata")
              .doc(_version)
              .get()
              .then(
                (vMetadataRef) => {
                  let vMetadata = vMetadataRef.data();

                  if (Array.isArray(vMetadata.keys)) {
                    vMetadata.keys = vMetadata.keys.filter(
                      (vesselMetadata) =>
                        vesselMetadata.key !== _key
                    );

                    vMetadata.lastUpdated = lastUpdated.toISOString();

                    firebase
                      .firestore()
                      .collection("vessels-metadata")
                      .doc(_version)
                      .set(vMetadata, { merge: false })
                      .then(
                        () => {
                          //Update stored vessel metadata
                          self.removeVesselFromMetadata(_key);

                          //Update vessel entry in local storage
                          self.removeVesselFromLocalCache(_key).then(
                            () => {
                              resolve({
                                msg: "SUCCESS",
                                removedVesselKey: _key,
                              });
                            },
                            (errorMsg) => {
                              reject(errorMsg);
                            }
                          );
                        },
                        (updateVesselMetadataErr) => {
                          reject(updateVesselMetadataErr);
                        }
                      );
                  } else {
                    reject("A list was not provided");
                  }
                },
                (vMetadataErr) => {
                  reject(vMetadataErr);
                }
              );
          },
          (err) => {
            reject(err);
          }
        );
    });

    return deleteVesselPromise;
  }
}

export default new VesselsService();
