<template>
  <div class="app-main vessel-sets">
    <main>
      <div class="list-panel left">
        <div class="p-2 filter">
          <b-form-input
            type="text"
            v-model="filterKeyword"
            size="sm"
            placeholder="Filter by vessel set name"
          ></b-form-input>
          <b-button
            variant="outline-secondary"
            size="sm"
            v-on:click="clearFilter"
            :disabled="!filterKeyword.length"
            >Clear</b-button
          >
        </div>

        <b-alert
          :show="filterKeyword.length && !vesselSets.length"
          variant="danger"
          >No vessel sets were found that match your filter.</b-alert
        >

        <b-list-group class="items">
          <b-list-group-item
            button
            class="item p-3"
            v-bind:class="{ active: vesselSet.key === selectedVesselSet.key }"
            v-for="vesselSet of vesselSets"
            :key="vesselSet.key"
            @click="selectVesselSet(vesselSet)"
            >{{ vesselSet.label }}</b-list-group-item
          >
        </b-list-group>

        <div class="footer">
          <b-button
            class="d-flex align-items-baseline"
            variant="primary"
            size="sm"
            v-bind:disabled="inDm"
            v-b-modal.addVesselSetModal
          >
            <i class="fas fa-plus mr-1"></i>Add New Vessel Set
          </b-button>
        </div>
      </div>

      <div class="content">
        <b-navbar class="pt-0 pl-2 pr-2 pb-2" toggleable="sm" type="light" v-if="selectedVesselSet.key">
          <b-navbar-brand class="mb-0"></b-navbar-brand>

          <b-navbar-toggle
            class="mt-2"
            target="vessel-set-btns-collapse"
          ></b-navbar-toggle>

          <b-collapse is-nav id="vessel-set-btns-collapse">
            <b-navbar-nav class="ml-auto">
              <b-button-toolbar
                class="d-flex justify-content-end"
                key-nav
                aria-label="Vessel-set Edit, Delete, and Print buttons"
              >
                <b-button
                  class="mt-2 d-flex align-items-baseline"
                  v-if="selectedVesselSet.key"
                  variant="outline-primary"
                  size="sm"
                  v-b-modal.editVesselSetModal
                >
                  <i class="fas fa-pen mr-1"></i>Edit
                </b-button>

                <b-button
                  class="mt-2 d-flex align-items-baseline"
                  v-if="selectedVesselSet.key"
                  variant="outline-primary"
                  size="sm"
                  v-bind:disabled="inDm"
                  v-b-modal.deleteVesselSetModal
                >
                  <i class="fas fa-trash mr-1"></i>Delete
                </b-button>

                <b-button
                  class="mt-2 d-flex align-items-baseline"
                  v-if="selectedVesselSet.key"
                  variant="outline-primary"
                  size="sm"
                  v-bind:disabled="inDm"
                  v-on:click="print"
                >
                  <i class="fas fa-print mr-1"></i>Print
                </b-button>
              </b-button-toolbar>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <div
          class="mt-4 h-2 d-flex align-items-center justify-content-center"
          v-if="selectedVesselSet && !selectedVesselSet.key"
        >
          <b-alert show variant="info"
            >Select a Vessel Set from the list to view its information.</b-alert
          >
        </div>

        <div class="vessel-set-content" v-if="selectedVesselSet.key">
          <b-jumbotron
            class="p-2"
            v-bind:header="selectedVesselSet.label"
            header-tag="h3"
            header-level="6"
            v-bind:lead="selectedVesselSet.desc || ''"
            bg-variant="info"
          ></b-jumbotron>

          <h4>Vessels</h4>
          <b-card
            v-bind:title="getVesselLabel(sVessel.key)"
            v-for="sVessel of vsVessels"
            :key="sVessel.key"
            class="vessel-card"
          >
            <b-button
              variant="outline-secondary flex-grow-0 flex-shrink-0"
              size="sm"
              v-on:click="setSelectedVessel(sVessel)"
              >View Card</b-button
            >
          </b-card>

          <div v-if="!vsVessels.length">
            <span>There are no vessels assigned yet. Click the&nbsp;</span>
            <em>Edit</em>
            <span>&nbsp;button to add vessels to this vessel set.</span>
          </div>
        </div>
      </div>
    </main>

    <AddVesselSetModal v-if="!inDm"></AddVesselSetModal>
    <EditVesselSetModal :vessel-set="selectedVesselSet"></EditVesselSetModal>
    <VesselCardModal :vessel-key="selectedVessel.key"></VesselCardModal>

    <b-modal
      id="deleteVesselSetModal"
      title="Delete Vessel Set?"
      header-bg-variant="warning"
      @ok="deleteVesselSet"
      ok-title="Yes"
      ok-variant="outline-secondary"
      cancel-title="No"
      cancel-variant="primary"
      centered
      >Are you sure you want to delete this vessel set? This action can not be
      undone.</b-modal
    >
  </div>
</template>

<script>
  import AddVesselSetModal from "../components/AddVesselSetModal";
  import EditVesselSetModal from "../components/EditVesselSetModal";
  import VesselCardModal from "../components/VesselCardModal";
  import Vue from "vue";

  export default {
    name: "VesselSets",
    components: {
      AddVesselSetModal,
      EditVesselSetModal,
      VesselCardModal,
    },
    data: function () {
      return {
        selectedVessel: {},
      };
    },
    computed: {
      filterKeyword: {
        get() {
          return this.$store.state.sets.filterKeyword;
        },
        set(val) {
          this.$store.dispatch("sets/setFilter", val);
        },
      },
      inDm: {
        get() {
          return this.$store.state.user.inDm;
        },
      },
      selectedVesselSet: function () {
        return this.$store.state.sets.selected;
      },
      vesselSets: function () {
        return this.$store.getters["sets/getFilteredVesselSets"].call(
          this,
          this.filterKeyword
        );
      },
      vsVessels: function () {
        let availableVessels = [];
        let allVessels = Vue.$vesselsService.getVesselsMetadata();
        if (Array.isArray(allVessels)) {
          availableVessels = allVessels.map((vessel) => vessel.key);
        }

        if (this.$store.state.user.isSa) {
          return this.selectedVesselSet.vessels;
        }

        return this.selectedVesselSet
          ? this.selectedVesselSet.vessels.filter((vessel) => {
              return !!(
                Array.isArray(availableVessels) &&
                availableVessels.indexOf(vessel.key) > -1
              );
            })
          : [];
      },
    },
    methods: {
      clearFilter() {
        this.$store.dispatch("sets/setFilter", "");
      },
      deleteVesselSet() {
        if (!this.inDm) {
          this.$store.dispatch(
            "sets/deleteVesselSet",
            this.$store.state.sets.selected
          );
        }
      },
      getVesselLabel(vesselKey) {
        return Vue.$vesselsService.getVesselLabel(vesselKey);
      },
      print() {
        if (!this.inDm) {
          this.$store.dispatch("print/openPrintPreview", {
            vesselSetLabel: this.selectedVesselSet.label,
            vesselSetDesc: this.selectedVesselSet.desc,
            vessels: this.selectedVesselSet.vessels,
            mode: "vessel-set",
          });
        }
      },
      selectVesselSet(vesselSet) {
        this.$store.dispatch("sets/setSelected", vesselSet);
      },
      setSelectedVessel(vessel) {
        this.selectedVessel = vessel;
        this.$bvModal.show("vesselCardModal");
      },
    },
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";

  .vessel-sets {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    main {
      flex: 1 1 100%;
      display: flex;
      height: 100%;
      min-height: 0;

      .list-panel {
        flex: 0 0 350px;
        display: flex;
        flex-direction: column;
        background-color: $controlBackground;

        &.left {
          border-right: $navBorder;
        }

        &.right {
          border-left: $navBorder;
        }

        .filter {
          flex: 0 0 auto;
          display: flex;
          flex-wrap: nowrap;
          padding: 12px;
          border-bottom: $navBorder;

          input {
            margin-right: 10px;
          }
        }

        .alert {
          margin: 10px;
        }

        .items {
          flex: 1 1 auto;
          padding: 0.5rem;
          margin: 0;
          list-style-type: none;
          overflow-y: auto;

          .item {
            display: flex;
            min-height: 58px;

            &.active {
              color: #fff;
              background-color: $bsbw_blue_800;
            }

            &.checkbox:hover {
              cursor: pointer;
            }

            &:not(.item):not(.checkbox):hover {
              color: #000;
              background-color: $bsbw_blue_400;
              cursor: pointer;
            }
          }
        }

        .footer {
          flex: 0 0 auto;
          display: flex;
          justify-content: center;
          padding: 10px;
          border-top: $navBorder;
        }
      }

      .content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        min-width: 300px;

        &.scaled .vessel-card {
          transform: scale(0.8);
          transform-origin: 0 0;
        }

        nav {
          flex: 0 0 auto;
          background-color: $controlBackground;
          border-bottom: $navBorder;

          .navbar-nav {
            button {
              margin-left: 10px;
            }
          }
        }

        .vessel-set-content {
          flex: 1 1 auto;
          overflow: auto;
          padding: 20px;

          .jumbotron .lead {
            margin: 0;
            font-size: 1.15rem;
          }

          > p {
            white-space: pre-line;
          }

          .vessel-card {
            position: relative;
            margin-top: 20px;

            .card-body {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 1rem;

              .card-title {
                flex: 1 1 auto;
                margin: 0;
                padding: 0;
                font-size: 1em;
              }
            }
          }

          .card-footer {
            text-align: right;
          }
        }
      }
    }
  }
</style>
