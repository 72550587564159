<template>
  <div class="terms-of-use">
    <div>By registering, accessing, and using this Account, you are agreeing that:</div>
    <ol>
      <li>Deadly Warfare Games may change, alter, adjust or remove this agreement at any time, at its sole desire.</li>
      <li>Users agree to protect their password and not share or distribute it to others.</li>
      <li>Users are granted permissions for their individual personal use only.</li>
      <li>Users will not charge for, distribute any part of this Companion Site, nor any or all of those items produced for their personal use without the express written consent of Deadly Warfare Games.</li>
      <li>Deadly Warfare Games may revoke the use of this site at any time to any user not following any of these conditions.</li>
    </ol>
  </div>
</template>

<script>
  export default { name: "TermsOfUse" };
</script>

<style lang="scss" scoped>
  .terms-of-use ol {
    padding-left: 18px;
  }
</style>
