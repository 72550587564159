<template>
  <div
    class="vessel-card"
    ref="cardWrapper"
    data-type="vc"
    v-bind:id="vesselCardId"
    :data-key="vessel ? vessel.key : ''"
  >
    <div class="no-data" v-if="!vessel.key">Waiting for Vessel Data</div>

    <div class="vessel-card-content" ref="card" v-if="vessel && vessel.key">
      <div class="card" v-bind:id="vessel.key">
        <div class="vessel-card-row vcr-1 bb">
          <div>
            <div class="em">
              <div class="pad">Points</div>
            </div>
            <div>
              <div class="pad">Circle</div>
            </div>
          </div>

          <div class="br">
            <div class="em">
              <div class="pad">{{ vessel.pts }}</div>
            </div>
            <div>
              <div class="pad">{{ vessel.cir }}</div>
            </div>
          </div>

          <div class="br">
            <div class="em type-class">
              <div class="pad vessel-type">{{ vessel.type }}</div>
              <div v-if="!showDisplayName" class="pad h-center vessel-class">{{ vessel.cls }}</div>
              <div v-if="showDisplayName" class="pad h-center vessel-class">{{ vessel._name }}</div>
            </div>
            <div class="h-center ships-in-class">
              <div>
                {{ vessel._vic1 ? vessel._vic1.split(" ").join("&nbsp;") : "" }}
              </div>
            </div>
            <div class="h-center ships-in-class" v-if="vessel._vic2">
              <div>
                {{ vessel._vic2 ? vessel._vic2.split(" ").join("&nbsp;") : "" }}
              </div>
            </div>
          </div>

          <div class="br">
            <div class="pad h-center em">{{ vessel.yr }}</div>
            <div class="pad h-center">Crew&nbsp;{{ vessel.crew }}</div>
          </div>

          <div class="pad flag h-center">
            <FlagImg v-bind:nation="vessel.nat" @loaded="flagImageDataLoaded($event)"></FlagImg>
          </div>
        </div>

        <div class="vessel-card-row vcr-2 bb">
          <div>
            <div>
              <div class="pad">Speed</div>
            </div>
          </div>

          <div class="br">
            <div>
              <div class="pad">{{ vessel._spd }}</div>
            </div>
          </div>

          <div class="br">
            <div class="vessel-accel">
              <div class="pad">Accel</div>
              <div class="pad">+{{ vessel.acl }}/-{{ vessel.acl }}</div>
            </div>
          </div>

          <div class="torpedos" v-if="vessel.tsz">
            <div class="pad">Torpedoes</div>
            <div class="pad">T-{{ vessel.tsz }}</div>

            <div class="pad">Fast</div>
            <div class="pad">{{ vessel.tf }}</div>

            <div class="pad">Slow</div>
            <div class="pad">{{ vessel.ts }}</div>
          </div>

          <div class="torpedos" v-else>
            <div class="pad">Torpedoes</div>
            <div class="pad">None</div>

            <div class="pad">Fast</div>
            <div>
              <!--Empty-->
            </div>

            <div class="pad">Slow</div>
            <div>
              <!--Empty-->
            </div>
          </div>
        </div>

        <div class="vessel-card-row vcr-3 bb">
          <div class="pad hv-center ta-center br">Gun&nbsp;Size</div>
          <div class="pad hv-center ta-center yellow br">
            #&nbsp;of&nbsp;Guns
          </div>

          <div class="br">
            <div class="pad h-center light-blue bb em">Short&nbsp;6"</div>
            <div class="pad h-center">roll&nbsp;3-6</div>
          </div>

          <div class="br">
            <div class="pad light-blue h-center bb em">Med&nbsp;12"</div>
            <div class="pad h-center">roll&nbsp;4-6</div>
          </div>

          <div class="br">
            <div class="pad light-blue h-center bb em">Long&nbsp;24"</div>
            <div class="pad h-center">roll&nbsp;5-6</div>
          </div>

          <div class="br">
            <div class="pad light-blue h-center bb em">Ext&nbsp;36"</div>
            <div class="pad h-center">roll&nbsp;6</div>
          </div>

          <div class="blue">
            <div class="pad h-center">Hull</div>
            <div class="pad h-center">Damage</div>
          </div>
        </div>

        <div class="vessel-card-row vcr-4" v-if="!inThreeGunMode">
          <div class="pad black bb em">Pri</div>

          <!-- Special Formatting -->
          <div class="pad black bb br em nowrap">
            <span>{{ hideZeroLabel(vessel.gpsz) }}</span>
            <span v-if="vessel.gpsz > 0">"</span>
          </div>

          <div class="pad yellow h-center br">
            {{ hideZeroLabel(vessel.gpno) }}
          </div>

          <div class="pad black h-center bb br em">
            {{ hideZeroLabel(vessel.gps) }}
          </div>

          <div class="pad black h-center bb br em">
            {{ hideZeroLabel(vessel.gpm) }}
          </div>

          <div class="pad black h-center bb br em">
            {{ hideZeroLabel(vessel.gpl) }}
          </div>

          <div class="pad black h-center bb br em">
            {{ hideZeroLabel(vessel.gpe) }}
          </div>

          <div class="pad blue h-center">{{ hideZeroLabel(vessel.gpd) }}</div>
        </div>

        <div class="vessel-card-row vcr-5" v-if="!inThreeGunMode">
          <div class="pad bb em">Sec</div>

          <!-- Special Formatting -->
          <div class="pad bb br em nowrap">
            <span>{{ hideZeroLabel(vessel.gssz) }}</span>
            <span v-if="vessel.gssz > 0">"</span>
          </div>

          <div class="pad yellow h-center br">
            {{ hideZeroLabel(vessel.gsno) }}
          </div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel.gss) }}</div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel.gsm) }}</div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel.gsl) }}</div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel.gse) }}</div>

          <div class="pad blue h-center">{{ hideZeroLabel(vessel.gsd) }}</div>
        </div>

        <div class="vessel-card-row vcr-3gp" v-if="!!inThreeGunMode">
          <div class="pad black bb em">Pri</div>

          <!-- Special Formatting -->
          <div class="pad black bb br em nowrap">
            <span>{{ hideZeroLabel(vessel['3g_gpsz']) }}</span>
            <span v-if="vessel['3g_gpsz'] > 0">"</span>
          </div>

          <div class="pad yellow h-center br">
            {{ hideZeroLabel(vessel['3g_gpno']) }}
          </div>

          <div class="pad black h-center bb br em">
            {{ hideZeroLabel(vessel['3g_gps']) }}
          </div>

          <div class="pad black h-center bb br em">
            {{ hideZeroLabel(vessel['3g_gpm']) }}
          </div>

          <div class="pad black h-center bb br em">
            {{ hideZeroLabel(vessel['3g_gpl']) }}
          </div>

          <div class="pad black h-center bb br em">
            {{ hideZeroLabel(vessel['3g_gpe']) }}
          </div>

          <div class="pad blue h-center">{{ hideZeroLabel(vessel['3g_gpd']) }}</div>
        </div>

        <div class="vessel-card-row vcr-3gs" v-if="!!inThreeGunMode">
          <div class="pad bb em nowrap">Semi</div>

          <!-- Special Formatting -->
          <div class="pad bb br em">
            <span>{{ hideZeroLabel(vessel['3g_gssz']) }}</span>
            <span v-if="vessel['3g_gssz'] > 0">"</span>
          </div>

          <div class="pad yellow h-center br">
            {{ hideZeroLabel(vessel['3g_gsno']) }}
          </div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel['3g_gss']) }}</div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel['3g_gsm']) }}</div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel['3g_gsl']) }}</div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel['3g_gse']) }}</div>

          <div class="pad blue h-center">{{ hideZeroLabel(vessel['3g_gsd']) }}</div>
        </div>

        <div class="vessel-card-row vcr-3gt" v-if="!!inThreeGunMode">
          <div class="pad bb em">Sec</div>

          <!-- Special Formatting -->
          <div class="pad bb br em">
            <span>{{ hideZeroLabel(vessel['3g_gtsz']) }}</span>
            <span v-if="vessel['3g_gtsz'] > 0">"</span>
          </div>

          <div class="pad yellow h-center br">
            {{ hideZeroLabel(vessel['3g_gtno']) }}
          </div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel['3g_gts']) }}</div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel['3g_gtm']) }}</div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel['3g_gtl']) }}</div>

          <div class="pad h-center bb br">{{ hideZeroLabel(vessel['3g_gte']) }}</div>

          <div class="pad blue h-center">{{ hideZeroLabel(vessel['3g_gtd']) }}</div>
        </div>

        <div class="vessel-card-row vcr-6 bb">
          <div class="pad brown em">QF</div>

          <div class="pad brown br em">
            <span>{{ hideZeroLabel(vessel.qfsz) }}</span>
            <span v-if="vessel.qfsz > 0">"</span>
          </div>

          <div class="pad yellow h-center br">
            {{ hideZeroLabel(vessel.qfg) }}
          </div>

          <div class="pad brown h-center br">
            {{ hideZeroLabel(vessel.qfs) }}
          </div>

          <div class="pad brown h-center br">
            {{ defaultZeroLabel(vessel.qfm) }}
          </div>

          <div class="pad h-center br">Penetration&nbsp;Table</div>

          <!-- Special Formatting -->
          <div class="pad blue h-center">
            <span v-if="vessel.qfd > 0">*</span>
            <span>{{ hideZeroLabel(vessel.qfd) }}</span>
            <span v-if="vessel.qfd > 0">*</span>
          </div>
        </div>

        <div class="vessel-card-row vcr-7 bb" v-bind:class="{ 'three-gun': !!inThreeGunMode }">
          <div class="pad br">
            <div class="h-center" v-bind:class="{ ptb: !inThreeGunMode }">7"+ guns cannot shoot at DD, TB, SS</div>
            <div class="h-center" v-bind:class="{ ptb: !inThreeGunMode }">
              Multiple ships roll with a -1 to hit die
            </div>
            <div class="h-center" v-bind:class="{ ptb: !inThreeGunMode }">
              Serpentine rolls are -1 to pulled die
            </div>
          </div>
          <div class="pad">
            <div class="h-center" v-bind:class="{ ptb: !inThreeGunMode }">Rake = +1 to hit at Extreme Range</div>
            <div class="h-center" v-bind:class="{ ptb: !inThreeGunMode }">
              Rake = Blue and Purple Dice use vitals
            </div>
            <div class="h-center" v-bind:class="{ ptb: !inThreeGunMode }">*Unengaged = draw one extra die*</div>
          </div>
        </div>

        <div class="vessel-card-row vcr-8 bb">
          <div class="hv-center hull-indices">
            <div
              class="hv-center hull-index"
              v-bind:class="{ dark: hullIndex <= midHull }"
              v-for="hullIndex of hullIndices"
              :key="hullIndex"
            >
              {{ hullIndex }}
            </div>
          </div>
        </div>

        <div class="vessel-card-row vcr-9 bb">
          <div class="br">
            <div class="pad h-center em">Hull</div>
            <div class="pad h-center em">{{ vessel.hull }}</div>
          </div>

          <div class="br">
            <div class="pad h-center">6 Rudder Fixed</div>
            <div class="pad h-center">3-6 Helm Fixed</div>
          </div>

          <div class="br">
            <div class="pad h-center">3-6 Deck Fire Out</div>
            <div class="pad h-center">5-6 Recover 1 Spd</div>
          </div>

          <div class="br">
            <div class="pad h-center">5-6 Internal Fire Out</div>
            <div class="pad h-center">5-6 Regain 1 Hull</div>
          </div>

          <div>
            <div class="pad bb gatlings-ram gr-1">
              <div>Gatlings</div>
              <div class="h-center">{{ vessel.gat }}</div>
            </div>

            <div class="pad sc-row gatlings-ram gr-2">
              <div>Ram</div>
              <div class="h-center">{{ vessel.ram }}&nbsp;x&nbsp;Spd</div>
            </div>
          </div>
        </div>

        <div class="vessel-card-row vcr-10 bb">
          <div class="vessel">
            <VesselImg :vessel="vessel" @loaded="vesselImageDataLoaded($event)"></VesselImg>
          </div>
        </div>

        <div class="vessel-card-row vcr-11 bb">
          <div class="pad br v-center vessel-details">
            <div>
              {{ typeof vessel.det === "string" && vessel.det !== "0" ? vessel.det.split(" ").join("&nbsp;") : "" }}
            </div>
          </div>

          <div class="pad">Waterline&nbsp;Hull</div>
          <div class="pad h-center">{{ vessel.wl }}</div>

          <div class="pad">Upper&nbsp;Hull</div>
          <div class="pad h-center">{{ vessel.uh }}</div>
        </div>

        <div class="vessel-card-row vcr-12 bb">
          <div class="pad">Pri</div>
          <div class="pad br">{{ vessel._apri }}</div>

          <div class="pad">QF</div>
          <div class="pad h-center">{{ vessel.qf }}</div>

          <div class="pad">Con&nbsp;Tower</div>
          <div class="pad h-center">{{ vessel.conn }}</div>
        </div>

        <!--<div class="vessel-card-row vcr-12-3gun bb" v-if="!!inThreeGunMode">
          <div class="pad">Pri</div>
          <div class="pad">{{ vessel._apri }}</div>

          <div class="pad">Arm</div>
          <div class="pad br">{{ vessel.sat }}</div>

          <div class="pad">QF</div>
          <div class="pad h-center">{{ vessel.qf }}</div>

          <div class="pad">Con&nbsp;Tower</div>
          <div class="pad h-center">{{ vessel.conn }}</div>
        </div>-->

        <div class="vessel-card-row vcr-13">
          <div class="pad">Sec</div>
          <div class="pad br">{{ vessel._asec }}</div>

          <div class="pad h-center br em">Armor&nbsp;Positions</div>

          <div class="pad">Deck</div>
          <div class="pad h-center">{{ vessel.deck }}</div>

          <div class="pad">Vitals</div>
          <div class="pad h-center">{{ vessel.vit }}</div>
        </div>
      </div>

      <Copyright></Copyright>
    </div>

    <resize-observer v-if="!!resizeToFit" @notify="resizeCard" />
  </div>
</template>

<script>
  // html2canvas notes:
  // - The version of html2canvas we're using is a fork of the niklasvh/html2canvas repo that has a fix for image
  // scaling that is not yet merged. Pulling from this forked repo is not stable but the resulting output works at this time.
  // - See https://github.com/niklasvh/html2canvas/pull/1087
  import html2canvas from "html2canvas";

  import Copyright from "../components/Copyright";
  import FlagImg from "../components/FlagImg";
  import VesselImg from "../components/VesselImg";

  //let assets = require.context("@/assets", true, /\.jpg$/);

  export default {
    name: "VesselCard",
    props: ["vesselKey", "resizeToFit", "inThreeGunMode", "showZeros", "showNegatives", "showDisplayName", "generateCardImg"],
    emits: ['loaded'],
    components: { Copyright, FlagImg, VesselImg },
    data: function () {
      return {
        vessel: {},
        flagImageDataIsLoaded: false,
        vesselImageDataIsLoaded: false
      };
    },
    computed: {
      hullIndices: function() {
        let i = 1;
        let indices = [];

        while (i <= this.vessel.hull && i <= 25) {
          indices.push(i);
          i++;
        }
        return indices;
      },
      midHull: function () {
        return this.vessel ? Math.ceil(this.vessel.hull / 2) : 0;
      },
      vesselCardId: function() {
        return this.vessel ? "vesselCard" + this.vessel.key : 'vesselCardElem';
      }
    },
    methods: {
      hideZeroLabel(val) {
        if (val > 0) {
          return val;
        }

        if((!!this.showZeros && val == 0) || (!!this.showNegatives && val < 0)) {
          return val;
        }

        return "";
      },
      defaultZeroLabel(val) {
        if (val >= 0) {
          return val;
        }

        if(!!this.showNegatives && val < 0) {
          return val;
        }

        return "0";
      },
      resizeCard() {
        let card = this.$refs.card;

        if (!card) {
          return;
        }

        if (!!this.resizeToFit) {
          let wrapper = this.$refs.cardWrapper;

          if (wrapper && card) {
            let wSize = {
              width: wrapper.clientWidth,
              height: wrapper.clientHeight,
            };
            let cSize = { width: card.clientWidth, height: card.clientHeight };

            let scale = Math.min(
              wSize.width / cSize.width,
              wSize.height / cSize.height
            );

            card.style.transform = "scale(" + scale + ")";
          }
        } else {
          card.style.transform = "scale(1)";
        }
      },
      generateVesselCardImage() {
        const generateVcImagePromise = new Promise((resolve, reject) => {
          let vesselCardElem = document.getElementById(this.vessel.key);

          if(vesselCardElem) {
            try{
              html2canvas(vesselCardElem, {
                scale: 2,
                //allowTaint : true,
                //useCORS: true,
                //foreignObjectRendering: true,
                onrendered: (canvas) => {
                  resolve(canvas.toDataURL());
                },
              });
            } catch(e) {
              console.error(e);
              reject(e);
            }
            
          } else {
            reject("vessel card not found");
          }
        });

        return generateVcImagePromise;
      },
      initGenerateVesselCardImage() {
        let self = this;

        if(this.flagImageDataIsLoaded && this.vesselImageDataIsLoaded && this.generateCardImg == true) {
          setTimeout(() => {
            self.generateVesselCardImage().then((vesselCardImg) => {
              self.$emit('loaded', vesselCardImg);
            });
          }, 500);
        }
      },
      loadVesselData(vesselKey) {
        let self = this;
        if (vesselKey && typeof vesselKey === "object" && vesselKey.key) {
          this.vessel = vesselKey;
        } else if (vesselKey && typeof vesselKey === "string") {
          this.$store.getters["vessels/getVesselData"]
            .call(self, vesselKey)
            .then((vesselData) => {
              self.vessel = vesselData;
            });
        } else {
          this.vessel = {};
          this.vesselCardImg = null;
        }
      },
      flagImageDataLoaded() {
        this.flagImageDataIsLoaded = true;
        this.initGenerateVesselCardImage();
      },
      vesselImageDataLoaded() {
        this.vesselImageDataIsLoaded = true;
        this.initGenerateVesselCardImage();
      },
    },
    watch: {
      vesselKey: function (vesselKey) {
        this.loadVesselData(vesselKey);
      },
      resizeToFit: function (/*resize*/) {
        this.resizeCard();
      },
      inThreeGunMode: function(/*inThreeGunMode*/) {
        this.initGenerateVesselCardImage();
      }
    },
    created() {
      if (this.vesselKey && !this.vessel.key) {
        this.loadVesselData(this.vesselKey);
      }
    },
    mounted() {
      this.resizeCard();
    },
  };
</script>

<style lang="scss">
  .nowrap {
    white-space: nowrap;
  }

  .vessel-card {
    position: relative;

    .no-data {
      text-align: center;
      width: 100%;
      font-size: 1.1em;
      padding: 20px;
      color: #ccc;
    }

    &.default-print {
      .vessel-card-content {
        width: 465px;
        font-size: 0.9em;

        .hull-indices {
          height: 40px;

          .hull-index {
            width: 22px;
            height: 22px;
          }
        }

        .flag img {
          width: 60px;
          height: 42px;
        }

        .vessel {
          height: 230px;
          img {
            max-width: 456px;
            max-height: 190px;
          }
        }

        .pad {
          padding: 1px;
        }

        .card .vessel-card-row {
          &.vcr-1 {
            -ms-grid-columns: 12mm 11mm 64mm 18mm 17mm;
            grid-template-columns: 12mm 11mm 64mm 18mm 17mm;

            .type-class {
              display: -ms-grid;
              display: grid;
              -ms-grid-columns: 14mm 50mm;
              grid-template-columns: 14mm 50mm;

              .vessel-type {
                white-space: nowrap;
              }

              .vessel-class {
                padding-right: 14mm;
              }
            }

            .ships-in-class {
              font-size: 0.8em;
              position: relative;
              line-height: 1em;

              > div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          &.vcr-2 {
            -ms-grid-columns: 12mm 8mm 33mm 69mm;
            grid-template-columns: 12mm 8mm 33mm 69mm;

            .vessel-accel {
              display: -ms-grid;
              display: grid;
              -ms-grid-columns: 16mm 16mm;
              grid-template-columns: 16mm 16mm;
            }

            .torpedos {
              display: -ms-grid;
              display: grid;
              -ms-grid-columns: 20mm 10mm 10mm 10mm 10mm 9mm;
              grid-template-columns: 20mm 10mm 10mm 10mm 10mm 9mm;
            }
          }

          &.vcr-3 {
            -ms-grid-columns: 20mm 17mm 16mm 16mm 18mm 18mm 17mm;
            grid-template-columns: 20mm 17mm 16mm 16mm 18mm 18mm 17mm;
          }

          &.vcr-4,  &.vcr-5,  &.vcr-3gp,  &.vcr-3gs,  &.vcr-3gt {
            -ms-grid-columns: 10mm 10mm 17mm 16mm 16mm 18mm 18mm 17mm;
            grid-template-columns: 10mm 10mm 17mm 16mm 16mm 18mm 18mm 17mm;
          }

          &.vcr-6 {
            -ms-grid-columns: 10mm 10mm 17mm 16mm 16mm 36mm 17mm;
            grid-template-columns: 10mm 10mm 17mm 16mm 16mm 36mm 17mm;
          }

          &.vcr-7 {
            -ms-grid-columns: 61mm 61mm;
            grid-template-columns: 61mm 61mm;
            font-size: 0.9em;

            &.three-gun {
              font-size: 0.7em;

              .pad {
                padding: 0 0 1px 0;
              }
            }
          }

          &.vcr-8 {
            -ms-grid-columns: 122mm;
            grid-template-columns: 122mm;
            padding: 0;

            .hull-indices {
              .hull-index {
                width: 0.6cm;
                height: 0.6cm;
                font-size: 0.9em;
                line-height: 0.9em;
                border-top: 1pt solid #000;
                border-bottom: 1pt solid #000;
                border-right: 1pt solid #000;

                &:first-child {
                  border-left: 1pt solid #000;
                }
              }
            }
          }

          &.vcr-9 {
            -ms-grid-columns: 8mm 24mm 29mm 35mm 26mm;
            grid-template-columns: 8mm 24mm 29mm 35mm 26mm;
            font-size: 0.9em;

            .gatlings-ram {
              display: -ms-grid;
              display: grid;

              &.gr-1 {
                -ms-grid-columns: 14mm 12mm;
                grid-template-columns: 14mm 12mm;
              }

              &.gr-2 {
                -ms-grid-columns: 8mm 18mm;
                grid-template-columns: 8mm 18mm;
              }
            }
          }

          &.vcr-10 {
            -ms-grid-columns: 122mm;
            grid-template-columns: 122mm;

            .vessel {
              padding: 5pt;
              height: 4.4cm;

              img {
                max-width: 116mm;
                max-height: 39mm;
              }
            }
          }

          &.vcr-11 {
            -ms-grid-columns: 61mm 23mm 10mm 18mm 10mm;
            grid-template-columns: 61mm 23mm 10mm 18mm 10mm;
            font-size: 0.85em;

            .vessel-details {
              font-size: 0.8em;
              position: relative;

              > div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          &.vcr-12 {
            -ms-grid-columns: 8mm 53mm 23mm 10mm 18mm 10mm;
            grid-template-columns: 8mm 53mm 23mm 10mm 18mm 10mm;
            font-size: 0.85em;
          }

          &.vcr-12-3gun {
            -ms-grid-columns: 8mm 37mm 10mm 6mm 23mm 10mm 18mm 10mm;
            grid-template-columns: 8mm 37mm 10mm 6mm 23mm 10mm 18mm 10mm;
            font-size: 0.85em;
          }

          &.vcr-13 {
            -ms-grid-columns: 8mm 40mm 27mm 9mm 10mm 18mm 10mm;
            grid-template-columns: 8mm 40mm 27mm 9mm 10mm 18mm 10mm;
            font-size: 0.85em;
          }
        }
      }
    }

    .vessel-card-content {
      display: block;
      position: relative;
      width: 1000px;
      height: 100%;
      transform-origin: top left;
      padding: 0;
      font-size: 1.4em;
      user-select: none;

      .card {
        border: 2px solid #000;
        border-radius: 0;

        .vessel-card-row {
          display: -ms-grid;
          display: grid;

          > div {
            background-color: #fff;

            &.yellow {
              background-color: #ffff66;
            }

            &.blue {
              background-color: #b8cce4;
            }

            &.light-blue {
              background-color: #daeef3;
            }

            &.black {
              background-color: #000;
              color: #fff;
            }

            &.brown {
              background-color: #d8e4bc;
            }

            &.grey {
              background-color: #bfbfbf;
            }
          }

          &.vcr-1 {
            -ms-grid-columns: 80px 120px 558px 114px 123px;
            grid-template-columns: 80px 120px 558px 114px 123px;

            .type-class {
              display: -ms-grid;
              display: grid;
              -ms-grid-columns: 100px 456px;
              grid-template-columns: 100px 456px;

              .vessel-type {
                white-space: nowrap;
              }

              .vessel-class {
                padding-right: 100px;
                white-space: nowrap;
                overflow: visible;
              }
            }

            .ships-in-class {
              font-size: 0.7em;

              > div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          &.vcr-2 {
            -ms-grid-columns: 80px 70px 272px 573px;
            grid-template-columns: 80px 70px 272px 573px;

            .vessel-accel {
              display: -ms-grid;
              display: grid;
              -ms-grid-columns: 136px 134px;
              grid-template-columns: 136px 134px;
            }

            .torpedos {
              display: -ms-grid;
              display: grid;
              -ms-grid-columns: 156px 92px 92px 62px 98px 73px;
              grid-template-columns: 156px 92px 92px 62px 98px 73px;

              > div {
                white-space: nowrap;
              }
            }
          }

          &.vcr-3 {
            -ms-grid-columns: 150px 122px 150px 150px 150px 150px 124px;
            grid-template-columns: 150px 122px 150px 150px 150px 150px 124px;
          }

          &.vcr-4,  &.vcr-5,  &.vcr-3gp,  &.vcr-3gs,  &.vcr-3gt {
            -ms-grid-columns: 70px 80px 122px 150px 150px 150px 150px 124px;
            grid-template-columns: 70px 80px 122px 150px 150px 150px 150px 124px;
          }

          &.vcr-6 {
            -ms-grid-columns: 70px 80px 122px 150px 150px 300px 124px;
            grid-template-columns: 70px 80px 122px 150px 150px 300px 124px;
          }

          &.vcr-7 {
            -ms-grid-columns: 498px 497px;
            grid-template-columns: 498px 497px;

            .ptb {
              padding: 2px 0;
            }
          }

          &.vcr-8 {
            -ms-grid-columns: 996px;
            grid-template-columns: 996px;
          }

          &.vcr-9 {
            -ms-grid-columns: 70px 188px 240px 298px 200px;
            grid-template-columns: 70px 188px 240px 298px 200px;

            .gatlings-ram {
              display: -ms-grid;
              display: grid;

              &.gr-1 {
                -ms-grid-columns: 100px 88px;
                grid-template-columns: 100px 88px;
              }

              &.gr-2 {
                -ms-grid-columns: 68px 120px;
                grid-template-columns: 68px 120px;
              }
            }
          }

          &.vcr-10 {
            -ms-grid-columns: 996px;
            grid-template-columns: 996px;
          }

          &.vcr-11 {
            -ms-grid-columns: 498px 164px 80px 174px 80px;
            grid-template-columns: 498px 164px 80px 174px 80px;

            .pad {
              padding: 2px 1px !important;
            }

            .vessel-details {
              font-size: 0.7em;

              > div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          &.vcr-12 {
            -ms-grid-columns: 80px 418px 164px 80px 174px 80px;
            grid-template-columns: 80px 418px 164px 80px 174px 80px;

            .pad {
              padding: 2px 1px !important;
            }
          }

          &.vcr-12-3gun {
            -ms-grid-columns: 80px 278px 80px 60px 164px 80px 174px 80px;
            grid-template-columns: 80px 278px 80px 60px 164px 80px 174px 80px;

            .pad {
              padding: 2px 1px !important;
            }
          }

          &.vcr-13 {
            -ms-grid-columns: 80px 302px 216px 64px 80px 174px 80px;
            grid-template-columns: 80px 302px 216px 64px 80px 174px 80px;

            .pad {
              padding: 2px 1px !important;
            }
          }
        }
      }

      .copyright {
        text-align: center;
      }

      .hull-indices {
        height: 60px;

        .hull-index {
          width: 42px;
          height: 42px;
          font-weight: bold;
          border-top: 1px solid #000;
          border-bottom: 1px solid #000;
          border-right: 1px solid #000;

          &:first-child {
            border-left: 1px solid #000;
          }

          &.dark {
            background-color: rgb(209, 203, 203);
          }
        }
      }

      .flag img {
        width: 113px;
        height: 75px;
        border: 1px solid black;
      }

      .vessel {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px solid #a52a2a;
        overflow: hidden;
        padding: 20px;
        height: 400px;

        img {
          flex: 0 0 auto;
          max-width: 956px;
          max-height: 360px;
        }
      }

      .pad {
        padding: 6px;
      }

      /*horizontal and vertical center align*/
      .hv-center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      /*horizontal center align*/
      .h-center {
        display: flex;
        justify-content: center;
      }

      .v-center {
        display: flex;
        align-items: center;
      }

      .ta-center {
        text-align: center;
      }

      .ta-right {
        text-align: right;
      }

      .em {
        font-weight: bold;
      }

      /*border right*/
      .br {
        border-right: 2px solid #000;
      }

      /*border bottom*/
      .bb {
        border-bottom: 2px solid #000;
      }
    }
  }
</style>
