import LocalForage from "localforage";

class UtilService {
  /* constructor() {
    console.log("UtilService");
  } */

  downloadImageFromGCPStorageBucket(
    imgRef,
    imgStorageBucketRef,
    imgStorageBucketMetadataLastUpdated,
    imgCacheKey
  ) {
    let self = this;
    let resp = new Promise(function(resolve, reject) {
      // Use the firebase.storage api to get the download URL for this vessel's image and convert it to base64. Then save that result to local storage.
      imgStorageBucketRef
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL for the image in the Google Cloud storage bucket
          self.getDataURL(url, (dataURLResp) => {
            if (dataURLResp) {
              // Save this data to the local cache
              self.setLocalCacheItem(imgCacheKey, imgRef, {
                key: imgRef,
                data: dataURLResp,
                gcpStorageLastUpdated: imgStorageBucketMetadataLastUpdated,
                info: "Image data based on file stored in GCP storage bucket.",
              });

              resolve({ imgData: dataURLResp });
            }
          });
        })
        .catch((error) => {
          reject(error);
        });
    });

    return resp;
  }

  getLocalCache(cacheKey) {
    let getCachePromise = new Promise(function(resolve, reject) {
      try {
        LocalForage.getItem(cacheKey).then(
          (resp) => {
            resolve({ cacheKey: cacheKey, cachedData: resp });
          },
          (err) => {
            let errMsg =
              "There was a problem calling LocalForage.getItem to retrieve cached data.";
            console.error(errMsg, err);
            reject({ status: 500, msg: errMsg, err: err });
          }
        );
      } catch (e) {
        let eMessage = "There was a problem accessing LocalForage.";
        console.error(eMessage, e.message);
        reject({ status: 500, msg: eMessage, err: e });
      }
    });

    return getCachePromise;
  }

  clearLocalCache(cacheKey) {
    let getCachePromise = new Promise(function(resolve, reject) {
      try {
        LocalForage.setItem(cacheKey, {}).then(
          () => {
            let successMsg = "Cleared " + cacheKey + ".";
            resolve({
              cacheKey: cacheKey,
              msg: successMsg,
            });
          },
          (err) => {
            let errMsg =
              "There was a problem calling LocalForage.setItem to clear cached data for " +
              cacheKey;
            console.error(errMsg, err);
            reject({ status: 500, msg: errMsg, err: err });
          }
        );
      } catch (e) {
        let eMessage = "There was a problem accessing LocalForage.";
        console.error(eMessage, e.message);
        reject({ status: 500, msg: eMessage, err: e });
      }
    });

    return getCachePromise;
  }

  getLocalCacheItem(cacheKey, cacheItemKey) {
    let self = this;
    let lcItemDataPromise = new Promise(function(resolve, reject) {
      self.getLocalCache(cacheKey).then(
        (resp) => {
          let cacheContents = resp.cachedData;

          if (!(cacheContents && Object.prototype.hasOwnProperty.call(cacheContents, cacheItemKey))) {
            // Matching data was not found in local storage
            reject({
              status: 404,
              msg: "The requested item was not found in local storage.",
              err: null,
            });
          } else {
            // Return the version of data in local storage.
            resolve({
              cacheKey: cacheKey,
              cacheItemKey: cacheItemKey,
              cacheItemData: cacheContents[cacheItemKey],
            });
          }
        },
        (e) => {
          reject({ status: e.status, msg: e.msg, err: e.err });
        }
      );
    });

    return lcItemDataPromise;
  }

  setLocalCacheItem(cacheKey, cacheItemKey, cacheItemData) {
    let self = this;
    let lcItemDataPromise = new Promise(function(resolve, reject) {
      let continueProcessing = (contents) => {
        if (!contents) {
          contents = {};
        }

        let itemCacheData = {};
        itemCacheData[cacheItemKey] = cacheItemData;

        let updatedContents = Object.assign({}, contents, itemCacheData);
        try {
          LocalForage.setItem(cacheKey, updatedContents).then(
            () => {
              let successMsg =
                "Added " +
                cacheItemKey +
                " data to LocalForage " +
                cacheKey +
                " cache.";

              resolve({
                cacheKey: cacheKey,
                cacheItemKey: cacheItemKey,
                cacheItemData: cacheItemData,
                msg: successMsg,
              });
            },
            (err) => {
              let errMsg =
                "There was a problem calling LocalForage.setItem to save cached data for " +
                cacheItemKey +
                " in " +
                cacheKey;
              console.error(errMsg, err);
              reject({ status: 500, msg: errMsg, err: err });
            }
          );
        } catch (e) {
          let eMessage = "There was a problem accessing LocalForage.";
          console.error(eMessage, e.message);
          reject({ status: 500, msg: eMessage, err: e });
        }
      };

      // Get the contents of the local cache first
      self.getLocalCache(cacheKey).then(
        (resp) => {
          continueProcessing(resp.cachedData);
        },
        () => {
          //reject({ status: e.status, msg: e.msg, err: e.err });
          continueProcessing(null);
        }
      );
    });

    return lcItemDataPromise;
  }

  getDataURL(url, callback) {
    let xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.send();
  }

  getUniqueByKey(sourceList, key) {
    if (Array.isArray(sourceList) && typeof key === "string" && key.length) {
      let items = [];
      sourceList.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          items.push(item[key]);
        }
      });

      return [...new Set(items)];
    }

    return sourceList;
  }

  sortAlphaNumeric(a, b) {
    return a.localeCompare(b, "en", { numeric: true });
  }

  sortVesselsAlphaNumeric(vesselA, vesselB) {
    let vATitle = vesselA.nat + " - " + vesselA.cls + " - " + vesselA._version;
    let vBTitle = vesselB.nat + " - " + vesselB.cls + " - " + vesselB._version;
    return vATitle.localeCompare(vBTitle, "en", { numeric: true });
  }

  sortByKeyAlphaNumeric(obj1, obj2) {
    let vA =
      obj1 && Object.prototype.hasOwnProperty.call(obj1, "key") && typeof obj1.key === "string"
        ? obj1.key
        : "";
    let vB =
      obj2 && Object.prototype.hasOwnProperty.call(obj2, "key") && typeof obj2.key === "string"
        ? obj2.key
        : "";
    return vA.localeCompare(vB, "en", { numeric: true });
  }

  armorLabel(a1, a2, ar) {
    if (
      typeof a1 == "number" &&
      typeof a2 == "number" &&
      a2 !== 0 &&
      typeof ar == "number" &&
      ar !== 0
    ) {
      let result = "Hood:Barb " + a1 + ":" + a2 + " @ ";
      let arWithPrecision = parseFloat(ar).toPrecision(5);
      let arLabel = "";

      switch (arWithPrecision) {
        case "0.16667":
          arLabel = "1 to 5";
          break;
        case "0.33333":
          arLabel = "1 to 2";
          break;
        case "0.50000":
          arLabel = "1 to 1";
          break;
        case "0.66667":
          arLabel = "2 to 1";
          break;
        case "0.83333":
          arLabel = "5 to 1";
          break;
        default:
          arLabel = "";
      }

      return result + arLabel;
    } else if (a1 == 0) {
      return "";
    }

    return a1;
  }

  speedLabel(speed) {
    if (typeof speed == "number" && speed % 1 > 0) {
      return parseInt(speed) + "+";
    }

    return speed;
  }

  escapeSpecialCharacters(str) {
    if (!(typeof str === "string")) {
      return str;
    }

    return str.replace(/&/g, "&amp;");
  }

  vesselsInClassLabel(cls, vic) {
    let _cls = typeof cls === "string" && cls.length ? cls.trim() : cls;
    let _vic = typeof vic === "string" && vic.length ? vic.trim() : vic;
    let vicResult = _vic;

    if (_vic == _cls) {
      vicResult = "";
    } else if (
      typeof _vic == "string" &&
      _vic.length &&
      _vic.indexOf(_cls + ", ") == 0
    ) {
      vicResult = _vic.replace(_cls + ", ", "");
    }

    vicResult = this.escapeSpecialCharacters(vicResult);

    return vicResult;
  }

  splitVesselsInClassLabel(vic) {
    let result = { ln1: null, ln2: null };

    if (typeof vic === "string" && vic.length > 50) {
      const parts = vic.split(",");
      let ln1 = "";
      let ln2 = "";

      parts.forEach((part) => {
        part = part.trim();
        if (ln1.length + part.length + 2 <= 50) {
          ln1 += ln1.length ? ", " + part : part;
        } else {
          ln2 += ln2.length ? ", " + part : part;
        }
      });

      if (typeof ln2 === "string" && ln2.length > 50) {
        ln2 = ln2.substr(0, 47) + "...";
      }
      result.ln1 = ln2.length ? ln1 + "," : ln1;
      result.ln2 = ln2.length ? ln2 : null;
    } else {
      result.ln1 = vic;
      result.ln2 = null;
    }

    return result;
  }

  replaceAccents(str) {
    const ACCENTS =
      "ÆÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽžŞş";
    const NON_ACCENTS =
      "AAAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZzSs";

    const strAccents = str.split("");
    const strAccentsOut = new Array();

    const strAccentsLen = strAccents.length;

    for (let y = 0; y < strAccentsLen; y++) {
      if (ACCENTS.indexOf(strAccents[y]) != -1) {
        strAccentsOut[y] = NON_ACCENTS.substr(
          ACCENTS.indexOf(strAccents[y]),
          1
        );
      } else {
        strAccentsOut[y] = strAccents[y];
      }
    }

    const newString = strAccentsOut.join("");
    return newString;
  }

  sanitizeVesselKey(vesselKey) {
    return this.replaceAccents(
      vesselKey
        .replace(/\s/g, "_")
        .replace(/\./g, "")
        .replace(/,/g, "")
        .replace(/'/g, "_")
        .replace(/"/g, "in")
    );
  }
}

export default new UtilService();
