import _ from "lodash";
import App from "./App.vue";
import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import Clipboard from "v-clipboard";
import "es6-promise/auto";
import firebase from "firebase/app";
import "firebase/firestore";
import LocalForage from "localforage";
import router from "./router";
import store from "./store";
import Toasted from "vue-toasted";
import Vue from "vue";
import VueAxios from "vue-axios";
import VueResize from "vue-resize";
import Rx from "rxjs/Rx";
import VueRx from "vue-rx";
import VesselsService from "./services/vesselsService";
import UserService from "./services/userService";
import UtilService from "./services/utilService";

let app = "";

//Display the application's last build date
console.info(
  "The BSBW Companion Site was last built on " + process.env.VUE_APP_BUILD_DATE
);

//Local Forage (local storage) configuration
LocalForage.config({
  driver: LocalForage.IndexedDB,
  name: "BSBW-Companion-Site",
  version: 1.0,
  storeName: "bsbw-data",
});

//Google Firebase Config (see vue.config.js)
firebase.initializeApp(
  JSON.parse(unescape(process.env.VUE_APP_FIREBASE_CONFIG))
);

firebase.firestore().settings({});

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    Vue.use(VueAxios, axios);
    Vue.use(BootstrapVue);
    Vue.use(Clipboard);
    Vue.use(Toasted);
    Vue.use(VueResize);
    Vue.use(VueRx, Rx);
    Vue.config.productionTip = false;

    Vue.$vesselsService = VesselsService;
    Object.defineProperty(Vue.prototype, "$vesselsService", {
      get() {
        return VesselsService;
      },
    });

    Vue.$userService = UserService;
    Object.defineProperty(Vue.prototype, "$userService", {
      get() {
        return UserService;
      },
    });

    Vue.$utilService = UtilService;
    Object.defineProperty(Vue.prototype, "$utilService", {
      get() {
        return UtilService;
      },
    });

    Object.defineProperty(Vue.prototype, "$_", {
      value: _,
    });

    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount("#app");
  }
});
