<template>
  <div>
    <b-modal
      id="emailFormModal"
      ref="emailFormModal"
      v-bind:title="modalTitle"
      size="lg"
      header-bg-variant="dark"
      header-text-variant="light"
      @shown="initializeModal"
    >
      <form>
        <b-form-group label="To" label-class="font-weight-bold" label-for="toEmailInput">
          <b-form-input
            id="toEmailInput"
            type="text"
            v-model.trim="to"
            placeholder="Enter the recipient's email address"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Subject" label-class="font-weight-bold" label-for="subjectInput">
          <b-form-input
            id="subjectInput"
            type="text"
            v-model.trim="subject"
            placeholder="Enter the email's subject"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Body" label-class="font-weight-bold" label-for="bodyInput">
          <b-form-textarea
            id="textarea"
            v-model="body"
            placeholder="Enter the email's body"
            rows="14"
          ></b-form-textarea>
        </b-form-group>
      </form>

      <div slot="modal-footer" class="w-100">
        <b-button variant="primary" class="float-right" v-bind:href="mailto">Send</b-button>
        <b-button variant="outline-secondary" class="float-right mr-2" v-on:click="closeModal">Close</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: "EmailFormModal",
    props: ["user", "mode"],
    data: function() {
      return {
        modalTitle: "Email Form",
        to: "",
        subject: "",
        body: "",
        bodyEditorOptions: {
          selector: "textarea",
          height: 400
        }
      };
    },
    computed: {
      mailto() {
        return (
          "mailto:" +
          this.to +
          "?subject=" +
          encodeURIComponent(this.subject) +
          "&body=" +
          encodeURIComponent(this.body)
        );
      }
    },
    methods: {
      initializeModal() {
        this.to = this.user ? this.user.id : "";

        switch (this.mode) {
          case "register":
            this.modalTitle = "Site Registration Email Form";
            this.subject =
              "Black Smoke Blue Water: Your site registration information";
            this.body =
              "Hello " +
              this.user.fName +
              ",\n\nWelcome to the Black Smoke Blue Water game companion site. You must register with the site before accessing site content.\n\nFollow the registration link provided below or copy it into a new browser tab. On the registration screen, enter your email address and the registration key provided below.\n\nhttps://bsbw.deadlywarfaregames.com/#/register\n\nEmail: " +
              this.user.id +
              "\nRegistration Key: " +
              this.user.rKey +
              "\nPassword: Create a new password that you will use to sign into the site once registered.\n\nIf you have any problems registering with the site, please reply to this email and let us know.\n\nThanks,\nYour Black Smoke Blue Water companion site team";
            break;

          case "preview":
            this.modalTitle = "Site Preview Mode Email Form";
            this.to = "";
            this.subject = "Black Smoke Blue Water: Site preview information";
            this.body =
              "Hello _________,\n\nWelcome to the Black Smoke Blue Water game companion site. We are providing you with access to a limited preview of the site.\n\nFollow the link to the site's Sign In screen provided below or copy it into a new browser tab.\n\nhttps://bsbw.deadlywarfaregames.com/#/login\n\nOn the Sign In screen, enter the email address and password provided below to sign in.\nEmail: " +
              this.user.id +
              "\nPassword: " +
              this.user.pw +
              "\n\nIf you have any problems signing into the site, please reply to this email and let us know.\n\nThanks,\nYour Black Smoke Blue Water companion site team";
            break;

          default:
            this.modalTitle = "Email Form";
            this.subject = "";
            this.body = "";
        }
      },
      closeModal() {
        this.$refs.emailFormModal.hide();
      }
    }
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";
  #emailFormModal {
    .modal-body {
      min-height: 0;
      max-height: 80vh;
      overflow-y: auto;

      .body-editor-result {
        min-height: 300px;
        border: 1px solid #000000;
      }
    }
  }
</style>
