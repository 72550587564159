<template>
  <div class="copyright">Copyright {{today.getFullYear()}} Deadly Warfare Games</div>
</template>

<script>
  export default {
    name: "Copyright",
    data: function() {
      return {
        today: new Date()
      };
    }
  };
</script>
