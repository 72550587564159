<template>
  <div class="app-main">
    <div class="register">
      <div class="registration-wrapper">
        <b-card
          v-if="!userAgreesToTerms"
          class="registration-card"
          title="Conditions of Use"
        >
          <TermsOfUse></TermsOfUse>

          <div class="button-wrapper">
            <b-button
              variant="primary"
              size="sm"
              v-on:click="continueToRegistration"
            >
              <i class="far fa-check-square mr-1"></i>I Agree to the conditions
            </b-button>
          </div>
        </b-card>

        <b-card
          v-if="userAgreesToTerms"
          class="registration-card"
          title="Account Registration"
        >
          <p>
            <span
              >Enter the email address and registration code you were sent
              below. Then, create a new password for your account and click
              the&nbsp;</span
            >
            <b>Register</b> button to complete your registration.
          </p>

          <p>
            <span>If you've already registered, click the&nbsp;</span>
            <b>Sign In</b> link in the header to sign in.
          </p>

          <b-form-group label="Email*" label-for="emailInput">
            <b-form-input
              id="emailInput"
              type="text"
              v-model="regEmail"
              placeholder="Enter your email address"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Registration Code* (e.g. '0000-00-0000')"
            label-for="regKeyInput"
          >
            <b-form-input
              id="regKeyInput"
              type="text"
              v-model="regKey"
              placeholder="Enter your registration code"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Create New Password*"
            label-for="passwordInput"
            description="The password must be at least six characters long."
          >
            <b-form-input
              id="passwordInput"
              type="password"
              v-model="regPassword"
              placeholder="Enter a new password"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Verify New Password*"
            label-for="verifyPasswordInput"
          >
            <b-form-input
              id="verifyPasswordInput"
              type="password"
              v-model="regPasswordVerify"
              placeholder="Enter your new password again"
            ></b-form-input>
          </b-form-group>

          <div class="button-wrapper">
            <b-button
              class="mr-2"
              variant="outline-secondary"
              size="sm"
              v-on:click="reviewTermsOfUse"
            >
              <i class="far fa-arrow-alt-circle-left mr-1"></i>Review Conditions
              of Use
            </b-button>
            <b-button variant="primary" size="sm" v-on:click="register"
              >Register</b-button
            >
          </div>
        </b-card>
      </div>

      <GameLinks class="col-layout"></GameLinks>
    </div>

    <AppFooter></AppFooter>
  </div>
</template>

<script>
  import AppFooter from "../components/AppFooter.vue";
  import TermsOfUse from "../components/TermsOfUse.vue";
  //import axios from "axios";
  //import firebase from "firebase/app";
  import "firebase/auth";
  import GameLinks from "../components/GameLinks.vue";
  //import Vue from "vue";

  export default {
    name: "Register",
    components: { AppFooter, GameLinks, TermsOfUse },
    data() {
      return {
        regEmail: "",
        regPassword: "",
        regPasswordVerify: "",
        regKey: "",
        userAgreesToTerms: false,
      };
    },
    methods: {
      continueToRegistration: function () {
        this.userAgreesToTerms = true;
      },
      register: function () {
        if (
          this.regEmail.length &&
          this.regPassword.length &&
          this.regPasswordVerify.length &&
          this.regKey.length
        ) {
          //let self = this;

          if (this.regPassword !== this.regPasswordVerify) {
            alert(
              "The value you entered in the password verification field does not match the value in the new password field. Please check the password fields."
            );
            return;
          }

          /*axios
            .get(
              "https://us-central1-bsbw-companion-site.cloudfunctions.net/preRegisteredAccountExists",
              { params: { id: this.regEmail, key: this.regKey } }
            )
            .then(() => {
              firebase
                .auth()
                .createUserWithEmailAndPassword(self.regEmail, self.regPassword)
                .then(() => {
                  firebase
                    .firestore()
                    .collection("users")
                    .doc(self.regEmail)
                    .set({ rc: true }, { merge: true })
                    .then(() => {
                      Vue.toasted.show("Your account registration is complete!", {
                        theme: "toasted-primary",
                        position: "top-right",
                        duration: 3000,
                      });

                      self.$store.dispatch("user/initialize");
                    })
                    .catch((errResp) => {
                      alert(errResp.message);
                    });
                })
                .catch((errResp) => {
                  alert(errResp.message);
                });
            })
            .catch((errResp) => {
              let msg =
                errResp.response && errResp.response.data
                  ? errResp.response.data.message
                  : errResp.message;
              alert(msg);
            });*/
        } else {
          alert("Please fill in all registration fields.");
        }
      },
      reviewTermsOfUse: function () {
        this.userAgreesToTerms = false;
      },
    },
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";

  .app-main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100px;

    .register {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 40px 20px;
      overflow-y: auto;
      background-color: $bsbw_blue_800;

      .registration-wrapper {
        flex: 1 1 auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 20px;
      }

      .registration-card {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        min-height: 60px;
        border: 2px solid #fff;

        label {
          font-weight: bold;
        }

        input {
          width: 100%;
          margin-bottom: 10px;
        }

        .button-wrapper {
          display: flex;
          justify-content: flex-end;
        }
      }

      .game-links {
        flex: 1 1 auto;
      }
    }

    .app-footer {
      flex: 0 0 auto;
    }
  }
</style>
