import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Vue from "vue";
import CommonConstants from "../../common/constants";

// initial state
// current {Object} - basic profile object of authenticated user {id, email}
// isSa {boolean} - indicates whether the user is a site admin or not
// hasDv {boolean} - indicates whether the user can delete a vessel or not
// inDm {boolean} - indicates whether the site is running in demo mode or not
// userInfo {Object} - user's name and assigned vessels
// userData {Object} - user-generated data such as vessel sets
// userPerms {Object} - user's site permissions
// routeHandler {Function} - reference to a function that will call for a route change
// supportedBrowser {boolean} - indicates whether the user is viewing the site in a supported browser or not
// all {array} - a list of all site users (loaded for a site admin only)
const state = {
  current: null,
  isSa: false,
  hasDv: false,
  inDm: false,
  userInfo: null,
  userData: null,
  userPerms: null,
  routeHandler: null,
  supportedBrowser: null,
  all: null,
};

// getters
const getters = {};

// actions
const actions = {
  getUserData({ commit, dispatch }, aUserProfile) {
    if (!aUserProfile) {
      dispatch("logOut");
    } else {
      firebase
        .firestore()
        .collection("user-data")
        .doc(aUserProfile.id)
        .get()
        .then((userDataRef) => {
          if (userDataRef.exists) {
            let userData = userDataRef.data();
            commit("setUserData", userData);
            dispatch("config/loadAssetsConfig", null, { root: true });
            dispatch("config/loadListsConfig", null, { root: true });
            dispatch("config/loadGameCards", null, { root: true });
            dispatch("sets/setVesselSetKeys", userData.vesselSets, {
              root: true,
            });
          }
        });
    }
  },
  getUserInfo({ commit, dispatch }, pData) {
    let aUserProfile = pData.profile;

    if (!aUserProfile) {
      dispatch("logOut");
    } else {
      firebase
        .firestore()
        .collection("users")
        .doc(aUserProfile.id)
        .get()
        .then((userDocRef) => {
          if (userDocRef.exists) {
            let userInfo = userDocRef.data(); // {fName, lName, rKey, rc}
            commit("setUserInfo", userInfo);

            //Pull user's site permissions
            firebase
              .firestore()
              .collection("permissions")
              .doc(aUserProfile.id)
              .get()
              .then((permsDocRef) => {
                if (permsDocRef.exists) {
                  let userPerms = permsDocRef.data();
                  commit("setUserPerms", userPerms);
                  dispatch("requestRoute", pData.route);

                  // Load all vessel metadata
                  let allVesselKeys = [];
                  firebase
                    .firestore()
                    .collection("vessels-metadata")
                    .get()
                    .then((vesselsMetadataSnapshot) => {
                      let vesselsDataVersions = [];
                      vesselsMetadataSnapshot.docs.forEach((docRef) => {
                        if (docRef.exists) {
                          vesselsDataVersions.push({
                            value: docRef.id,
                            text: docRef.id,
                          });
                          let vMetadata = docRef.data();

                          if (Array.isArray(vMetadata.keys)) {
                            vMetadata.keys.forEach((key) => {
                              allVesselKeys.push(key);
                            });
                          }
                        }
                      });

                      Vue.$vesselsService.setVesselsDataVersions(
                        vesselsDataVersions
                      );

                      // Filter the vessels metadata keys for the non-admin user
                      if (
                        !(userPerms.sap && userPerms.sap.sa) &&
                        Array.isArray(userPerms.vessels)
                      ) {
                        let filteredVesselKeys = allVesselKeys.filter(
                          (vessel) => {
                            return userPerms.vessels.indexOf(vessel.key) >= 0;
                          }
                        );
                        allVesselKeys = filteredVesselKeys;
                      }

                      //Update the vessels state with the vessel keys the user has access to
                      Vue.$vesselsService.initializeVesselsMetadata({
                        localCacheKey: CommonConstants.LF_VESSEL_CARDS_CACHE,
                        label: "Primary Vessels (From Database)",
                        vesselKeys: allVesselKeys,
                      });
                    });
                }
              });
          } else {
            alert(
              "A registered user account could not be found. Please contact the site administrator for assistance."
            );
            dispatch("logOut");
          }
        });
    }
  },
  initialize({ commit, dispatch }, data) {
    let aUser = firebase.auth().currentUser;

    if (data) {
      commit("setRouteHandler", data.requestRoute);
    }

    if (aUser) {
      let profile = { id: aUser.uid, email: aUser.email };
      dispatch("setCurrent", profile);
      dispatch("getUserInfo", {
        profile: profile,
        route: data && data.pendingRoute ? data.pendingRoute : "/",
      });
      dispatch("getUserData", profile);
    } else {
      dispatch("logOut", false);
      dispatch(
        "requestRoute",
        data && data.pendingRoute ? data.pendingRoute : "/login"
      );
    }
  },
  loadAllUsers({ commit }) {
    firebase
      .firestore()
      .collection("users")
      .orderBy("lName")
      .orderBy("fName")
      .get()
      .then((querySnapshot) => {
        let usersFromDb = querySnapshot.docs.map((doc) => {
          if (doc.exists) {
            return { id: doc.id, ...doc.data() };
          }
        });

        commit("setAllUsers", usersFromDb);
      })
      .catch(function(error) {
        console.error(error.message);
      });
  },
  logOut({ commit, dispatch }, routeAfterAction = true) {
    let au = firebase.auth().currentUser;

    let continueCommit = function() {
      commit("cleanup", null);
      dispatch("vessels/cleanup", null, {
        root: true,
      });
      dispatch("sets/cleanup", null, {
        root: true,
      });
      dispatch("print/cleanup", null, { root: true });

      if (routeAfterAction) {
        dispatch("requestRoute", "/");
      }
    };

    if (au) {
      firebase
        .auth()
        .signOut()
        .then(continueCommit);
    } else {
      continueCommit();
    }
  },
  setSupportedBrowser({ commit }, supportedBrowser) {
    commit("setSupportedBrowser", supportedBrowser);
  },
  refreshUserData({ dispatch, state }) {
    dispatch("getUserData", state.current);
  },
  requestRoute({ state }, routeName) {
    if (typeof state.routeHandler === "function") {
      state.routeHandler(routeName);
    }
  },
  setCurrent({ commit }, aUserProfile) {
    commit("setCurrent", aUserProfile);
  },
};

// mutations
const mutations = {
  cleanup(state) {
    state.current = null;
    state.isSa = false;
    state.hasDv = false;
    state.inDm = false;
    state.userInfo = null;
    state.userData = null;
  },
  setAllUsers(state, users) {
    state.all = users;
  },
  setCurrent(state, aUserProfile) {
    state.current = aUserProfile;
  },
  setRouteHandler(state, handler) {
    state.routeHandler = handler;
  },
  setUserData(state, userData) {
    state.userData = userData;
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
  setSupportedBrowser(state, supportedBrowser) {
    state.supportedBrowser = supportedBrowser;
  },
  setUserPerms(state, userPerms) {
    state.userPerms = userPerms;
    state.isSa = userPerms && userPerms.sap && userPerms.sap.sa;
    state.hasDv = userPerms && userPerms.sap && userPerms.sap.dv;
    state.inDm = userPerms && userPerms.sap && userPerms.sap.dm;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
