<template>
  <div class="app-main user-profile">
    <main>
      <div class="actions">
        <h1 v-if="!inDm" class="navbar-brand section-title">Account</h1>

        <b-card v-if="!inDm" title="Reset Account Password">
          Clicking the 'Reset Password' button will send a password reset email to the email address associated with your account. Your current account password will not be reset until you set a new password using the link provided in the password reset email.
          <b-button
            variant="primary"
            slot="footer"
            size="sm"
            v-b-modal.resetPasswordConfirmationModal
          >Reset Password</b-button>
        </b-card>

        <h1 class="navbar-brand section-title">General Information</h1>

        <b-card title="Last Build Date">{{lastBuildDate}}</b-card>

        <b-card title="Browser Info">
          This is your browser's identifier string. You may be asked to send this to your system administrator if you happen to have a problem in the site.
          <div class="user-agent">{{browserUserAgent}}</div>

          <b-button
            variant="primary"
            slot="footer"
            size="sm"
            v-clipboard="() => browserUserAgent"
            v-clipboard:success="clipboardSuccessHandler"
          >Copy to Clipboard</b-button>
        </b-card>

        <h1 class="navbar-brand section-title">Data Cache</h1>

        <b-card title="Clear Cached Site Data">
          This site stores a small amount of vessel data in your browser's local storage to enable faster vessel card display. Click "Clear Vessel Cache" to clear that stored data.
          
          <div slot="footer" class="d-flex flex-wrap justify-content-end">
            <b-button
              class="mt-3"
              variant="light"
              size="sm"
              v-on:click="clearCachedNationData"
              title="This clears the base64 versions of the nation flag images stored in your browser's local storage."
            >Clear Cached Flag Image Data</b-button>

            <b-button
              class="mt-3 ml-3"
              variant="light"
              size="sm"
              v-on:click="clearCachedVesselData"
              title="This clears the base64 versions of the vessel images stored in your browser's local storage."
            >Clear Cached Vessel Image Data</b-button>

            <b-button
              class="mt-3 ml-3"
              variant="light"
              size="sm"
              v-on:click="clearCachedGameCardData"
              title="This clears the base64 versions of the Game Card images stored in your browser's local storage."
            >Clear Cached Game Card Image Data</b-button>
            
            <b-button
              class="mt-3 ml-3"
              variant="primary"
              size="sm"
              v-on:click="clearCachedSiteData"
              title="This clears the vessel detail data stored in your browser's local storage."
            >Clear Vessel Cache</b-button>
          </div>
        </b-card>

        <!--<b-card no-body>
          <b-card-body>
            <b-card-title>Load Vessel Data From File</b-card-title>
            <b-card-text>
              Click "Load Vessel Data From File" to choose a vessel data JSON file and save that data to the browser's local cache.
            </b-card-text>
          </b-card-body>

          <b-card-footer footer-class="card-footer">
            <b-form-file
              v-model="vesselDataFile"
              :state="Boolean(vesselDataFile)"
              placeholder="Choose a JSON file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".json"
              size="sm"
            ></b-form-file>

            <b-button
              variant="primary"
              size="sm"
              v-on:click="chooseVesselDataFile"
              class="ml-3"
              :disabled="!vesselDataFile"
            >Load Vessel Data From File</b-button>
          </b-card-footer>
        </b-card>-->

        <div v-if="isSa">
          <h1 class="navbar-brand section-title">Database Actions</h1>

          <b-card title="Load Vessels From Database">
            Load data for all vessels from the database. The vessel data will be stored in the browser's local cache. This is an Administrator-only feature and should only be executed when needed.
            <b-button
              variant="primary"
              slot="footer"
              size="sm"
              v-b-modal.loadVesselsConfirmationModal
            >Load All Vessels</b-button>
          </b-card>
        </div>
      </div>
    </main>

    <b-modal
      id="resetPasswordConfirmationModal"
      title="Reset Your Password?"
      header-bg-variant="warning"
      v-if="!inDm"
      @ok="changeUserPassword"
      ok-title="Yes"
      ok-variant="outline-secondary"
      cancel-title="No"
      cancel-variant="primary"
      centered
    >Are you sure you want to reset your password?</b-modal>

    <b-modal
      id="loadVesselsConfirmationModal"
      title="Pull vessels data from database?"
      header-bg-variant="warning"
      v-if="isSa"
      @ok="loadAllVesselsFromDb"
      ok-title="Yes"
      ok-variant="outline-secondary"
      cancel-title="No"
      cancel-variant="primary"
      centered
    >Are you sure you want to pull all vessel data from the database?</b-modal>
  </div>
</template>

<script>
  import firebase from "firebase/app";
  import "firebase/auth";
  import Vue from "vue";

  export default {
    name: "UserProfile",
    data () {
      return {
        vesselDataFile: null
      }
    },
    computed: {
      browserUserAgent: {
        get() {
          return window.navigator.userAgent;
        }
      },
      inDm: {
        get() {
          return this.$store.state.user.inDm;
        }
      },
      isSa: {
        get() {
          return this.$store.state.user.isSa;
        }
      },
      lastBuildDate() {
        return "This site was last built on " + process.env.VUE_APP_BUILD_DATE;
      }
    },
    methods: {
      clearCachedSiteData() {
        this.$store.dispatch("vessels/clearCachedData");
      },
      clearCachedNationData() {
        this.$store.dispatch("vessels/clearCachedNationImageData");
      },
      clearCachedVesselData() {
        this.$store.dispatch("vessels/clearCachedVesselImageData");
      },
      clearCachedGameCardData() {
        this.$store.dispatch("config/clearCachedGameCardImageData");
      },
      clipboardSuccessHandler() {
        this.$toasted.show("Clipboard updated!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 3000
        });
      },
      changeUserPassword() {
        if (this.inDm) {
          return;
        }

        let self = this;
        let email =
          this.$store.state.user && this.$store.state.user.current
            ? this.$store.state.user.current.email
            : null;

        if (!email) {
          this.$toasted.show(
            "A problem was encountered. A reset email has not been sent.",
            {
              theme: "toasted-primary",
              position: "top-right",
              duration: 3000
            }
          );
          return;
        }
        firebase
          .auth()
          .sendPasswordResetEmail(email)
          .then(function() {
            self.$toasted.show(
              "A password reset email has been sent to " + email,
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 3000
              }
            );
          })
          .catch(function(error) {
            console.error(error);
          });
      },
      chooseVesselDataFile() {
        var fileReader = new FileReader( );
        fileReader.onload = ( evt ) => { Vue.$vesselsService.processLocalVesselDataFile(evt.target.result); };
        fileReader.readAsText( this.vesselDataFile );
      },
      loadAllVesselsFromDb() {
        Vue.$vesselsService.loadAllVesselsFromDb().then(
          (/*vesselsFromDb*/) => {
            this.$toasted.show(
              "Data for all vessels was successfully loaded from the database.",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 3000
              }
            );
          },
          (err) => {
            console.error(err);
          }
        );
      }
    }
  };
</script>

<style lang="scss">
  .user-profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    main {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 0;

      .actions {
        padding: 0 2rem 2rem 2rem;

        .section-title {
          margin: 1rem 0;
        }

        .user-agent {
          padding: 20px;
          margin-top: 10px;
          border: 1px solid #ccc;
          border-radius: 3px;
        }
        .card .card-footer {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .b-form-file {
            flex: 1 1 auto;
            max-width: 30rem;
          }

          button {
            flex: 0 0 auto;
          }
        }
        .card + .card {
          margin-top: 40px;
        }
      }
    }
  }
</style>
