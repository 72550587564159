<template>
  <div>
    <b-modal
      id="vesselDetailsModal"
      ref="vesselDetailsModal"
      title="Vessel Details"
      header-bg-variant="dark"
      header-text-variant="light"
      size="lg"
      @ok="saveVesselData"
      ok-title="Save"
      cancel-variant="outline-secondary"
      @shown="initializeModal"
    >
      <form>
        <b-card
          bg-variant="light"
          class="mt-3"
          v-for="group of vesselFormConfig.groups"
          :key="group.id"
        >
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle="group.id" variant="outline-primary" class="d-flex align-items-center justify-content-start" v-bind:title="(group.expanded ? 'Collapse ' : 'Expand ') + group.label + ' Section'">
              <div class="flex-grow-1 flex-shrink-1 text-left">{{group.label}}</div>
              <i class="fas" v-bind:class="{'fa-minus': group.expanded, 'fa-plus': !group.expanded}"></i>
            </b-button>
          </b-card-header>
          <b-collapse v-bind:id="group.id" visible v-model="group.expanded" role="tabpanel">
            <b-card-body>
            <!-- v-bind:label="group.label" label-size="lg" label-class="font-weight-bold pt-0"-->
            <b-form-group class="mb-0" >
              <b-alert show variant="info" v-if="group.instructions && group.instructions.length > 0">{{group.instructions}}</b-alert>
              <b-form-group
                v-for="field of group.fields"
                :key="field.id"
                label-cols="12"
                content-cols="12"
                v-bind:label="field.label"
                label-class="font-weight-bold"
                v-bind:label-for="field.id"
              >
                <b-alert show variant="light" v-if="field.instructions && field.instructions.length > 0">{{field.instructions}}</b-alert>
                
                <b-form-input
                  v-if="field.readOnly && field.inputType !== 'checkbox'"
                  v-bind:id="field.id"
                  v-bind:type="field.inputType"
                  v-model="vessel[field.key]"
                  plaintext
                  v-bind:aria-describedby="(field.id + 'help-block')"
                ></b-form-input>
                
                <b-form-input
                  v-if="!field.readOnly && field.inputType !== 'checkbox'"
                  v-bind:id="field.id"
                  v-bind:type="field.inputType"
                  v-model="vessel[field.key]"
                  v-bind:aria-describedby="(field.id + 'help-block')"
                ></b-form-input>

                <b-form-checkbox 
                  v-if="field.inputType === 'checkbox'" 
                  v-bind:id="field.id" 
                  v-model="vessel[field.key]"
                  v-bind:aria-describedby="(field.id + 'help-block')"
                  v-bind:disabled="field.readOnly">
                  <span v-if="!!vessel[field.key]">{{field.trueLabel}}</span>
                  <span v-if="!vessel[field.key]">{{field.falseLabel}}</span>
                </b-form-checkbox>
                
                <b-form-text v-bind:id="(field.id + 'help-block')">
                  <span>Field: {{field.key}}</span>
                  <span v-if="field.helpText && field.helpText.length > 0"><br/>{{field.helpText}}</span>
                </b-form-text>
              </b-form-group>
            </b-form-group>
          </b-card-body>
          </b-collapse>
        </b-card>
      </form>

      <template v-slot:modal-footer="{ ok, cancel }">
        <div class="d-flex w-100">
          <div class="flex-grow-1 flex-shrink-1">
            <b-button @click="expandAll()" variant="outline-secondary" class="mr-2" title="Expand all Sections"><i class="fas fa-plus mr-1"></i>Expand All</b-button>
            <b-button @click="collapseAll()" variant="outline-secondary" title="Collapse all Sections"><i class="fas fa-minus mr-1"></i>Collapse All</b-button>
          </div>

          <div class="flex-grow-0 flex-shrink-0">
            <b-button @click="cancel()" variant="outline-secondary" class="mr-2">Cancel</b-button>

            <b-button
              class="mr-2"
              variant="outline-secondary"
              v-b-modal.jsonOutputModal
            >
              JSON
            </b-button>

            <b-button @click="ok()" variant="primary">Save</b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="jsonOutputModal"
      title="Vessel JSON Output"
      header-bg-variant="dark"
      header-text-variant="light"
      @shown="initializeJsonModal"
      ok-only
      ok-title="Close"
      size="lg"
      centered>
      
      <b-card>
          <div class="json-output">{{jsonOutput}}</div>

          <b-button
            variant="primary"
            slot="footer"
            size="sm"
            v-on:click="copyToClipboard"
          >Copy to Clipboard</b-button>
        </b-card>
    </b-modal>
  </div>
</template>

<script>
  import Vue from "vue";

  export default {
    name: "VesselDetailsModal",
    props: ["vesselKey"],
    components: {},
    data: function () {
      return {
        vessel: {},
        jsonOutput: "",
        vesselFormConfig: {
          groups: []
        },
        vesselFormConfigTemplate: {
          groups: [
            {
              id: "vessel",
              expanded: true,
              label: "Vessel",
              instructions: "",
              fields: [
                {
                  label: "Display Name:",
                  id: "vessel-display-name",
                  inputType: "text",
                  key: "_name",
                  helpText: "The display name can be shown on the vessel card in place of the default vessel class name that is shown at the top of the vessel card."
                },
                {
                  label: "Class:",
                  id: "vessel-class",
                  inputType: "text",
                  key: "cls",
                  readOnly: true,
                },
                {
                  label: "Nation:",
                  id: "vessel-nation",
                  inputType: "text",
                  key: "nat",
                  readOnly: true,
                },
                {
                  label: "Key:",
                  id: "vessel-key",
                  inputType: "text",
                  key: "key",
                  readOnly: true,
                },
                {
                  label: "Data Version:",
                  id: "vessel-version",
                  inputType: "text",
                  key: "_version",
                  readOnly: true,
                },
                {
                  label: "Last Updated:",
                  id: "vessel-last-updated",
                  inputType: "text",
                  key: "_lastUpdated",
                  readOnly: true,
                },
                {
                  label: "Display State:",
                  trueLabel: "Active",
                  falseLabel: "Inactive",
                  id: "vessel-active",
                  inputType: "checkbox",
                  key: "_active",
                  helpText: "Indicates whether this vessel is considered active or inactive for filtering purposes."
                }
              ],
            },
            {
              id: "specs",
              expanded: true,
              label: "Specs",
              instructions: "",
              fields: [
                {
                  label: "Type:",
                  id: "vessel-type",
                  inputType: "text",
                  key: "type",
                },
                {
                  label: "Vessels in Class:",
                  id: "vessel-vic",
                  inputType: "text",
                  key: "vic",
                  helpText: "Note: The values in fields _vic1 and _vic2 correspond to this field and are used to break long strings in this field into two lines when displayed in the vessel card."
                },
                {
                  label: "Vessels in Class Line 1 (Metadata):",
                  id: "vessel-meta-vic1",
                  inputType: "text",
                  key: "_vic1",
                  helpText: "Note: This field is generated during data import and is displayed in the vessel card."
                },
                {
                  label: "Vessels in Class Line 2 (Metadata):",
                  id: "vessel-meta-vic2",
                  inputType: "text",
                  key: "_vic2",
                  helpText: "Note: This field is generated during data import and is displayed in the vessel card."
                },
                {
                  label: "Year:",
                  id: "vessel-year",
                  inputType: "number",
                  key: "yr",
                },
                {
                  label: "Crew:",
                  id: "vessel-crew",
                  inputType: "number",
                  key: "crew",
                },
                {
                  label: "Points:",
                  id: "vessel-pts",
                  inputType: "text",
                  key: "pts",
                },
                {
                  label: "Hull:",
                  id: "vessel-hull",
                  inputType: "number",
                  key: "hull",
                },
                {
                  label: "Vitals:",
                  id: "vessel-vit",
                  inputType: "number",
                  key: "vit",
                },
                {
                  label: "Move/Speed:",
                  id: "vessel-spd",
                  inputType: "number",
                  key: "spd",
                },
                {
                  label: "Move/Speed (Metadata):",
                  id: "vessel-meta-speed",
                  inputType: "text",
                  key: "_spd",
                  helpText: "Note: This field is generated during data import and is displayed in the vessel card."
                },
                {
                  label: "Acceleration:",
                  id: "vessel-acl",
                  inputType: "number",
                  key: "acl",
                },
                {
                  label: "Circle:",
                  id: "vessel-cir",
                  inputType: "number",
                  key: "cir",
                },
                {
                  label: "Ram:",
                  id: "vessel-ram",
                  inputType: "number",
                  key: "ram",
                },
                {
                  label: "Other Details:",
                  id: "vessel-det",
                  inputType: "text",
                  key: "det",
                }
              ],
            },
            {
              id: "armor",
              expanded: true,
              label: "Armor",
              instructions: "",
              fields: [
                {
                  label: "Primary 1:",
                  id: "vessel-pri-first",
                  inputType: "number",
                  key: "ap1",
                },
                {
                  label: "Primary 2:",
                  id: "vessel-pri-second",
                  inputType: "number",
                  key: "ap2",
                },
                {
                  label: "Primary Ratio:",
                  id: "vessel-pri-ratio",
                  inputType: "number",
                  key: "apr",
                },
                {
                  label: "Hood:Barb Armor Labeling - Primary (Metadata):",
                  id: "vessel-meta-apri",
                  inputType: "text",
                  key: "_apri",
                  helpText: "Note: This is a generated field during data import and is displayed in the vessel card. It is based on the following fields: ap1, ap2, apr."
                },
                {
                  label: "Secondary 1:",
                  id: "vessel-sec",
                  inputType: "number",
                  key: "as1",
                },
                {
                  label: "Secondary 2:",
                  id: "vessel-dec-second",
                  inputType: "number",
                  key: "as2",
                },
                {
                  label: "Secondary Ratio:",
                  id: "vessel-sec-ratio",
                  inputType: "number",
                  key: "asr",
                },
                {
                  label: "Hood:Barb Armor Labeling - Secondary (Metadata):",
                  id: "vessel-meta-asec",
                  inputType: "text",
                  key: "_asec",
                  helpText: "Note: This field is generated during data import and is displayed in the vessel card. It is based on the following fields: as1, as2, asr."
                },
                {
                  label: "Armor (P/S):",
                  id: "vessel-armor-3g",
                  inputType: "text",
                  key: "sat",
                  threeGunModeOnly: true
                },
                {
                  label: "QF:",
                  id: "vessel-qf",
                  inputType: "number",
                  key: "qf",
                },
                {
                  label: "Water Line:",
                  id: "vessel-wl",
                  inputType: "number",
                  key: "wl",
                },
                {
                  label: "Upper Hull:",
                  id: "vessel-uh",
                  inputType: "number",
                  key: "uh",
                },
                {
                  label: "Con:",
                  id: "vessel-con",
                  inputType: "number",
                  key: "conn",
                },
                {
                  label: "Deck:",
                  id: "vessel-deck",
                  inputType: "number",
                  key: "deck",
                },
              ],
            },
            {
              id: "primary-guns",
              expanded: true,
              label: "Primary Guns (1 Primary Gun)",
              instructions: "",
              fields: [
                {
                  label: "No:",
                  id: "vessel-gpno",
                  inputType: "number",
                  key: "gpno",
                },
                {
                  label: "Size:",
                  id: "vessel-gpsz",
                  inputType: "number",
                  key: "gpsz",
                },
                {
                  label: "Class (large/small):",
                  id: "vessel-gpc",
                  inputType: "text",
                  key: "gpc",
                },
                {
                  label: "S:",
                  id: "vessel-gps",
                  inputType: "number",
                  key: "gps",
                },
                {
                  label: "M:",
                  id: "vessel-gpm",
                  inputType: "number",
                  key: "gpm",
                },
                {
                  label: "L:",
                  id: "vessel-gpl",
                  inputType: "number",
                  key: "gpl",
                },
                {
                  label: "E:",
                  id: "vessel-gpe",
                  inputType: "number",
                  key: "gpe",
                },
                {
                  label: "Dmg:",
                  id: "vessel-gpd",
                  inputType: "number",
                  key: "gpd",
                },
                {
                  label: "RoF:",
                  id: "vessel-gpr",
                  inputType: "number",
                  key: "gpr",
                },
              ],
            },
            {
              id: "secondary-guns",
              expanded: true,
              label: "Secondary Guns (1 Primary Gun)",
              instructions: "",
              fields: [
                {
                  label: "No:",
                  id: "vessel-gsno",
                  inputType: "number",
                  key: "gsno",
                },
                {
                  label: "Size:",
                  id: "vessel-gssz",
                  inputType: "number",
                  key: "gssz",
                },
                {
                  label: "Class (large/small):",
                  id: "vessel-gsc",
                  inputType: "text",
                  key: "gsc",
                },
                {
                  label: "S:",
                  id: "vessel-gss",
                  inputType: "number",
                  key: "gss",
                },
                {
                  label: "M:",
                  id: "vessel-gsm",
                  inputType: "number",
                  key: "gsm",
                },
                {
                  label: "L:",
                  id: "vessel-gsl",
                  inputType: "number",
                  key: "gsl",
                },
                {
                  label: "E:",
                  id: "vessel-gse",
                  inputType: "number",
                  key: "gse",
                },
                {
                  label: "Dmg:",
                  id: "vessel-gsd",
                  inputType: "number",
                  key: "gsd",
                },
                {
                  label: "RoF:",
                  id: "vessel-gsr",
                  inputType: "number",
                  key: "gsr",
                },
              ],
            },
            {
              id: "primary-guns-3g",
              expanded: true,
              label: "Primary Guns (2 Primary Guns)",
              instructions: "",
              threeGunModeOnly: true,
              fields: [
                {
                  label: "No:",
                  id: "vessel-3g_gpno",
                  inputType: "number",
                  key: "3g_gpno",
                },
                {
                  label: "Size:",
                  id: "vessel-3g_gpsz",
                  inputType: "number",
                  key: "3g_gpsz",
                },
                {
                  label: "Class (large/small):",
                  id: "vessel-3g_gpc",
                  inputType: "text",
                  key: "3g_gpc",
                },
                {
                  label: "S:",
                  id: "vessel-3g_gps",
                  inputType: "number",
                  key: "3g_gps",
                },
                {
                  label: "M:",
                  id: "vessel-3g_gpm",
                  inputType: "number",
                  key: "3g_gpm",
                },
                {
                  label: "L:",
                  id: "vessel-3g_gpl",
                  inputType: "number",
                  key: "3g_gpl",
                },
                {
                  label: "E:",
                  id: "vessel-3g_gpe",
                  inputType: "number",
                  key: "3g_gpe",
                },
                {
                  label: "Dmg:",
                  id: "vessel-3g_gpd",
                  inputType: "number",
                  key: "3g_gpd",
                },
                {
                  label: "RoF:",
                  id: "vessel-3g_gpr",
                  inputType: "number",
                  key: "3g_gpr",
                },
              ],
            },
            {
              id: "secondary-guns-3g",
              expanded: true,
              label: "Semi Guns (2 Primary Guns)",
              instructions: "The underlying data feilds for this group correspond to the 'secondary' data specified from the data import.",
              threeGunModeOnly: true,
              fields: [
                {
                  label: "No:",
                  id: "vessel-3g_gsno",
                  inputType: "number",
                  key: "3g_gsno",
                },
                {
                  label: "Size:",
                  id: "vessel-3g_gssz",
                  inputType: "number",
                  key: "3g_gssz",
                },
                {
                  label: "Class (large/small):",
                  id: "vessel-3g_gsc",
                  inputType: "text",
                  key: "3g_gsc",
                },
                {
                  label: "S:",
                  id: "vessel-3g_gss",
                  inputType: "number",
                  key: "3g_gss",
                },
                {
                  label: "M:",
                  id: "vessel-3g_gsm",
                  inputType: "number",
                  key: "3g_gsm",
                },
                {
                  label: "L:",
                  id: "vessel-3g_gsl",
                  inputType: "number",
                  key: "3g_gsl",
                },
                {
                  label: "E:",
                  id: "vessel-3g_gse",
                  inputType: "number",
                  key: "3g_gse",
                },
                {
                  label: "Dmg:",
                  id: "vessel-3g_gsd",
                  inputType: "number",
                  key: "3g_gsd",
                },
                {
                  label: "RoF:",
                  id: "vessel-3g_gsr",
                  inputType: "number",
                  key: "3g_gsr",
                },
              ],
            },
            {
              id: "tertiary-guns",
              expanded: true,
              label: "Secondary Guns (2 Primary Guns)",
              instructions: "The underlying data feilds for this group correspond to the 'tertiary' data specified from the data import.",
              threeGunModeOnly: true,
              fields: [
                {
                  label: "No:",
                  id: "vessel-3g_gtno",
                  inputType: "number",
                  key: "3g_gtno",
                },
                {
                  label: "Size:",
                  id: "vessel-3g_gtsz",
                  inputType: "number",
                  key: "3g_gtsz",
                },
                {
                  label: "Class (large/small):",
                  id: "vessel-3g_gtc",
                  inputType: "text",
                  key: "3g_gtc",
                },
                {
                  label: "S:",
                  id: "vessel-3g_gts",
                  inputType: "number",
                  key: "3g_gts",
                },
                {
                  label: "M:",
                  id: "vessel-3g_gtm",
                  inputType: "number",
                  key: "3g_gtm",
                },
                {
                  label: "L:",
                  id: "vessel-3g_gtl",
                  inputType: "number",
                  key: "3g_gtl",
                },
                {
                  label: "E:",
                  id: "vessel-3g_gte",
                  inputType: "number",
                  key: "3g_gte",
                },
                {
                  label: "Dmg:",
                  id: "vessel-3g_gtd",
                  inputType: "number",
                  key: "3g_gtd",
                },
                {
                  label: "RoF:",
                  id: "vessel-3g_gtr",
                  inputType: "number",
                  key: "3g_gtr",
                },
              ],
            },
            {
              id: "qf-guns",
              expanded: true,
              label: "Quick Fire Guns",
              instructions: "",
              fields: [
                {
                  label: "Qfire:",
                  id: "vessel-qfg",
                  inputType: "number",
                  key: "qfg",
                },
                {
                  label: "Size:",
                  id: "vessel-qfsz",
                  inputType: "number",
                  key: "qfsz",
                },
                {
                  label: "Class (large/small):",
                  id: "vessel-qfc",
                  inputType: "text",
                  key: "qfc",
                },
                {
                  label: "S:",
                  id: "vessel-qfs",
                  inputType: "number",
                  key: "qfs",
                },
                {
                  label: "M:",
                  id: "vessel-qfm",
                  inputType: "number",
                  key: "qfm",
                },
                {
                  label: "Dmg:",
                  id: "vessel-qfd",
                  inputType: "number",
                  key: "qfd",
                },
                {
                  label: "Gatling:",
                  id: "vessel-gat",
                  inputType: "number",
                  key: "gat",
                },
              ],
            },
            {
              id: "torpedoes",
              expanded: true,
              label: "Torpedoes",
              instructions: "",
              fields: [
                {
                  label: "No.:",
                  id: "vessel-tno",
                  inputType: "number",
                  key: "tno",
                },
                {
                  label: "Size:",
                  id: "vessel-tsz",
                  inputType: "number",
                  key: "tsz",
                },
                {
                  label: "Class (large/small):",
                  id: "vessel-tc",
                  inputType: "text",
                  key: "tc",
                },
                {
                  label: "Fast:",
                  id: "vessel-tf",
                  inputType: "number",
                  key: "tf",
                },
                {
                  label: "Slow:",
                  id: "vessel-ts",
                  inputType: "number",
                  key: "ts",
                },
              ],
            },
            {
              id: "img-refs",
              expanded: true,
              label: "Image References",
              instructions: "The nation and vessel images are stored in Google Cloud Platform and are referenced based on specific naming conventions. Update these values only for special cases.",
              fields: [
                {
                  label: "Vessel Image Reference",
                  id: "vessel-img-ref",
                  inputType: "text",
                  key: "_vesselImgRef",
                  instructions: "Enter file name only",
                  helpText: "Note: Use this field to specify a different file name if the name does not follow the standard naming convention of '[nation]-[class].jpg'"
                }
              ]
            }
          ],
        },
      };
    },
    computed: {},
    methods: {
      initializeModal() {
        let self = this;
        this.vessel = {};

        if (this.vesselKey) {
          let vMetadata = Vue.$vesselsService.getVesselMetadata(this.vesselKey);

          if (vMetadata) {
            // Set false to true so we pull fresh vessel data from the db
            Vue.$vesselsService.getVesselData(vMetadata, false).then(
              (vData) => {
                // The sat property is a new property added with 3-gun support. 
                // If this property exists, then we'll assume the other 3-gun-related properties exist.
                let has3GunData = vData && Object.prototype.hasOwnProperty.call(vData, 'sat');
                self.vesselFormConfig = self.getFormConfig(has3GunData);
                self.vessel = vData;
              },
              (err) => {
                console.error(err);
                Vue.toasted.show(
                  "There was a problem loading data for vessel " + self.vesselKey,
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 3000,
                  }
                );
              }
            );
          }
        }
      },
      getFormConfig(inThreeGunMode) {
        let groupsForDisplay = [];

        this.vesselFormConfigTemplate.groups.forEach(group => {
          if((!inThreeGunMode && !group.threeGunModeOnly) || !!inThreeGunMode) {
            let groupForDisplay = {
              id: group.id,
              expanded: group.expanded,
              label: group.label,
              instructions: group.instructions,
              fields: []
            };

            group.fields.forEach(field => {
              if((!inThreeGunMode && !field.threeGunModeOnly) || !!inThreeGunMode) {
                groupForDisplay.fields.push(field);
              }
            });
            groupsForDisplay.push(groupForDisplay);
          }
        });

        return {groups: groupsForDisplay}
      },
      saveVesselData() {
        Vue.$vesselsService.updateVessel(this.vessel).then(
          (resp) => {
            if (resp.updatedVessel) {
              //Update selected vessel
              this.$store.dispatch("vessels/setSelected", resp.updatedVessel);
            }

            this.$refs.vesselDetailsModal.hide();
          },
          (err) => {
            console.error(err);
          }
        );
      },
      expandAll() {
        this.vesselFormConfig.groups.forEach(group => {
          group.expanded = true;
        });
      },
      collapseAll() {
        this.vesselFormConfig.groups.forEach(group => {
          group.expanded = false;
        });
      },
      initializeJsonModal() {
        this.jsonOutput = JSON.stringify(this.vessel, null, 4);
      },
      copyToClipboard() {
        const el = document.createElement('textarea');
        el.addEventListener('focusin', e => e.stopPropagation());
        el.value = this.jsonOutput;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.clipboardSuccessHandler();
      },
      clipboardSuccessHandler() {
        this.$toasted.show("Clipboard updated!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 3000
        });
      },
    },
    watch: {},
    created() {},
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";

  #jsonOutputModal {
    .card .card-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .json-output {
      max-height: 400px;
      overflow: auto;
      padding: 1rem;
      white-space: pre;
    }
  }

  #vesselDetailsModal {
    .modal-body {
      min-height: 0;
      max-height: 80vh;
      overflow-y: auto;

      .card-header {
        border: none;
        background-color: transparent;
        padding: 0;
      }
    }
  }
</style>
