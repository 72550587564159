import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

class UserService {
  /*constructor() {
    console.log("UserService");
  }*/

  submitRegistration(regCode, email/*, password*/) {
    //let self = this;
    //let _Vue = Vue;
    let submitRefPromise = new Promise(function(resolve, reject) {
      let db = firebase.firestore();
      db.collection("registration").doc(regCode).then(
        regDocRef => {
          let regDoc = regDocRef.data();
          // Check email address
          if (regDoc.email != email) {
            reject({ msg: "Email does not match" });
            return;
          } else {
            //
          }
        },
        regDocErr => {
          reject(regDocErr);
        }
      );
    });

    return submitRefPromise;
  }

  getCollectionUserData() {
    let respPromise = new Promise(function(resolve, reject) {
      let db = firebase.firestore();
      db.collection("user-data").get().then(
        snapshot => {
          let userData = {};

          snapshot.docs.forEach((docRef) => {
            if (docRef.exists) {
              userData[docRef.id] = docRef.data();
            }
          });

          resolve(userData);
        },
        err => {
          reject(err);
        }
      );
    });

    return respPromise;
  }

  getCollectionUserVesselSetsData() {
    let respPromise = new Promise(function(resolve, reject) {
      let db = firebase.firestore();
      db.collection("vessel-sets").get().then(
        snapshot => {
          let userData = {};

          snapshot.docs.forEach((docRef) => {
            if (docRef.exists) {
              userData[docRef.id] = docRef.data();
            }
          });

          resolve(userData);
        },
        err => {
          reject(err);
        }
      );
    });

    return respPromise;
  }
}

export default new UserService();
