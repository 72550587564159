<template>
  <div id="app">
    <b-navbar
      toggleable="lg"
      class="app-header pt-0 pb-0"
      variant="light"
      type="light"
    >
      <b-navbar-brand class="mb-0">
        <h1>Black Smoke Blue Water</h1>
        <h2>by Deadly Warfare Games</h2>
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto" v-if="supportedBrowser">
        <b-nav-item id="sign-in" to="login" v-if="!(currentUser && validUi)">
          <i class="fas fa-user mr-1"></i>Sign In
        </b-nav-item>

        <b-nav-text v-if="currentUser && validUi">
          <!--<em>{{ currentUser.email }}</em>-->
          <b-dropdown id="userMenu" :text="currentUser.email" class="m-md-2" right variant="light" size="sm">
            <b-dropdown-item href="./documents/BSBW_Companion_Site_User_Manual.pdf" target="_blank" title="Companion Site User Manual"><i class="far fa-question-circle mr-2"></i>User Manual</b-dropdown-item>
            <!--<b-dropdown-item href="https://blacksmokebluewater.miiduu.com/index.php?route=information/contact" target="_blank" title="Contact Us"><i class="far fa-envelope mr-2"></i>Contact Us</b-dropdown-item>-->
            <b-dropdown-item-button v-on:click="onLogOut" title="Sign Out"><i class="fas fa-power-off mr-2"></i>Sign Out</b-dropdown-item-button>
          </b-dropdown>
        </b-nav-text>
      </b-navbar-nav>
    </b-navbar>

    <b-navbar
      toggleable="sm"
      class="app-sub-header"
      variant="dark"
      type="dark"
      v-if="currentUser && validUi"
    >
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-collapse class="menu ml-2 mr-2" is-nav id="nav_collapse">
        <b-navbar-nav>
          <b-nav-item to="/" exact>Home</b-nav-item>
          <b-nav-item to="/vessels" v-if="validUi">Vessels</b-nav-item>
          <b-nav-item to="/vessel-sets" v-if="validUi">Vessel Sets</b-nav-item>
          <b-nav-item to="/vessels-roll-out" v-if="validUi && isSa"
            >Roll Out Table</b-nav-item
          >
          <b-nav-item to="/tables-blank-cards" v-if="validUi">Tables/Blank Cards</b-nav-item>
          <b-nav-item to="/bsbw-administration" v-if="validUi && isSa"
            >Administration</b-nav-item
          >
          <b-nav-item to="/settings" v-if="!inDm">Settings</b-nav-item>
        </b-navbar-nav>
      </b-collapse>

      <!--<div class="nav-spacer"></div>

      <b-collapse class="menu ml-2 mr-2" is-nav id="nav_collapse_user">
        <b-dropdown size="md"  variant="link" toggle-class="text-decoration-none text-color-white" right>
          <template #button-content>
            Datasets<i class="fas fa-database ml-2"></i>
          </template>
          <b-dropdown-item-button 
            v-for="dataSource of availableDataSources"
            :key="dataSource.localCacheKey"
            :active="dataSource.localCacheKey === selectedDataSource.localCacheKey"
            v-on:click="changeDataSource(dataSource)" :title="dataSource.label">{{dataSource.label}}</b-dropdown-item-button>
        </b-dropdown>
      </b-collapse>

      <b-navbar-toggle target="nav_collapse_user"></b-navbar-toggle>-->
    </b-navbar>

    <div class="app-content">
      <router-view />
    </div>

    <PrintWindow
      v-if="!inDm && printIsOpen"
      :config="$store.state.print.config"
      @close="closePrint"
    ></PrintWindow>
  </div>
</template>

<script>
  import Vue from "vue";
  import firebase from "firebase/app";
  import "firebase/auth";
  import PrintWindow from "./components/PrintWindow";

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   * Borrowed from: https://codepen.io/gapcode/pen/vEJNZN?editors=0010
   */
  function detectIE() {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    var edge = ua.indexOf("Edge/");
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    // other browser
    return false;
  }

  export default {
    name: "UserDropDown",
    components: { PrintWindow },
    data: function() {
      return {
        availableDataSources: [],
        selectedDataSource: null
      };
    },
    computed: {
      currentUser: {
        get() {
          return this.$store.state.user.current;
        },
      },
      isSa: {
        get() {
          return this.$store.state.user.isSa;
        },
      },
      inDm: {
        get() {
          return this.$store.state.user.inDm;
        },
      },
      supportedBrowser: {
        get() {
          return this.$store.state.user.supportedBrowser;
        },
      },
      validUi: {
        get() {
          let ui = this.$store.state.user.userInfo;
          return ui && ui.rKey && ui.rKey.length;
        },
      },
      printIsOpen: function () {
        return this.$store.state.print.isOpen;
      }
    },
    methods: {
      closePrint() {
        this.$store.dispatch("print/closePrintPreview");
      },
      onLogOut() {
        this.$store.dispatch("user/logOut");
      },
      requestRoute(routeName) {
        if (routeName && routeName !== this.$router.history.current.name) {
          this.$router.replace(routeName);
        }
      },
      changeDataSource(vMetadata) {
        Vue.$vesselsService.initializeVesselsMetadata(vMetadata);
      }
    },
    created() {
      let version = detectIE();
      let self = this;

      if (version !== false && version < 12) {
        this.$store.dispatch("user/setSupportedBrowser", false);
        this.$router.replace("/unsupported");
      } else {
        this.$subscribeTo(Vue.$vesselsService.vesselsMetadataSourcesSubject, function (vMetadataSources) {
          self.availableDataSources = vMetadataSources;
          self.selectedDataSource = Vue.$vesselsService.selectedVesselsMetadataSource;
        });
        this.$store.dispatch("user/setSupportedBrowser", true);
        this.$store.dispatch("user/initialize", {
          requestRoute: this.requestRoute,
          pendingRoute: this.$route ? this.$route.fullPath : "/",
        });
      }
    }
  };
</script>

<style lang="scss">
  @import "assets/bootstrap.overrides.scss";
  @import "assets/bsbw.globals.scss";
  @import "~bootstrap/scss/bootstrap.scss";
  @import "~bootstrap-vue/dist/bootstrap-vue.css";
  @import "~vue-resize/dist/vue-resize.css";

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app,
  .app-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    font-family: "Roboto", arial, helvetica, sans-serif;
  }

  .app-header,
  .app-sub-header {
    flex: 0 0 auto;
  }

  .app-header {
    h1 {
      font-size: 1.3rem;
      margin: 0;
    }

    h2 {
      font-size: 1rem;
      margin: 0;
    }

    #sign-in .nav-link.active {
      visibility: hidden;
    }

    .navbar-text {
      padding: 0;
    }
  }

  #app .nav-link.router-link-active {
    color: #f8f9fa;
  }

  .app-sub-header {
    padding: 0.2rem;

    .nav-spacer {
      flex: 1 1 auto;
    }

    .menu {
      flex: 0 0 auto;
    }

    .dropdown >button {
      color: #f8f9fa;
    }
  }

  .app-content {
    flex: 1 1 100%;
    overflow-y: auto;
  }

  .print-window {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }

  @media print {
    .app-header,
    .app-sub-header,
    .app-content {
      visibility: hidden;
    }

    .print-window {
      bottom: inherit;
      overflow-y: hidden;
    }
  }
</style>
