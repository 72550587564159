<template>
  <div>
    <img :src="vesselImgPath" alt="Vessel Image" v-bind:title="vesselImgLabel" @error="fixBrokenLink" v-if="vesselImgPath !== null && (!vesselImgData || showAll === true)">
    <img :src="vesselImgData" alt="Vessel Image (base64)" v-bind:title="vesselImgLabel" v-if="vesselImgData !== null || showAll === true">
  </div>
</template>

<script>
  import Vue from "vue";
  import firebase from "firebase/app";
  import "firebase/storage";
  import CommonConstants from '../common/constants';

  let assets = require.context("@/assets", true, /\.jpg$/);

  export default {
    name: "VesselImg",
    props: ["vessel", "showAll"],
    emits: ['loaded'],
    data: function() {
      return {
        vesselImgPath: null,
        vesselImgLabel: null,
        vesselImgData: null
      };
    },
    methods: {
      fixBrokenLink() {
        this.setDefaultPlaceholder();
      },
      setDefaultPlaceholder() {
        this.vesselImgPath = assets("./placeholder.jpg");
        this.vesselImgData = null;
      },
      saveToLocalCache(cacheItemKey, cacheItemData) {
        Vue.$utilService.setLocalCacheItem(CommonConstants.LF_VESSELS_IMG_CACHE, cacheItemKey, cacheItemData);
      },
      downloadImageFromGCPStorageBucket(imgRef, imgStorageBucketRef, imgStorageBucketMetadataLastUpdated) {
        let self = this;

        Vue.$utilService.downloadImageFromGCPStorageBucket(imgRef, imgStorageBucketRef, imgStorageBucketMetadataLastUpdated, CommonConstants.LF_VESSELS_IMG_CACHE)
          .then(
            (resp) => {
              self.vesselImgData = resp.imgData;
              self.$emit('loaded', { imgRef: imgRef, imgData: resp.imgData, isPlaceholder: false });
            },
            () => {
              self.setDefaultPlaceholder();
              self.$emit('loaded', { imgRef: imgRef, imgData: null, isPlaceholder: true });
            }
          );
      },
      setVesselImgLabel() {
        this.vesselImgLabel = this.vessel ? this.vessel.nat + " - " + this.vessel.cls : "Vessel Image";
      },
      setVesselImgPath() {
        let self = this;

        if(this.vessel) {
          try {
            let assetsConfig = this.$store.state.config.assets;
            let vKey = this.vessel.key.indexOf("]_") >= 0 ? this.vessel.key.split(']_')[1] : this.vessel.key;
            let imgKey = typeof this.vessel._vesselImgRef === 'string' && this.vessel._vesselImgRef.length > 0 ? this.vessel._vesselImgRef : vKey;
            let imgBasePath = assetsConfig !== null ? assetsConfig.vessels : 'vessels/';
            let imgExt = assetsConfig !== null ? assetsConfig.vesselsImgExt : '.jpg';

            // Check if an asset exists for this vessel in the GCP storage bucket
            let imgStorageBucketRef = firebase.storage().ref().child(imgBasePath + imgKey + imgExt);
            imgStorageBucketRef.getMetadata()
              .then(
                (metadataResp) => {
                  // File exists in GCP storage bucket.
                  this.vesselImgPath = assetsConfig !== null ? (assetsConfig.base + assetsConfig.vessels + imgKey + assetsConfig.vesselsImgExt) : ("https://storage.cloud.google.com/" + metadataResp.bucket + "/" + metadataResp.fullPath);
                  this.vesselImgData = null;

                  // Check for corresponding data in the browser's local cache
                  Vue.$utilService.getLocalCacheItem(CommonConstants.LF_VESSELS_IMG_CACHE, imgKey)
                    .then((cachedData) => {
                      if(cachedData && cachedData.cacheItemData && cachedData.cacheItemData.gcpStorageLastUpdated === metadataResp.updated && cachedData.cacheItemData.data) {
                        // Use the img data stored in the browser's local cache
                        self.vesselImgData = cachedData.cacheItemData.data;
                        self.$emit('loaded', { imgRef: imgKey, imgData: cachedData.cacheItemData.data, isPlaceholder: false });
                      } else {
                        // We need to update the browser's local cache. Download the image from the GCP storage bucket.
                        self.downloadImageFromGCPStorageBucket(imgKey, imgStorageBucketRef, metadataResp.updated);
                      }
                    }, (e) => {
                      if(e.status === 404) {
                        // A corresponding record was not found in the browser's local storage. Download the image from the GCP storage bucket.
                        self.downloadImageFromGCPStorageBucket(imgKey, imgStorageBucketRef, metadataResp.updated);
                      }
                    });
                },
                (/* metadataErr */) => {
                  // There was a problem accessing the requested file metadata in the GCP storage bucket
                  self.setDefaultPlaceholder();
                  self.$emit('loaded', { imgRef: imgKey, imgData: null, isPlaceholder: true });
                });

          } catch (e) {
            this.setDefaultPlaceholder();
            self.$emit('loaded', { imgRef: null, imgData: null, isPlaceholder: true });
          }
        } else {
          this.setDefaultPlaceholder();
          self.$emit('loaded', { imgRef: null, imgData: null, isPlaceholder: true });
        }
      }
    },
    watch: {
      vessel: function(/* vessel */) {
        this.setVesselImgPath();
        this.setVesselImgLabel();
      }
    },
    created() {
      this.setVesselImgPath();
      this.setVesselImgLabel();
    }
  };
</script>
