<template>
  <div>
    <b-modal
      id="addUserModal"
      ref="addUserModal"
      title="Create a New User"
      header-bg-variant="dark"
      header-text-variant="light"
      @ok="addNewUser"
      ok-title="Create"
      cancel-variant="outline-secondary"
      @shown="initializeModal"
    >
      <b-alert
        :show="invalidMsgDismissCountDown"
        dismissible
        variant="danger"
        @dismissed="invalidMsgDismissCountDown = 0"
        @dismiss-count-down="invalidMsgCountDownChanged"
        >The email address, first name, and last name information for the new
        account must be entered in order to continue.</b-alert
      >

      <form>
        <b-form-group
          label="Account ID"
          label-class="font-weight-bold"
          label-for="acctIdInput"
          :invalid-feedback="invalidFeedback"
          :valid-feedback="validFeedback"
          :state="userAccountIdIsValid"
        >
          <b-form-input
            id="acctIdInput"
            :state="userAccountIdIsValid"
            v-model.trim="userAccountId"
            ref="userAccountId"
            placeholder="Enter a valid and unique Account ID (email)"
            aria-placeholder="Enter a unique Account ID"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="First Name"
          label-class="font-weight-bold"
          label-for="fNameInput"
          :invalid-feedback="invalidFirstNameFeedback"
          :state="fNameIsValid"
        >
          <b-form-input
            id="fNameInput"
            :state="fNameIsValid"
            v-model.trim="firstName"
            placeholder="Enter user's first name"
            aria-placeholder="Enter user's first name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Last Name"
          label-class="font-weight-bold"
          label-for="lNameInput"
          :invalid-feedback="invalidLastNameFeedback"
          :state="lNameIsValid"
        >
          <b-form-input
            id="lNameInput"
            :state="lNameIsValid"
            v-model.trim="lastName"
            placeholder="Enter user's last name"
            aria-placeholder="Enter user's last name"
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import firebase from "firebase/app";
  import "firebase/auth";
  import "firebase/firestore";
  import Vue from "vue";

  export default {
    name: "AddUserModal",
    data: function () {
      return {
        firstName: "",
        invalidMsgDismissSecs: 4,
        invalidMsgDismissCountDown: 0,
        lastName: "",
        userAccountId: "",
        accountSearch: {},
      };
    },
    computed: {
      userAccountIdIsValid() {
        return (
          this.userAccountId.length > 0 &&
          !this.accountSearch.matchFound &&
          !this.accountSearch.error
        );
      },
      fNameIsValid() {
        return this.firstName.length > 0;
      },
      lNameIsValid() {
        return this.lastName.length > 0;
      },
      invalidFeedback() {
        if (!this.userAccountId.length) {
          return "Enter a valid email address";
        } else if (this.userAccountId.length && !!this.accountSearch.matchFound) {
          return "An existing account was found with that ID. Please try a different ID.";
        } else if (this.userAccountId.length && this.accountSearch.error) {
          return this.accountSearch.error.message;
        }
        return "";
      },
      invalidFirstNameFeedback() {
        return !this.firstName.length ? "Enter the user's first name" : "";
      },
      invalidLastNameFeedback() {
        return !this.lastName.length ? "Enter the user's last name" : "";
      },
      validFeedback() {
        return "";
      },
    },
    methods: {
      addNewUser(event) {
        event.preventDefault();

        if (
          !(this.userAccountIdIsValid && this.fNameIsValid && this.lNameIsValid)
        ) {
          this.invalidMsgDismissCountDown = this.invalidMsgDismissSecs;
          return;
        }

        let that = this;
        let newAcctId = that.userAccountId;

        firebase
          .auth()
          .fetchSignInMethodsForEmail(newAcctId)
          .then((resp) => {
            firebase
              .firestore()
              .collection("users")
              .doc(newAcctId)
              .get()
              .then((userInfoRef) => {
                if (userInfoRef.exists) {
                  that.accountSearch = { matchFound: true };
                } else {
                  //A matching account was not found. We can create a new account with this ID.
                  that.accountSearch = {};
                  that.createUser(newAcctId);
                }
              })
              .catch(function (error) {
                that.accountSearch = { error };
              });
          })
          .catch(function (error) {
            console.error("Error getting providers for this account ID :", error);
            that.accountSearch = { error };
          });
      },
      createUser(newAcctId) {
        let that = this;

        let getRegKey = () => {
          var dt = new Date().getTime();
          var uuid = "xxxx-yx-xxxx".replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
          });

          return uuid;
        };

        /*
            New User Creation and Registration Flow
            When creating a user, create a new document in the new 'registration' table with the email as the key.

            Store the User's email address, first and last name, and registration key

            When the user goes through the registration process, use the email address they enter to check for the matching record. Then compare the registration key with what's in the record.

            Call the firebase function to create an account using email and password.

            Once the account is created and the user is authenticated, use the user's account uid as the key to create documents in the 'permissions', 'user-data', and 'users' collections.

            */

        //Create record in users collection
        firebase
          .firestore()
          .collection("users")
          .doc(newAcctId)
          .set({
            fName: that.firstName,
            lName: that.lastName,
            rKey: getRegKey(),
            rc: false,
          })
          .then(() => {
            Vue.toasted.show(
              "An entry for " + newAcctId + " was created in the users table.",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 3000,
              }
            );

            //Create record in user-data collection
            firebase
              .firestore()
              .collection("user-data")
              .doc(newAcctId)
              .set({
                vesselSets: {},
              })
              .then(() => {
                Vue.toasted.show(
                  "An entry for " +
                    newAcctId +
                    " was created in the user-data table.",
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 3000,
                  }
                );

                //Create record in permissions collection
                firebase
                  .firestore()
                  .collection("permissions")
                  .doc(newAcctId)
                  .set({
                    vessels: [],
                  })
                  .then(() => {
                    Vue.toasted.show(
                      "An entry for " +
                        newAcctId +
                        " was created in the permissions table.",
                      {
                        theme: "toasted-primary",
                        position: "top-right",
                        duration: 3000,
                      }
                    );

                    this.$store.dispatch("user/loadAllUsers");

                    this.$refs.addUserModal.hide();
                  });
              });
          });
      },
      initializeModal() {
        this.userAccountId = "";
        this.firstName = "";
        this.lastName = "";
        this.$refs.userAccountId.focus();
      },
      invalidMsgCountDownChanged(dismissCountDown) {
        this.invalidMsgDismissCountDown = dismissCountDown;
      },
    },
  };
</script>
