<template>
  <div id="printWindowWrapper" class="print-window">
    <b-navbar
      toggleable="sm"
      class="header"
      type="dark"
      variant="dark"
      fixed="top"
      sticky
    >
      <b-navbar-brand class="mb-0">Print Preview</b-navbar-brand>

      <b-nav-form class="ml-auto">
        <b-button
          class="mr-2 d-flex align-items-baseline"
          variant="primary"
          size="sm"
          v-on:click="closePrint"
          title="Close the Print Preview"
        >
          <i class="fas fa-times mr-1"></i>Close
        </b-button>
      </b-nav-form>
    </b-navbar>

    <div class="print-options">
      <b-card
        title="1) Choose the output format"
        tag="article"
        class="settings mb-2"
      >
        <b-form-group
          class="ml-4"
          label-class="font-weight-bold"
          label="Download a PDF file or print using your browser's print dialog"
          stacked
        >
          <b-form-radio-group
            stacked
            v-model="printOutputOptions"
            name="print-output"
          >
            <b-form-radio value="output-pdf"
              >PDF File (Recommended. This will produce a more accurate
              printable page.)</b-form-radio
            >
            <b-form-radio value="output-print">Print</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-card>

      <b-card
        title="2) Choose the output contents"
        tag="article"
        class="settings mb-2"
      >
        <p class="ml-4 message">
          These settings control what will be included in the generated PDF file
          or printout.
        </p>

        <b-form-group
          class="ml-4"
          label-class="font-weight-bold"
          label="Vessel Set Options"
          v-if="vesselSetInfoAvailable"
        >
          <b-form-checkbox id="vsInfoCheckbox" v-model="printVesselSetInfo"
            >Include vessel set's title and description</b-form-checkbox
          >
        </b-form-group>

        <b-form-group
          class="ml-4"
          label-class="font-weight-bold"
          label="Card Layout Options"
          stacked
        >
          <b-form-radio-group
            stacked
            v-model="printLayoutOptions"
            name="print-layout"
          >
            <b-form-radio
              v-if="showVesselCardsOpt"
              value="vc-followed-by-add-ons"
              >Vessel card(s)</b-form-radio
            >
            <b-form-radio
              v-if="showVesselCardsOpt"
              value="vc-add-ons-side-by-side"
              >Vessel card(s) and {{selectedSideBySideAddOn.label}}
              side-by-side</b-form-radio
            >
            <b-form-radio
              v-if="
                isSa &&
                printOutputOptions === 'output-pdf' &&
                (config.mode === 'roll-out-table' ||
                  config.mode === 'vessel-card')
              "
              value="roll-out-table-followed-by-add-ons"
              >Roll out table</b-form-radio
            >
            <b-form-radio value="add-ons-only"
              >Add-ons only (Select add-ons below)</b-form-radio
            >
          </b-form-radio-group>

          <div
            v-if="
              showVesselCardsOpt &&
              (printLayoutOptions == 'vc-followed-by-add-ons' ||
                printLayoutOptions == 'vc-add-ons-side-by-side')
            "
          >
            <p class="p-0 m-0 pt-3 font-weight-bold">Vessel Card Template</p>

            <b-form-checkbox
              id="vcTemplateOverrideCheckbox"
              v-model="overrideVesselCardTemplate"
              >Override vessel card template (The template will be applied to
              all vessels being printed.)</b-form-checkbox
            >

            <b-form-radio-group
              stacked
              v-model="vesselCardTemplate"
              name="vc-template-opt"
              class="ml-4 my-2"
            >
              <b-form-radio v-if="overrideVesselCardTemplate" value="2-gun"
                >1 Primary Gun Template</b-form-radio
              >
              <b-form-radio v-if="overrideVesselCardTemplate" value="3-gun"
                >2 Primary Guns Template</b-form-radio
              >
            </b-form-radio-group>

            <b-form-checkbox v-model="config.vesselCardShowDisplayName"
              >Show vessel card display names instead of vessel
              class</b-form-checkbox
            >
            <b-form-checkbox v-model="config.vesselCardShowNegatives"
              >Show Negatives (Negative values will be shown in the
              card)</b-form-checkbox
            >
            <b-form-checkbox v-model="config.vesselCardShowZeros"
              >Show Zeros (Zero values will be shown in the
              card)</b-form-checkbox
            >
          </div>

          <div
            v-if="
              showRollOutTableOpt &&
              printLayoutOptions == 'roll-out-table-followed-by-add-ons'
            "
          >
            <p class="p-0 m-0 pt-3 font-weight-bold">Roll Out Table Options</p>

            <b-form-radio-group
              stacked
              v-model="rollOutTableOptions"
              name="ro-table-opt"
              class="my-2"
            >
              <b-form-radio value="default-rot-layout"
                >Default Layout</b-form-radio
              >
              <b-form-radio value="choose-rot-gun-configs"
                >Choose Gun Configurations</b-form-radio
              >
            </b-form-radio-group>

            <div
              v-if="rollOutTableOptions === 'choose-rot-gun-configs'"
              class="pl-3"
            >
              <b-form-checkbox v-model="includeOnePrimaryGunConfig"
                >Include 1 Primary Gun Configuration</b-form-checkbox
              >
              <b-form-checkbox v-model="includeTwoPrimaryGunsConfig"
                >Include 2 Primary Guns Configuration</b-form-checkbox
              >
              <b-form-checkbox v-model="includeOtherGunsConfig"
                >Include Other Guns Configuration (QF and
                Torpedos)</b-form-checkbox
              >
            </div>
          </div>
        </b-form-group>

        <b-form-group v-if="printLayoutOptions === 'vc-add-ons-side-by-side'"
          class="add-ons ml-4" 
          label-class="font-weight-bold"
          label="Side-by-side Add-on" 
          stacked>
          <p class="p-0 m-0">
            Select the add-on to display to the right of a vessel card in side-by-side mode.
          </p>
          <b-dropdown
            class="mt-2"
            v-bind:text="selectedSideBySideAddOn.label"
            title="Side-by-side Add-on"
            size="md"
            variant="outline-primary"
          >
            <b-dropdown-item
              v-for="addOnItem of supportedAddOns"
              :key="addOnItem.key"
              v-on:click="selectedSideBySideAddOn = addOnItem"
              v-bind:active="addOnItem.key == selectedSideBySideAddOn.key"
              >{{addOnItem.label}}</b-dropdown-item
            >
          </b-dropdown>
        </b-form-group>

        <b-form-group 
          class="add-ons ml-4" 
          label-class="font-weight-bold"
          label="Add-ons"
          stacked
        >
          <p class="p-0 m-0">
            These items will follow the items selected above.
          </p>
          <div
            class="add-on-option mt-2"
            v-for="addOnItem of supportedAddOns"
            :key="addOnItem.key"
          >
            <b-form-checkbox v-model="addOnItem.includeInPrint">{{
              addOnItem.label
            }}</b-form-checkbox>

            <div
              class="print-count ml-3"
              v-if="
                printOutputOptions === 'output-pdf' && addOnItem.includeInPrint
              "
            >
              <b-badge pill variant="info"
                ># to print: {{ addOnItem.printCount }}</b-badge
              >
            </div>

            <b-input-group
              v-if="
                printOutputOptions === 'output-pdf' && addOnItem.includeInPrint
              "
              prepend="1"
              v-bind:append="
                config && config.vessels
                  ? config.vessels.length.toString()
                  : '4'
              "
              class="m-0 ml-3"
              size="sm"
            >
              <b-form-input
                type="range"
                class="range-selector"
                min="1"
                v-bind:max="
                  config && config.vessels
                    ? config.vessels.length.toString()
                    : '4'
                "
                v-model="addOnItem.printCount"
              ></b-form-input>
            </b-input-group>
          </div>
        </b-form-group>

        <b-form-group
          class="ml-4"
          label-class="font-weight-bold"
          label="'PDF Generated for...' Override (Admin-only override)"
          v-if="isSa && printOutputOptions === 'output-pdf'"
        >
          <b-form-checkbox
            id="vsAdminOverrideCheckbox"
            v-model="overrideDefaultFooter"
            >Override default 'Generated by' footer text</b-form-checkbox
          >

          <b-form-input
            v-model="generatedFor"
            placeholder="Generated by Deadly Warfare Games for first_name last_name (email_address)."
          ></b-form-input>
        </b-form-group>
      </b-card>

      <b-card
        title="3) Download PDF"
        tag="article"
        class="settings mb-2"
        v-if="printOutputOptions === 'output-pdf'"
      >
        <p class="ml-4 message">
          <span>Clicking the&nbsp;</span>
          <em>PDF</em>
          <span
            >&nbsp;button will build a PDF file containing the vessel cards and
            or mini tables displayed in the Preview below. You can download the
            PDF file to your computer and print it at your liesure.</span
          >
        </p>

        <b-button
          class="ml-4 my-3 d-flex align-items-baseline"
          variant="primary"
          size="lg"
          v-on:click="download"
          title="Download PDF Version"
        >
          <i class="fas fa-file-download mr-2"></i>PDF
        </b-button>

        <p class="ml-4 message">
          <em>
            <b>PDF Reader:</b>
          </em>
          You will need a PDF file reader, such as
          <a href="https://get.adobe.com/reader/" target="_blank"
            >Adobe Acrobat</a
          >, to view the generated file. Refer to this article,
          <a href="https://www.wikihow.com/Open-PDF-Files" target="_blank"
            >How to Open PDF Files</a
          >, for options on installing a PDF reader if your computer or mobile
          device doesn't have a PDF reader installed.
        </p>
      </b-card>

      <b-card
        title="3) Print"
        tag="article"
        class="mb-2"
        v-if="printOutputOptions === 'output-print'"
      >
        <p class="ml-4 message">
          <span>Clicking the&nbsp;</span>
          <em>Print</em>
          <span
            >&nbsp;button will open your browser's print dialog window where you
            will select your printer, change print-related settings, and begin
            printing. Please review the print setting recommendations below to
            ensure your cards print as expected.</span
          >
        </p>

        <div>
          <b-button
            class="ml-4 my-3 d-flex align-items-baseline"
            variant="primary"
            size="lg"
            v-on:click="print"
            title="Open Browser's Print Dialog"
          >
            <i class="fas fa-print mr-1"></i>Print
          </b-button>
        </div>

        <b-collapse class="ml-4" id="spsCollapse" visible>
          <p class="message">
            <em>
              <b>Use Chrome to Print:</b>
            </em>
            At this time, we recommend printing from the Chrome browser since
            its print dialog can accomodate the remaining recommendations. Each
            browser's print dialog is different and these instructions have been
            tailored toward what settings can be adjusted in Chrome's print
            dialog.
          </p>

          <p class="message">
            <em>
              <b>Background Graphics:</b>
            </em>
            The cell colors in a vessel card are considered background graphics
            and your browser's print options may need to be set to include these
            in the printout. Make sure the option to include background graphics
            is turned on before printing. Otherwise, the cell background colors
            may not display properly in your printout. In Chrome's print dialog,
            go to 'More settings' &rArr; 'Options' &rArr; and check the
            'Background Graphics' checkbox in the dialog's left-hand panel.
          </p>

          <p class="message">
            <em>
              <b>Page Margins:</b>
            </em>
            Each vessel card and mini tables card have been configured to print
            at a specific width and height on a standard Letter (8.5 x 11) page.
            The page margins set by your browser's print dialog may need to be
            adjusted to maximize the space for these cards. Make sure very small
            margins are selected before printing. <br />For example, top/bottom:
            0.35", left/right: 0.20"
          </p>

          <p class="message">
            <em>
              <b>Scale:</b>
            </em>
            Make sure the print scale is set to 100% so the cards print at their
            intended size.
          </p>
        </b-collapse>

        <b-button
          class="ml-4"
          v-b-toggle.spsCollapse
          variant="outline-secondary"
          size="sm"
        >
          <span class="when-opened">Hide</span>
          <span class="when-closed">Show</span>
          <span>&nbsp;Settings</span>
        </b-button>
      </b-card>
    </div>

    <div class="preview-header">
      <h3>Preview</h3>
      <b-alert variant="info" show
        >The preview below is meant to provide an estimated visual
        representation of how the vessel cards and mini tables (depending on
        your selections above) will be laid out on the printed page. A print
        stylesheet will be applied upon printing this information that may
        slightly affect spacing represented on this screen.</b-alert
      >

      <b-alert
        show
        variant="warning"
        v-if="vesselSetInfoAvailable && printVesselSetInfo"
        >When printing the vessel set's title and description, they will be
        included on the first printed page. The remaining printed content
        (Vessel Cards and/or Mini Tables) will start on the second
        page.</b-alert
      >
    </div>

    <div class="vs-info" v-if="vesselSetInfoAvailable && printVesselSetInfo">
      <h4 v-if="config.vesselSetLabel">{{ config.vesselSetLabel }}</h4>
      <div
        class="vs-desc"
        v-if="config.vesselSetDesc"
        v-html="config.vesselSetDesc"
      ></div>
    </div>

    <div
      class="cards"
      v-bind:class="cardsClasses"
      v-if="printLayoutOptions === 'vc-add-ons-side-by-side'"
    >
      <div class="cards-row" v-for="vessel of config.vessels" :key="vessel.key">
        <VesselCard
          class="cards-row-item default-print"
          generate-card-img="false"
          :vessel-key="vessel.key"
          :in-three-gun-mode="
            !!overrideVesselCardTemplate
              ? vesselCardTemplate === '3-gun'
              : vessel.vesselCardTemplate === '3-gun'
          "
          :show-zeros="config.vesselCardShowZeros"
          :show-negatives="config.vesselCardShowNegatives"
          :show-display-name="config.vesselCardShowDisplayName"
          @loaded="vesselCardImageDataLoaded($event, vessel)"
        ></VesselCard>

        <div class="spacer"></div>

        <GameCardImg v-if="selectedSideBySideAddOn"
          class="cards-row-item default-print add-on"
          :game-card-key="selectedSideBySideAddOn.key"
          :game-card-type="selectedSideBySideAddOn.gcType"
          :game-card-config="selectedSideBySideAddOn.gc"
          game-card-ext-type="JPG"
          :inline-style="selectedSideBySideAddOn.inlineStyle"
          @loaded="gameCardImageDataLoaded($event, selectedSideBySideAddOn)"
        >
        </GameCardImg>
      </div>
    </div>

    <div
      class="cards"
      v-bind:class="cardsClasses"
      v-if="
        printLayoutOptions == 'vc-only' ||
        printLayoutOptions == 'vc-followed-by-add-ons'
      "
    >
      <div
        class="cards-row"
        v-for="vesselRow of vesselsTwoByTwo"
        :key="vesselRow.id"
      >
        <VesselCard
          class="cards-row-item default-print"
          generate-card-img="false"
          :vessel-key="vesselRow.vessels[0].key"
          :in-three-gun-mode="
            !!overrideVesselCardTemplate
              ? vesselCardTemplate === '3-gun'
              : vesselRow.vessels[0].vesselCardTemplate === '3-gun'
          "
          :show-zeros="config.vesselCardShowZeros"
          :show-negatives="config.vesselCardShowNegatives"
          :show-display-name="config.vesselCardShowDisplayName"
          @loaded="vesselCardImageDataLoaded($event, vesselRow.vessels[0])"
        ></VesselCard>

        <div class="spacer"></div>
        
        <VesselCard
          class="cards-row-item default-print"
          v-if="vesselRow.vessels.length == 2"
          generate-card-img="false"
          :vessel-key="vesselRow.vessels[1].key"
          :in-three-gun-mode="
            !!overrideVesselCardTemplate
              ? vesselCardTemplate === '3-gun'
              : vesselRow.vessels[1].vesselCardTemplate === '3-gun'
          "
          :show-zeros="config.vesselCardShowZeros"
          :show-negatives="config.vesselCardShowNegatives"
          :show-display-name="config.vesselCardShowDisplayName"
          @loaded="vesselCardImageDataLoaded($event, vesselRow.vessels[1])"
        ></VesselCard>
      </div>
    </div>

    <div
      class="cards add-ons"
      v-bind:class="cardsClasses"
      v-if="
        printLayoutOptions == 'vc-followed-by-add-ons' ||
        printLayoutOptions == 'roll-out-table-followed-by-add-ons' ||
        printLayoutOptions == 'add-ons-only' ||
        printLayoutOptions == 'vc-add-ons-side-by-side'
      "
    >
      <GameCardImg v-for="aoItem of addOnsToPrint" v-bind:key="aoItem.key"
        class="cards-row-item add-on"
        :game-card-key="aoItem.key"
        :game-card-type="aoItem.gcType"
        :game-card-config="aoItem.gc"
        game-card-ext-type="JPG"
        :inline-style="aoItem.inlineStyle"
        @loaded="gameCardImageDataLoaded($event, aoItem)"
      >
      </GameCardImg>
    </div>

    <div
      class="spinner-wrapper d-flex flex-column align-items-center justify-content-center"
      v-if="showSpinner"
    >
      <div class="spinner"></div>
      <div>Generating PDF...</div>
    </div>
  </div>
</template>

<script>
// html2canvas notes:
// - The version of html2canvas we're using is a fork of the niklasvh/html2canvas repo that has a fix for image
// scaling that is not yet merged. Pulling from this forked repo is not stable but the resulting output works at this time.
// - See https://github.com/niklasvh/html2canvas/pull/
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//import pdfMakeConfig from "../common/PdfMakeConfig.js";

import VesselCard from "../components/VesselCard";
import GameCardImg from "../components/GameCardImg.vue";
//import VesselCardInstructions from "../components/VesselCardInstructions.vue";

//let svgAssets = require.context("@/assets", true, /\.svg$/);
//let jpgAssets = require.context("@/assets", true, /\.jpg$/);

export default {
  name: "PrintWindow",
  props: {
    config: {
      type: Object,
      required: true,
      default() {
        return {
          items: [],
          selectedItem: null,
          mode: null,
          vessels: [],
          vesselSetName: null,
          vesselSetDesc: null,
          vesselCardShowZeros: false,
          vesselCardShowNegatives: false,
        };
      },
    },
  },
  components: { VesselCard, GameCardImg /*, VesselCardInstructions*/ },
  data: function () {
    return {
      //getSvg: svgAssets,
      //getJpg: jpgAssets,
      cardWidth: 274, //275
      generatedFor:
        "Generated by Deadly Warfare Games for first_name last_name (email_address)",
      overrideDefaultFooter: false,
      overrideVesselCardTemplate: false,
      vesselCardTemplate: "2-gun",
      printVesselSetInfo: false,
      printLayoutOptions: "vc-followed-by-add-ons",
      printOutputOptions: "output-pdf",
      showVesselCardsOpt: true,
      showRollOutTableOpt: true,
      rollOutTableOptions: "default-rot-layout",
      includeOnePrimaryGunConfig: true,
      includeTwoPrimaryGunsConfig: true,
      includeOtherGunsConfig: true,
      includeVesselCards: false,
      includeRollOutTable: false,
      showPreview: true,
      showSpinner: false,
      showDownloadPdf: true,
      applyPrintStyles: false,
      addOnItems: {},
      selectedSideBySideAddOn: null,
      supportedAddOns: [],
      vesselLabelOverrides: [
        { target: "Admiral ", repl: "Adm. " },
        { target: "Almirante ", repl: "Alm. " },
        { target: "Ammiraglio", repl: "Ammir." },
        { target: "Conde", repl: "Con." },
        { target: "Cruiser", repl: "Cruis." },
        { target: "Devastation", repl: "Devas." },
        { target: "Edinburgh", repl: "Edin." },
        { target: "Erzherzogin", repl: "Erz." },
        { target: "Fernando", repl: "Fern." },
        { target: "General-Admiral ", repl: "Gen-Adm " },
        { target: "General ", repl: "Gen. " },
        { target: "Generic ", repl: "" },
        { target: " Generic", repl: "" },
        { target: "Harpertszoon", repl: "Harp." },
        { target: "Imperator", repl: "Imp." },
        { target: "Infanta", repl: "Inf." },
        { target: "Kronprinzessin", repl: "Kron." },
        { target: "Large", repl: "L" },
        { target: "Medium", repl: "M" },
        { target: "Merchant", repl: "Merch." },
        { target: "Nederlanden", repl: "Neder." },
        { target: "Presidente", repl: "Pres." },
        { target: "Princesa", repl: "Prin." },
        { target: "Saint", repl: "St" },
        { target: "Small", repl: "S" },
        { target: "Sovereign", repl: "Sov." },
        { target: "Stephanie", repl: "Steph." },
        { target: "Twenty-Seven", repl: "27" },
        { target: "Vladimir", repl: "Vlad." },
        { target: "Wilhelmina", repl: "Wilh." },
      ],
      typeLabelOverrides: [
        { target: "Barbette", repl: "Barb" },
        { target: "BB 2nd", repl: "BB 2" },
        { target: "IC Ram", repl: "IC R" },
        { target: "IC Ram MN", repl: "IC R MN" },
      ],
      nationLabelOverrides: [
        { target: "Argentina", repl: "Arg" },
        { target: "Austria-Hungary", repl: "A-H" },
        { target: "Brazil", repl: "Braz" },
        { target: "Britain", repl: "Brit" },
        { target: "Chile", repl: "Chil" },
        { target: "China", repl: "Chin" },
        { target: "Denmark", repl: "Den" },
        { target: "Ecuador", repl: "Ecua" },
        { target: "France", repl: "Fran" },
        { target: "Generic", repl: "Gen" },
        { target: "Germany", repl: "Ger" },
        { target: "Greece", repl: "Grc" },
        { target: "Holland", repl: "Holl" },
        { target: "Italy", repl: "Ita" },
        { target: "Japan", repl: "Jap" },
        { target: "Norway", repl: "Nor" },
        { target: "Ottomans", repl: "Ott" },
        { target: "Peru", repl: "Peru" },
        { target: "Portugal", repl: "Port" },
        { target: "Romania", repl: "Rom" },
        { target: "Russia", repl: "Rus" },
        { target: "Siam", repl: "Siam" },
        { target: "Spain", repl: "Spa" },
        { target: "Sweden", repl: "Swed" },
        { target: "Uruguay", repl: "Uru" },
        { target: "US", repl: "US" },
      ],
      roTableColumns: [
        {
          id: "1",
          key: "cls",
          label: "Vessel",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.cls,
              this.vesselLabelOverrides
            );
          },
        },
        {
          id: "3",
          key: "nat",
          label: "Nation",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.nat,
              this.nationLabelOverrides
            );
          },
        },
        {
          id: "2",
          key: "type",
          label: "Type",
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.type,
              this.typeLabelOverrides
            );
          },
        },
        {
          id: "5",
          key: "pts",
          label: "Points",
          labelAbbr: "Pts",
          headerImage: this.generateRotatedText("Points"),
          isNumeric: true,
        },
        {
          id: "6",
          key: "hull",
          label: "Hull",
          headerImage: this.generateRotatedText("Hull"),
          isNumeric: true,
        },
        {
          id: "6-2",
          key: "sat",
          label: "Armor",
          headerImage: this.generateRotatedText("Armor"),
        },
        {
          id: "7",
          key: "ap1",
          label: "Primary",
          labelAbbr: "Pri",
          headerImage: this.generateRotatedText("Primary"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "8",
          key: "as1",
          label: "Secondary",
          labelAbbr: "Sec",
          headerImage: this.generateRotatedText("Secondary"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "9",
          key: "qf",
          label: "QF",
          headerImage: this.generateRotatedText("QF"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "10",
          key: "wl",
          label: "Water Line",
          labelAbbr: "WL",
          headerImage: this.generateRotatedText("Water Line"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "11",
          key: "uh",
          label: "Upper Hull",
          labelAbbr: "UH",
          headerImage: this.generateRotatedText("Upper Hull"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "12",
          key: "conn",
          label: "Con",
          headerImage: this.generateRotatedText("Conn"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "13",
          key: "deck",
          label: "Deck",
          headerImage: this.generateRotatedText("Deck"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "15",
          key: "vit",
          label: "Vitals (x1.6)",
          labelAbbr: "Vit 1.6",
          headerImage: this.generateRotatedText("Vitals (x1.6)"),
          formatter: (value, key, item) => {
            //let vit = item.vit * 1.6;
            //return +(Math.round(vit + "e+2") + "e-2");
            return item.vit > 0 ? Math.round(1.6 * item.vit) : "";
          },
          //fillColor: "#ffff00",
          isNumeric: true,
        },
        {
          id: "16",
          key: "spd",
          label: "Move",
          headerImage: this.generateRotatedText("Move"),
          isNumeric: true,
        },
        {
          id: "17",
          key: "acl",
          label: "Accel",
          labelAbbr: "Acl",
          headerImage: this.generateRotatedText("Accel"),
          isNumeric: true,
        },
        {
          id: "18",
          key: "cir",
          label: "Circle",
          labelAbbr: "Cir",
          headerImage: this.generateRotatedText("Circle"),
          isNumeric: true,
        },
        {
          id: "19",
          key: "ram",
          label: "Ram",
          headerImage: this.generateRotatedText("Ram"),
          isNumeric: true,
        },
        {
          id: "20",
          key: "gpno",
          label: "No.",
          headerImage: this.generateRotatedText("No."),
          isNumeric: true,
        },
        {
          id: "21",
          key: "gpsz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "22",
          key: "gpc",
          label: "Class",
          labelAbbr: "Cls",
          headerImage: this.generateRotatedText("Class"),
          formatter: (value, key, item) => {
            return item && item.gpc
              ? this.$_.upperFirst(item.gpc).substr(0, 1)
              : "";
          },
        },
        {
          id: "23",
          key: "gps",
          label: "S",
          headerImage: this.generateRotatedText("S"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "24",
          key: "gpm",
          label: "M",
          headerImage: this.generateRotatedText("M"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "25",
          key: "gpl",
          label: "L",
          headerImage: this.generateRotatedText("L"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "26",
          key: "gpe",
          label: "E",
          headerImage: this.generateRotatedText("E"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "27",
          key: "gpd",
          label: "Dmg",
          headerImage: this.generateRotatedText("Dmg"),
          isNumeric: true,
        },
        {
          id: "29",
          key: "gsno",
          label: "No.",
          headerImage: this.generateRotatedText("No."),
          isNumeric: true,
        },
        {
          id: "30",
          key: "gssz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "31",
          key: "gsc",
          label: "Class",
          labelAbbr: "Cls",
          headerImage: this.generateRotatedText("Class"),
          formatter: (value, key, item) => {
            return item && item.gsc
              ? this.$_.upperFirst(item.gsc).substr(0, 1)
              : "";
          },
        },
        {
          id: "32",
          key: "gss",
          label: "S",
          headerImage: this.generateRotatedText("S"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "33",
          key: "gsm",
          label: "M",
          headerImage: this.generateRotatedText("M"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "34",
          key: "gsl",
          label: "L",
          headerImage: this.generateRotatedText("L"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "35",
          key: "gse",
          label: "E",
          headerImage: this.generateRotatedText("E"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "36",
          key: "gsd",
          label: "Dmg",
          headerImage: this.generateRotatedText("Dmg"),
          isNumeric: true,
        },
      ],
      roTableMainCondensedColumns: [
        {
          id: "1",
          key: "cls",
          label: "Vessel",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.cls,
              this.vesselLabelOverrides
            );
          },
        },
        {
          id: "3",
          key: "nat",
          label: "Nation",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.nat,
              this.nationLabelOverrides
            );
          },
        },
        {
          id: "2",
          key: "type",
          label: "Type",
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.type,
              this.typeLabelOverrides
            );
          },
        },
        {
          id: "5",
          key: "pts",
          label: "Points",
          labelAbbr: "Pts",
          headerImage: this.generateRotatedText("Points"),
          isNumeric: true,
        },
        {
          id: "6",
          key: "hull",
          label: "Hull",
          headerImage: this.generateRotatedText("Hull"),
          isNumeric: true,
        },
        {
          id: "6-2",
          key: "sat",
          label: "Armor",
          headerImage: this.generateRotatedText("Armor"),
        },
        {
          id: "7",
          key: "ap1",
          label: "Primary",
          labelAbbr: "Pri",
          headerImage: this.generateRotatedText("Primary"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "8",
          key: "as1",
          label: "Secondary",
          labelAbbr: "Sec",
          headerImage: this.generateRotatedText("Secondary"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "9",
          key: "qf",
          label: "QF",
          headerImage: this.generateRotatedText("QF"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "10",
          key: "wl",
          label: "Water Line",
          labelAbbr: "WL",
          headerImage: this.generateRotatedText("Water Line"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "11",
          key: "uh",
          label: "Upper Hull",
          labelAbbr: "UH",
          headerImage: this.generateRotatedText("Upper Hull"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "12",
          key: "conn",
          label: "Con",
          headerImage: this.generateRotatedText("Conn"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "13",
          key: "deck",
          label: "Deck",
          headerImage: this.generateRotatedText("Deck"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "15",
          key: "vit",
          label: "Vitals (x1.6)",
          labelAbbr: "Vit 1.6",
          headerImage: this.generateRotatedText("Vitals (x1.6)"),
          formatter: (value, key, item) => {
            //let vit = item.vit * 1.6;
            //return +(Math.round(vit + "e+2") + "e-2");
            return item.vit > 0 ? Math.round(1.6 * item.vit) : "";
          },
          //fillColor: "#ffff00",
          isNumeric: true,
        },
        {
          id: "16",
          key: "spd",
          label: "Move",
          headerImage: this.generateRotatedText("Move"),
          isNumeric: true,
        },
        {
          id: "17",
          key: "acl",
          label: "Accel",
          labelAbbr: "Acl",
          headerImage: this.generateRotatedText("Accel"),
          isNumeric: true,
        },
        {
          id: "18",
          key: "cir",
          label: "Circle",
          labelAbbr: "Cir",
          headerImage: this.generateRotatedText("Circle"),
          isNumeric: true,
        },
        {
          id: "19",
          key: "ram",
          label: "Ram",
          headerImage: this.generateRotatedText("Ram"),
          isNumeric: true,
        },
        {
          id: "45",
          key: "yr",
          label: "Year",
          headerImage: this.generateRotatedText("Year"),
        },
        {
          id: "46",
          key: "crew",
          label: "Crew",
          headerImage: this.generateRotatedText("Crew"),
        },
        {
          id: "4",
          key: "vic",
          label: "Other",
        },
        {
          id: "47",
          key: "det",
          label: "Details",
        },
      ],
      roTableTwoGunColumns: [
        {
          id: "1",
          key: "cls",
          label: "Vessel",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.cls,
              this.vesselLabelOverrides
            );
          },
        },
        {
          id: "3",
          key: "nat",
          label: "Nation",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.nat,
              this.nationLabelOverrides
            );
          },
        },
        {
          id: "20",
          key: "gpno",
          label: "No.",
          headerImage: this.generateRotatedText("No."),
          isNumeric: true,
        },
        {
          id: "21",
          key: "gpsz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "22",
          key: "gpc",
          label: "Class",
          labelAbbr: "Cls",
          headerImage: this.generateRotatedText("Class"),
          formatter: (value, key, item) => {
            return item && item.gpc
              ? this.$_.upperFirst(item.gpc).substr(0, 1)
              : "";
          },
        },
        {
          id: "23",
          key: "gps",
          label: "S",
          headerImage: this.generateRotatedText("S"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "24",
          key: "gpm",
          label: "M",
          headerImage: this.generateRotatedText("M"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "25",
          key: "gpl",
          label: "L",
          headerImage: this.generateRotatedText("L"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "26",
          key: "gpe",
          label: "E",
          headerImage: this.generateRotatedText("E"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "27",
          key: "gpd",
          label: "Dmg",
          headerImage: this.generateRotatedText("Dmg"),
          isNumeric: true,
        },
        {
          id: "29",
          key: "gsno",
          label: "No.",
          headerImage: this.generateRotatedText("No."),
          isNumeric: true,
        },
        {
          id: "30",
          key: "gssz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "31",
          key: "gsc",
          label: "Class",
          labelAbbr: "Cls",
          headerImage: this.generateRotatedText("Class"),
          formatter: (value, key, item) => {
            return item && item.gsc
              ? this.$_.upperFirst(item.gsc).substr(0, 1)
              : "";
          },
        },
        {
          id: "32",
          key: "gss",
          label: "S",
          headerImage: this.generateRotatedText("S"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "33",
          key: "gsm",
          label: "M",
          headerImage: this.generateRotatedText("M"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "34",
          key: "gsl",
          label: "L",
          headerImage: this.generateRotatedText("L"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "35",
          key: "gse",
          label: "E",
          headerImage: this.generateRotatedText("E"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "36",
          key: "gsd",
          label: "Dmg",
          headerImage: this.generateRotatedText("Dmg"),
          isNumeric: true,
        },
      ],
      roTableThreeGunColumns: [
        {
          id: "1",
          key: "cls",
          label: "Vessel",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.cls,
              this.vesselLabelOverrides
            );
          },
        },
        {
          id: "3",
          key: "nat",
          label: "Nation",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.nat,
              this.nationLabelOverrides
            );
          },
        },
        {
          id: "20",
          key: "3g_gpno",
          label: "No.",
          headerImage: this.generateRotatedText("No."),
          isNumeric: true,
        },
        {
          id: "21",
          key: "3g_gpsz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "22",
          key: "3g_gpc",
          label: "Class",
          labelAbbr: "Cls",
          headerImage: this.generateRotatedText("Class"),
          formatter: (value, key, item) => {
            return item &&
              Object.prototype.hasOwnProperty.call(item, "3g_gpc") &&
              typeof item["3g_gpc"] === "string" &&
              item["3g_gpc"].length > 1
              ? this.$_.upperFirst(item["3g_gpc"]).substr(0, 1)
              : "";
          },
        },
        {
          id: "23",
          key: "3g_gps",
          label: "S",
          headerImage: this.generateRotatedText("S"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "24",
          key: "3g_gpm",
          label: "M",
          headerImage: this.generateRotatedText("M"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "25",
          key: "3g_gpl",
          label: "L",
          headerImage: this.generateRotatedText("L"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "26",
          key: "3g_gpe",
          label: "E",
          headerImage: this.generateRotatedText("E"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "27",
          key: "3g_gpd",
          label: "Dmg",
          headerImage: this.generateRotatedText("Dmg"),
          isNumeric: true,
        },
        {
          id: "29",
          key: "3g_gsno",
          label: "No.",
          headerImage: this.generateRotatedText("No."),
          isNumeric: true,
        },
        {
          id: "30",
          key: "3g_gssz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "31",
          key: "3g_gsc",
          label: "Class",
          labelAbbr: "Cls",
          headerImage: this.generateRotatedText("Class"),
          formatter: (value, key, item) => {
            return item &&
              Object.prototype.hasOwnProperty.call(item, "3g_gsc") &&
              typeof item["3g_gsc"] === "string" &&
              item["3g_gsc"].length > 1
              ? this.$_.upperFirst(item["3g_gsc"]).substr(0, 1)
              : "";
          },
        },
        {
          id: "32",
          key: "3g_gss",
          label: "S",
          headerImage: this.generateRotatedText("S"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "33",
          key: "3g_gsm",
          label: "M",
          headerImage: this.generateRotatedText("M"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "34",
          key: "3g_gsl",
          label: "L",
          headerImage: this.generateRotatedText("L"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "35",
          key: "3g_gse",
          label: "E",
          headerImage: this.generateRotatedText("E"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "36",
          key: "3g_gsd",
          label: "Dmg",
          headerImage: this.generateRotatedText("Dmg"),
          isNumeric: true,
        },
        {
          id: "37",
          key: "3g_gtno",
          label: "No.",
          headerImage: this.generateRotatedText("No."),
          isNumeric: true,
        },
        {
          id: "38",
          key: "3g_gtsz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "39",
          key: "3g_gtc",
          label: "Class",
          labelAbbr: "Cls",
          headerImage: this.generateRotatedText("Class"),
          formatter: (value, key, item) => {
            return item &&
              Object.prototype.hasOwnProperty.call(item, "3g_gtc") &&
              typeof item["3g_gtc"] === "string" &&
              item["3g_gtc"].length > 1
              ? this.$_.upperFirst(item["3g_gtc"]).substr(0, 1)
              : "";
          },
        },
        {
          id: "40",
          key: "3g_gts",
          label: "S",
          headerImage: this.generateRotatedText("S"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "41",
          key: "3g_gtm",
          label: "M",
          headerImage: this.generateRotatedText("M"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "42",
          key: "3g_gtl",
          label: "L",
          headerImage: this.generateRotatedText("L"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "43",
          key: "3g_gte",
          label: "E",
          headerImage: this.generateRotatedText("E"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "44",
          key: "3g_gtd",
          label: "Dmg",
          headerImage: this.generateRotatedText("Dmg"),
          isNumeric: true,
        },
      ],
      roDetailsTableColumns: [
        {
          id: "1",
          key: "cls",
          label: "Vessel",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.cls,
              this.vesselLabelOverrides
            );
          },
        },
        {
          id: "3",
          key: "nat",
          label: "Nation",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.nat,
              this.nationLabelOverrides
            );
          },
        },
        {
          id: "2",
          key: "type",
          label: "Type",
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.type,
              this.typeLabelOverrides
            );
          },
        },
        {
          id: "45",
          key: "yr",
          label: "Year",
        },
        {
          id: "46",
          key: "crew",
          label: "Crew",
        },
        {
          id: "4",
          key: "vic",
          label: "Other",
        },
        {
          id: "47",
          key: "det",
          label: "Details",
        },
        {
          id: "38",
          key: "qfg",
          label: "Qfire",
          labelAbbr: "QF",
          headerImage: this.generateRotatedText("No."),
          isNumeric: true,
        },
        {
          id: "21",
          key: "qfsz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "22",
          key: "qfc",
          label: "Class",
          labelAbbr: "Cls",
          headerImage: this.generateRotatedText("Class"),
        },
        {
          id: "23",
          key: "qfs",
          label: "S",
          headerImage: this.generateRotatedText("S"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "24",
          key: "qfm",
          label: "M",
          headerImage: this.generateRotatedText("M"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "27",
          key: "qfd",
          label: "Dmg",
          headerImage: this.generateRotatedText("Dmg"),
          isNumeric: true,
        },
        {
          id: "39",
          key: "gat",
          label: "Gatlng",
          labelAbbr: "Gat",
          headerImage: this.generateRotatedText("Gatling"),
          isNumeric: true,
        },
        {
          id: "40",
          key: "tno",
          label: "No.",
          headerImage: this.generateRotatedText("No."),
        },
        {
          id: "41",
          key: "tsz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "42",
          key: "tc",
          label: "Class",
          headerImage: this.generateRotatedText("Class"),
          //labelAbbr: "Cls",
          formatter: (value, key, item) => {
            return item && item.tc
              ? this.$_.upperFirst(item.tc).substr(0, 1)
              : "";
          },
        },
        {
          id: "43",
          key: "tf",
          label: "Fast",
          headerImage: this.generateRotatedText("Fast"),
          isNumeric: true,
        },
        {
          id: "44",
          key: "ts",
          label: "Slow",
          headerImage: this.generateRotatedText("Slow"),
          isNumeric: true,
        },
      ],
      roTableOtherGunsColumns: [
        {
          id: "1",
          key: "cls",
          label: "Vessel",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.cls,
              this.vesselLabelOverrides
            );
          },
        },
        {
          id: "3",
          key: "nat",
          label: "Nation",
          noWrap: true,
          formatter: (value, key, item) => {
            return this.applyStringOverrides(
              item.nat,
              this.nationLabelOverrides
            );
          },
        },
        {
          id: "38",
          key: "qfg",
          label: "Qfire",
          labelAbbr: "QF",
          headerImage: this.generateRotatedText("No."),
          isNumeric: true,
        },
        {
          id: "21",
          key: "qfsz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "22",
          key: "qfc",
          label: "Class",
          labelAbbr: "Cls",
          headerImage: this.generateRotatedText("Class"),
        },
        {
          id: "23",
          key: "qfs",
          label: "S",
          headerImage: this.generateRotatedText("S"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "24",
          key: "qfm",
          label: "M",
          headerImage: this.generateRotatedText("M"),
          fillColor: "#e1e1e1",
          isNumeric: true,
        },
        {
          id: "27",
          key: "qfd",
          label: "Dmg",
          headerImage: this.generateRotatedText("Dmg"),
          isNumeric: true,
        },
        {
          id: "39",
          key: "gat",
          label: "Gatlng",
          labelAbbr: "Gat",
          headerImage: this.generateRotatedText("Gatling"),
          isNumeric: true,
        },
        {
          id: "40",
          key: "tno",
          label: "No.",
          headerImage: this.generateRotatedText("No."),
        },
        {
          id: "41",
          key: "tsz",
          label: 'Size"',
          headerImage: this.generateRotatedText('Size"'),
          isNumeric: true,
        },
        {
          id: "42",
          key: "tc",
          label: "Class",
          headerImage: this.generateRotatedText("Class"),
          //labelAbbr: "Cls",
          formatter: (value, key, item) => {
            return item && item.tc
              ? this.$_.upperFirst(item.tc).substr(0, 1)
              : "";
          },
        },
        {
          id: "43",
          key: "tf",
          label: "Fast",
          headerImage: this.generateRotatedText("Fast"),
          isNumeric: true,
        },
        {
          id: "44",
          key: "ts",
          label: "Slow",
          headerImage: this.generateRotatedText("Slow"),
          isNumeric: true,
        },
      ],
    };
  },
  computed: {
    cardsClasses: function () {
      return {
        "pb-before": !!this.printVesselSetInfo,
        "simulated-print": this.applyPrintStyles,
      };
    },
    isSa: {
      get() {
        return this.$store.state.user.isSa;
      },
    },
    addOnsToPrint: function () {
      return this.supportedAddOns.filter((addOn) => addOn.includeInPrint);
    },
    vesselSetInfoAvailable: function () {
      return (
        this.config && (this.config.vesselSetLabel || this.config.vesselSetDesc)
      );
    },
    vesselsTwoByTwo: function () {
      let result = [];

      if (Array.isArray(this.config.vessels)) {
        let counter = 1;
        let currentId = 1;
        let group = null;

        this.config.vessels.forEach((vessel) => {
          if (counter === 1) {
            group = { id: currentId, vessels: [vessel] };
            result.push(group);
            counter++;
          } else if (counter === 2) {
            group.vessels.push(vessel);
            currentId++;
            counter = 1;
          }
        });
      }

      return result;
    },
    pdfGeneratedFor: {
      get() {
        let result = "";
        let today = new Date();
        let copyright = " (Copyright " + today.getFullYear() + ")";

        if (
          this.overrideDefaultFooter &&
          this.generatedFor &&
          this.generatedFor.length
        ) {
          result = this.generatedFor + copyright;
        } else {
          try {
            let current = this.$store.state.user.current;
            let ui = this.$store.state.user.userInfo;

            result =
              "Generated by Deadly Warfare Games for " +
              ui.fName +
              " " +
              ui.lName +
              " (" +
              current.email +
              ")" +
              copyright;
          } catch (e) {
            console.log("There was a problem accessing user information: " + e);
            result = "Generated by Deadly Warfare Games";
          }
        }

        return result;
      },
    },
  },
  methods: {
    closePrint() {
      this.$emit("close");
    },
    applyStringOverrides(strVal, overrides) {
      let adjVal = strVal;

      if (
        Array.isArray(overrides) &&
        overrides.length &&
        typeof strVal === "string" &&
        strVal.length
      ) {
        overrides.forEach((override) => {
          if (adjVal.indexOf(override.target) > -1) {
            adjVal = adjVal.replace(override.target, override.repl);
          }
        });
      }

      return adjVal;
    },
    generateRotatedText(text, fillColor = "#000") {
      let ctx;
      let canvas = document.createElement("canvas");
      let width = 40; // 26
      let height = 275; // 160
      // I am using predefined dimensions so either make this part of the arguments or change at will
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext("2d");
      ctx.font = "36pt Roboto"; // 22pt
      ctx.save();
      ctx.translate(width - 4, height);
      ctx.rotate(-0.5 * Math.PI);
      ctx.fillStyle = fillColor;
      ctx.fillText(text, 0, 0);
      ctx.restore();
      return canvas.toDataURL();
    },
    download() {
      let self = this;

      //this.applyPrintStyles = !this.applyPrintStyles;
      //return;

      this.showSpinner = true;
      this.applyPrintStyles = true;

      //Scroll element to the top to prevent partial child rendering
      let pcElem = document.getElementById("printWindowWrapper");
      if (pcElem) {
        pcElem.scrollTop = 0;
        pcElem.scrollLeft = 0;
      }

      // The expected number of vessel cards to render. This will be used to determine when the script can continue with PDF creation.
      let expectedVc = 0;
      let renderedVesselCards = [];
      let renderedMiniTable = null;

      // Render vessel cards option
      let renderVc = false;

      // Render roll-out table option
      let renderRot = false;

      // Render mini tables option
      let renderMt = false;

      // TODO: Place finishPdfCreation and buildContent into a service class
      let finishPdfCreation = function (
        title,
        content,
        fileName,
        orientation = "portrait"
      ) {
        var docDefinition = {
          pageSize: "letter",
          pageOrientation: orientation,
          pageMargins: [20, 25, 20, 20],
          info: {
            title: title,
            author: "Deadly Warfare Games",
            subject: "Black Smoke Blue Water game content",
          },
          header: {
            text:
              self.printVesselSetInfo && self.config.vesselSetLabel
                ? self.config.vesselSetLabel
                : "",
            margin: [20, 10, 20, 0],
          },
          footer: {
            text: self.pdfGeneratedFor,
            alignment: "center",
            fontSize: 7,
            color: "#8C8C8C",
            bold: false,
            margin: [0, 0, 0, 0],
          },
          content: content,
        };
        pdfMake.createPdf(docDefinition).download(fileName, () => {
          self.applyPrintStyles = false;
          self.showSpinner = false;
        }); //.open(); //Open is only supported in Chrome, Firefox, and Opera
      };

      let buildRollOutTableContent = function (section = "main") {
        let cols = [];
        let headerFontSize = 7;
        let headerFillColor = "#bfbfbf";
        let cellFontSize = 7;

        let rotContent = {
          table: {
            body: [],
          },
        };

        switch (section) {
          case "main-condensed":
            cols = self.roTableMainCondensedColumns;
            rotContent.table.headerRows = 2;
            break;
          case "2-gun":
            cols = self.roTableTwoGunColumns;
            rotContent.table.headerRows = 3;
            break;
          case "3-gun":
            cols = self.roTableThreeGunColumns;
            rotContent.table.headerRows = 3;
            break;
          case "other-guns":
            cols = self.roTableOtherGunsColumns;
            rotContent.table.headerRows = 2;
            break;
          case "details":
            cols = self.roDetailsTableColumns;
            rotContent.table.headerRows = 2;
            break;
          default:
            cols = self.roTableColumns;
            rotContent.table.headerRows = 3;
        }

        headerFontSize = 8;
        cellFontSize = 9;

        if (section === "main") {
          // Add grouped headers
          let gh1 = [
            {
              text: "Vessel Stats",
              colSpan: 18,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: "Primary Guns",
              colSpan: 8,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: "Secondary Guns",
              colSpan: 8,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ];

          let gh2 = [
            {
              text: "",
              colSpan: 6,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {
              text: "Armor",
              colSpan: 7,
              fontSize: headerFontSize,
              alignment: "center",
              fillColor: "#e1e1e1",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: "",
              colSpan: 5,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {
              text: "",
              colSpan: 3,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {
              text: "Penetration Ranges",
              colSpan: 4,
              fontSize: headerFontSize,
              alignment: "center",
              fillColor: "#e1e1e1",
            },
            {},
            {},
            {},
            {
              text: "",
              colSpan: 1,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {
              text: "",
              colSpan: 3,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {
              text: "Penetration Ranges",
              colSpan: 4,
              fontSize: headerFontSize,
              alignment: "center",
              fillColor: "#e1e1e1",
            },
            {},
            {},
            {},
            {
              text: "",
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
          ];

          rotContent.table.body.push(gh1);
          rotContent.table.body.push(gh2);
        } else if (section === "main-condensed") {
          // Add grouped headers
          let gh2 = [
            {
              text: "Vessel Stats",
              colSpan: 6,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {
              text: "Armor",
              colSpan: 7,
              fontSize: headerFontSize,
              alignment: "center",
              fillColor: "#e1e1e1",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: "",
              colSpan: 9,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ];

          rotContent.table.body.push(gh2);
        } else if (section === "2-gun") {
          let gh1 = [
            {
              text: "Vessels",
              colSpan: 2,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {
              text: "Primary Guns (1 Primary Gun)",
              colSpan: 8,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: "Secondary Guns (1 Primary Gun)",
              colSpan: 8,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ];
          let gh2 = [
            {
              text: "",
              colSpan: 2,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {
              text: "",
              colSpan: 3,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {
              text: "Penetration Ranges",
              colSpan: 4,
              fontSize: headerFontSize,
              alignment: "center",
              fillColor: "#e1e1e1",
            },
            {},
            {},
            {},
            {
              text: "",
              colSpan: 1,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {
              text: "",
              colSpan: 3,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {
              text: "Penetration Ranges",
              colSpan: 4,
              fontSize: headerFontSize,
              alignment: "center",
              fillColor: "#e1e1e1",
            },
            {},
            {},
            {},
            {
              text: "",
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
          ];

          rotContent.table.body.push(gh1);
          rotContent.table.body.push(gh2);
        } else if (section === "3-gun") {
          let gh1 = [
            {
              text: "Vessels",
              colSpan: 2,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {
              text: "Primary Guns (2 Primary Guns)",
              colSpan: 8,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: "Semi Guns (2 Primary Guns)",
              colSpan: 8,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: "Secondary Guns (2 Primary Guns)",
              colSpan: 8,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ];
          let gh2 = [
            {
              text: "",
              colSpan: 2,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {
              text: "",
              colSpan: 3,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {
              text: "Penetration Ranges",
              colSpan: 4,
              fontSize: headerFontSize,
              alignment: "center",
              fillColor: "#e1e1e1",
            },
            {},
            {},
            {},
            {
              text: "",
              colSpan: 1,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {
              text: "",
              colSpan: 3,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {
              text: "Penetration Ranges",
              colSpan: 4,
              fontSize: headerFontSize,
              alignment: "center",
              fillColor: "#e1e1e1",
            },
            {},
            {},
            {},
            {
              text: "",
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {
              text: "",
              colSpan: 3,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {
              text: "Penetration Ranges",
              colSpan: 4,
              fontSize: headerFontSize,
              alignment: "center",
              fillColor: "#e1e1e1",
            },
            {},
            {},
            {},
            {
              text: "",
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
          ];

          rotContent.table.body.push(gh1);
          rotContent.table.body.push(gh2);
        } else if (section === "details") {
          // Add grouped headers
          let gh1 = [
            {
              text: "Vessels",
              colSpan: 7,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {
              text: "Quick Fire Guns",
              colSpan: 6,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {
              text: "",
              colSpan: 1,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {
              text: "Torpedoes",
              colSpan: 5,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
          ];
          rotContent.table.body.push(gh1);
        } else if (section === "other-guns") {
          // Add grouped headers
          let gh1 = [
            {
              text: "Vessels",
              colSpan: 2,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {
              text: "Quick Fire Guns",
              colSpan: 6,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
            {},
            {
              text: "",
              colSpan: 1,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {
              text: "Torpedoes",
              colSpan: 5,
              fontSize: headerFontSize,
              fillColor: headerFillColor,
              alignment: "left",
            },
            {},
            {},
            {},
            {},
          ];
          rotContent.table.body.push(gh1);
        }

        let header = [];
        let marginTop = 34; //section.indexOf("details") >= 0 ? 0 : 34;
        cols.forEach((col) => {
          let th = null;
          if (col.headerImage) {
            th = {
              image: col.headerImage,
              width: 6,
              alignment: "center",
            };
          } else if (col.labelAbbr) {
            th = {
              text: col.labelAbbr,
              fontSize: headerFontSize,
              bold: false,
              margin: [0, marginTop, 0, 0],
            };
          } else {
            th = {
              text: col.label,
              fontSize: headerFontSize,
              bold: false,
              margin: [0, marginTop, 0, 0],
            };
          }

          if (col.key === "vic" || col.key === "det") {
            th.width = 10;
          }

          if (col.fillColor) {
            th.fillColor = col.fillColor;
          } else {
            th.fillColor = headerFillColor;
          }

          header.push(th);
        });

        rotContent.table.body.push(header);

        self.config.vessels.forEach((vessel) => {
          let row = [];

          cols.forEach((col) => {
            let td = {
              text: self.vesselLabelFn(vessel, col),
              fontSize: cellFontSize,
              margin: [0, 0, 0, 0],
            };

            if (col.isNumeric) {
              td.alignment = "center";
            }

            if (col.fillColor) {
              td.fillColor = col.fillColor;
            }

            if (col.noWrap) {
              td.noWrap = col.noWrap;
            }
            row.push(td);
          });

          rotContent.table.body.push(row);
        });

        return rotContent;
      };

      let buildContent = function () {
        if (renderVc && renderedVesselCards.length != expectedVc) {
          return;
        }

        let title = "Black Smoke Blue Water";
        let fileName = "Black Smoke Blue Water";
        let content = [];
        let forContentVc = [];
        let forContentMt = [];

        // Check if any mini tables need to be rendered
        renderMt = self.addOnsToPrint.length > 0;

        if (renderVc) {
          renderedVesselCards = self.$_.sortBy(
            renderedVesselCards,
            (item) => item.key
          );
        }

        // Modify the title and PDF filename based on selected options
        if (self.printLayoutOptions === "add-ons-only") {
          title += " - Mini Tables";
          fileName += " - Mini Tables";
        } else if (self.printLayoutOptions === "vc-followed-by-add-ons") {
          title += " - Game Cards & Mini Tables";
          fileName += " - Cards and Mini Tables";
          forContentVc = renderedVesselCards;
        } else if (self.printLayoutOptions === "vc-add-ons-side-by-side") {
          title += " - Game Cards & Mini Tables";
          fileName += " - Cards and Mini Tables";
          renderedVesselCards.forEach((item) => {
            forContentVc.push(item);

            if (self.selectedSideBySideAddOn) {
              forContentVc.push({
                img: self.selectedSideBySideAddOn.jpgData,
                width: self.cardWidth,
              });
            }
          });
        } else if (
          self.printLayoutOptions === "roll-out-table-only" ||
          self.printLayoutOptions === "roll-out-table-followed-by-add-ons"
        ) {
          title += " - Roll Out Table";
          fileName += " - Roll Out Table";
        } else {
          //this.printLayoutOptions === "vc-only"
          title += " - Game Cards";
          fileName += " - Cards";
          forContentVc = renderedVesselCards;
        }

        if (self.printVesselSetInfo && self.config.vesselSetDesc) {
          content.push({
            text: self.config.vesselSetDesc,
            pageBreak: "after",
          });
        }

        // Build roll out table content
        if (renderRot) {
          // Title for main table
          content.push({
            text: "Vessel Roll Out Table",
            fontSize: 12,
            bold: true,
            alignment: "center",
            margin: [0, 0, 0, 10],
          });

          if (self.rollOutTableOptions === "choose-rot-gun-configs") {
            // Use layout where the user can choose which gun configuration to include

            // Main table
            content.push(buildRollOutTableContent("main-condensed"));

            if (self.includeOnePrimaryGunConfig) {
              // Title for 2-gun table
              let twoGunTitle = {
                text: "Vessel Roll Out Table - 1 Primary Gun",
                fontSize: 12,
                bold: true,
                alignment: "center",
                margin: [0, 20, 0, 10],
                pageBreak: "before",
              };

              // Push title and table for the additional detail table
              content.push(twoGunTitle);
              content.push(buildRollOutTableContent("2-gun"));
            }

            if (self.includeTwoPrimaryGunsConfig) {
              // Title for 3-gun table
              let threeGunTitle = {
                text: "Vessel Roll Out Table - 2 Primary Guns",
                fontSize: 12,
                bold: true,
                alignment: "center",
                margin: [0, 20, 0, 10],
                pageBreak: "before",
              };

              // Push title and table for the 3-gun table
              content.push(threeGunTitle);
              content.push(buildRollOutTableContent("3-gun"));
            }

            if (self.includeOtherGunsConfig) {
              // Title for other guns table
              let otherGunsTitle = {
                text: "Vessel Roll Out Table - Other Guns",
                fontSize: 12,
                bold: true,
                alignment: "center",
                margin: [0, 20, 0, 10],
                pageBreak: "before",
              };

              // Push title and table for the other guns table
              content.push(otherGunsTitle);
              content.push(buildRollOutTableContent("other-guns"));
            }
          } else {
            // Use default layout

            // Main table
            content.push(buildRollOutTableContent("main"));

            // Title for 3-gun table
            let threeGunTitle = {
              text: "Vessel Roll Out Table - 2 Primary Guns",
              fontSize: 12,
              bold: true,
              alignment: "center",
            };

            if (self.config.mode != "vessel-card") {
              threeGunTitle.pageBreak = "before";
              threeGunTitle.margin = [0, 0, 0, 10];
            } else {
              threeGunTitle.margin = [0, 20, 0, 10];
            }

            // Push title and table for the 3-gun table
            content.push(threeGunTitle);
            content.push(buildRollOutTableContent("3-gun"));

            // Title for additional details table
            let detailsTitle = {
              text: "Vessel Roll Out Table - Additional Details",
              fontSize: 12,
              bold: true,
              alignment: "center",
            };

            if (self.config.mode != "vessel-card") {
              detailsTitle.pageBreak = "before";
              detailsTitle.margin = [0, 0, 0, 10];
            } else {
              detailsTitle.margin = [0, 20, 0, 10];
            }

            // Push title and table for the additional detail table
            content.push(detailsTitle);
            content.push(buildRollOutTableContent("details"));
          }
        }

        // Build vessel card contents
        if (renderVc) {
          let counter = 1;
          let group = null;

          forContentVc.forEach((item) => {
            if (counter === 1) {
              group = {
                columns: [
                  {
                    image: item.img,
                    width: item.width,
                  },
                ],
                columnGap: 20,
                margin: [0, 0, 0, 7],
              };
              content.push(group);
              counter++;
            } else if (counter === 2) {
              group.columns.push({ image: item.img, width: item.width });
              counter = 1;
            }
          });
        }

        // Build mini table contents
        if (renderMt) {
          self.supportedAddOns.forEach((addOn) => {
            if (addOn.includeInPrint) {
              for (let i = 1; i <= addOn.printCount; i++) {
                forContentMt.push({
                  img: addOn.jpgData,
                  width: self.cardWidth,
                });
              }
            }
          });

          let counter = 1;
          let group = null;

          forContentMt.forEach((item) => {
            if (counter === 1) {
              group = {
                columns: [
                  {
                    image: item.img,
                    width: item.width,
                  },
                ],
                columnGap: 20,
                margin: [0, 0, 0, 11],
              };

              content.push(group);
              counter++;
            } else if (counter === 2) {
              group.columns.push({ image: item.img, width: item.width });
              counter = 1;
            }
          });
        }

        finishPdfCreation(
          title,
          content,
          fileName,
          renderRot ? "landscape" : "portrait"
        );
      };

      let vcRenderComplete = function (img, key) {
        renderedVesselCards.push({
          key: key,
          img: img,
          width: self.cardWidth,
        });

        if (renderedVesselCards.length === expectedVc) {
          buildContent();
        }
      };

      if (self.printLayoutOptions === "add-ons-only") {
        // Render only the add-ons (mini tables)
        renderVc = false;
        renderRot = false;
        buildContent();
      } else if (
        self.printLayoutOptions === "roll-out-table-only" ||
        self.printLayoutOptions === "roll-out-table-followed-by-add-ons"
      ) {
        // Render only the roll out table
        renderRot = true;
        buildContent();
      } else {
        // Using setTimeout to give the print style some time to get applied so the PDF card size is properly generated.
        let vesselsCount = self.config.vessels.length;
        let waitTimeToRender = 1000;
        let minDefaultVesselCount = 20;

        if (vesselsCount > minDefaultVesselCount && vesselsCount < 100) {
          waitTimeToRender = 2000;
        } else if (vesselsCount > minDefaultVesselCount && vesselsCount < 350) {
          waitTimeToRender = 3000;
        } else if (vesselsCount > minDefaultVesselCount) {
          waitTimeToRender = 4000;
        }

        // Start building vessel card contents after a short wait time
        setTimeout(function () {
          let vcElems = document.getElementsByClassName(
            "vessel-card cards-row-item"
          );

          if (
            self.printLayoutOptions === "vc-followed-by-add-ons" ||
            self.printLayoutOptions === "vc-add-ons-side-by-side"
          ) {
            expectedVc = vcElems.length;
            renderVc = true;
            renderMt = true;
          } else {
            //self.printLayoutOptions === "vc-only"
            expectedVc = vcElems.length;
            renderVc = true;
          }

          renderRot = self.includeRollOutTable;

          //Render the vessel cards as images
          if (renderVc && vcElems.length) {
            Array.from(vcElems).forEach((elem) => {
              html2canvas(elem, { scale: 2 }).then(
                (canvas) => {
                  let elemKey = elem.dataset && elem.dataset.key ? elem.dataset.key : null;
                  vcRenderComplete(canvas.toDataURL(), elemKey);

                }, 
                (err) => {
                  console.error(err);
                });
            });
          }
        }, waitTimeToRender);
      }
    },
    print() {
      window.print();
    },
    vesselLabelFn(vessel, columnDef) {
      if (!vessel || !columnDef) {
        return "";
      }

      let output = "";

      if (this.$_.isFunction(columnDef.formatter)) {
        // formatter: value, key, item
        output = columnDef.formatter(null, columnDef.key, vessel);
      } else if (
        columnDef.key &&
        Object.prototype.hasOwnProperty.call(vessel, columnDef.key)
      ) {
        let displayVal = vessel[columnDef.key];
        output = displayVal || "";
      }

      /*if (columnDev.key === 'qfm' && output == -1) {
            output = "";
          } else if (columnDev.key != 'qfm' && columnDef.isNumeric && (output == -1 || output == 0)) {
            output = "";
          }*/

      return output;
    },
    getDataUri(url, callback) {
      var image = new Image();

      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
        canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

        canvas.getContext("2d").drawImage(this, 0, 0);

        // Get raw image data
        callback(
          canvas
            .toDataURL("image/png")
            .replace(/^data:image\/(png|jpg);base64,/, "")
        );

        // ... or get as Data URI
        callback(canvas.toDataURL("image/png"));
      };

      image.src = url;
    },
    vesselCardImageDataLoaded(vesselCardImageLoadedEventData, vesselCard) {
      // TODO: I eventually want to use this to generate the preview image for a vessel card instead of trying to generate the images upon creating the PDF
      console.log("==> Vessel Card img data loaded: ", vesselCardImageLoadedEventData, " - vesselCard: ", vesselCard);

      /*if(vesselCard && vesselCardImageLoadedEventData && !vesselCardImageLoadedEventData.isPlaceholder) {
        vesselCard.jpgData = vesselCardImageLoadedEventData.imgData;
      }*/
    },
    gameCardImageDataLoaded(gameCardImageLoadedEventData, addOn) {
      //console.log("==> Game Card img data loaded: ", gameCardImageLoadedEventData, " - addOn: ", addOn);

      if(addOn && gameCardImageLoadedEventData && !gameCardImageLoadedEventData.isPlaceholder) {
        addOn.jpgData = gameCardImageLoadedEventData.imgData;
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    printOutputOptions: function (newVal, oldVal) {
      if (newVal === "output-print") {
        if (
          this.printLayoutOptions === "roll-out-table-only" ||
          this.printLayoutOptions === "roll-out-table-followed-by-add-ons"
        ) {
          this.printLayoutOptions = "vc-followed-by-add-ons";
        }

        this.includeRollOutTable = false;
      }
    },
  },
  created() {
    //console.log("PrintWindow created - mode = " + this.config.mode);
    this.includeVesselCards = true;
    this.includeRollOutTable = false;

    if (this.config.mode === "vessel-set") {
      this.showRollOutTableOpt = false;
    } else if (this.config.mode === "roll-out-table") {
      this.printLayoutOptions = "roll-out-table-followed-by-add-ons";
      this.showRollOutTableOpt = true;
    } else if (this.config.mode === "mini-tables") {
      this.printLayoutOptions = "add-ons-only";
      this.showVesselCardsOpt = false;
      this.showRollOutTableOpt = false;
    }

    // Build out the add-ons list
    const template = {
      key: null,
      label: null,
      gc: null,
      gcType: null,
      inlineStyle: {
        width: '465px',
        height: "auto",
      },
      includeInPrint: false,
      printCount: 1,
      svg: null,
      jpg: null,
      jpgData: null,
    };

    const assets = this.$store.state.config.assets;
    const gameCardTypes = this.$store.state.config.gameCardTypes;
    const gameCards = this.$store.state.config.gameCards;

    if (gameCards && Array.isArray(gameCardTypes)) {
      let allGameCards = {};
      let supportedGameCards = [];
      this.selectedSideBySideAddOn = null;
      gameCardTypes.forEach((gcType) => {
        const gcTypeCards = gameCards.hasOwnProperty(gcType.key)
          ? gameCards[gcType.key]
          : [];

        if (Array.isArray(gcTypeCards)) {
          gcTypeCards.forEach((gc) => {
            if (gc) {
              let gcTemplate = Object.assign({}, template, {
                key: gc.key,
                label: gc.label,
                gc: gc,
                gcType: gcType,
                svg:
                  assets.base +
                  assets[gcType.key] +
                  gc.assetRef +
                  assets[gcType.key + "SvgExt"],
                jpg:
                  assets.base +
                  assets[gcType.key] +
                  gc.assetRef +
                  assets[gcType.key + "ImgExt"],
              });

              allGameCards[gc.key] = gcTemplate;
              supportedGameCards.push(gcTemplate);

              if (!this.selectedSideBySideAddOn) {
                this.selectedSideBySideAddOn = gcTemplate;
              }
            }
          });
        }
      });

      this.supportedAddOns = supportedGameCards;
      this.addOnItems = allGameCards;
    }
  },
};
</script>

<style lang="scss">
@mixin vesselCardPrintStyles {
  .vessel-card-content {
    width: auto;
    max-width: 465px;
  }
}

@mixin miniTablesPrintStyles {
  flex: 0 0 470px;
}

.print-window {
  position: relative;
  display: block;
  background-color: #fff;
  z-index: 5;

  .header {
    margin-bottom: 20px;
    background-color: #eeeeee;
    border: 1px solid rgb(138, 138, 138);

    .button-wrapper {
      flex: 0 0 auto;

      button + button {
        margin-left: 10px;
      }
    }
  }

  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }

  .print-options,
  .vs-info,
  .preview-header,
  .cards {
    margin-left: 20px;
    margin-right: 20px;
  }

  .print-options {
    font-size: 0.9em;
    margin-bottom: 20px;

    h4,
    .h4 {
      font-size: 1.3em;
    }

    .add-ons {
      .add-on-option {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        .print-count {
          white-space: nowrap;

          .badge {
            font-size: 0.8rem;
            padding: 0.4rem;
          }
        }

        .range-selector {
          max-width: 30rem;
        }

        .custom-checkbox .custom-control-label {
          flex: 0 0 auto;
          white-space: nowrap;
        }
      }
    }

    .settings .custom-checkbox {
      display: block;
      margin-bottom: 6px;
    }
  }

  .vs-info {
    margin-bottom: 20px;

    .vs-desc {
      white-space: pre-line;
    }
  }

  .cards {
    position: relative;
    width: 100%;

    .cards-row {
      display: flex;
      margin-bottom: 20px;

      .copyright {
        font-size: 8pt;
      }

      .spacer {
        flex: 0 0 10px;
      }

      .cards-row-item:not(.add-on) {
        flex: 0 0 500px;
      }

      .cards-row-item.add-on {
        width: 468px;
        flex: 0 0 auto;
      }
    }

    &.add-ons {
      .add-on {
        //width: 500px;
        margin: 0 10px 10px 0;
      }
    }

    &.simulated-print {
      .cards-row {
        margin-bottom: 0;
      }

      .cards-row-item:not(.add-on) {
        flex: 0 0 9.7cm;
      }

      .cards-row-item.add-on {
        width: 9.7cm;
        flex: 0 0 auto;
      }

      .vessel-card {
        @include vesselCardPrintStyles();
      }

      .vessel-card-instructions {
        @include miniTablesPrintStyles();

        .vci-section {
          &:first-child {
            margin-bottom: 12px;
          }

          .vci-section-col > div {
            padding: 3px 3px 4px 2px;
          }

          .vci-section-title {
            padding: 0 6px 0 0;
          }
        }
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: rgba(109, 152, 187, 0.8);
    font-size: 1.5em;
  }

  .spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: #333;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
  }

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
}

@page {
  size: A4;
  margin: 1.5cm 1cm;
}

@media print {
  .print-window {
    position: relative;
    padding: 0;
    margin: 0;
    overflow: hidden;

    .header,
    .print-options,
    .preview-header {
      display: none;
    }

    .vs-info {
      display: block;
      position: relative;
      break-after: always; //New prop
      page-break-after: always; //Old prop
      height: 30cm;
    }

    .cards {
      padding: 0;
      margin: 0;

      /*&.pb-before {
            break-before: always; //New prop
            page-break-before: always; //Old prop
          }*/

      .cards-row {
        position: relative;
        break-inside: avoid; //New prop
        page-break-inside: avoid; //Old prop
        padding: 0 2px;
        margin-bottom: 15px;

        .spacer {
          flex: 1 1 auto;
          min-width: 0;
          width: 100%;
        }

        .cards-row-item:not(.add-on) {
          flex: 0 0 9.7cm;
        }

        .cards-row-item.add-on {
          width: 12.3cm;
          flex: 0 0 auto;
        }

        .vessel-card {
          &.default-print {
            @include vesselCardPrintStyles();
          }
        }

        .vessel-card-instructions {
          @include miniTablesPrintStyles();

          .vci-section:first-child {
            margin-bottom: 6px;
          }
        }
      }

      &.add-ons {
        .add-on {
          width: 12.3cm;
          margin: 0 1cm 0.5cm 0;
        }
      }
    }
  }
}
</style>