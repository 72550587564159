import firebase from "firebase/app";
import "firebase/firestore";
import Vue from "vue";
import CommonConstants from "../../common/constants";

// initial state
const state = {
  assets: null,
  gameCards: null,
  gameCardTypes: [],
  lists: null,
  nations: [],
};

// getters
const getters = {
  getAssetsConfig: (state) => () => {
    return state.assets;
  },
  getGameCardsConfig: (state) => () => {
    return state.gameCards;
  },
  getListsConfig: (state) => () => {
    return state.lists;
  },
};

// actions
const actions = {
  clearCachedGameCardImageData({ commit }) {
    commit("clearCachedGameCardImageData");
  },
  loadAssetsConfig({ commit }) {
    firebase
      .firestore()
      .collection("config")
      .doc("assets")
      .get()
      .then((assetsDocRef) => {
        if (assetsDocRef.exists) {
          commit("setAssets", assetsDocRef.data());
        }
      });
  },
  loadGameCards({ commit }) {
    firebase
      .firestore()
      .collection("config")
      .doc("game-cards")
      .get()
      .then((docRef) => {
        if (docRef.exists) {
          commit("setGameCards", docRef.data());
        }
      });
  },
  loadListsConfig({ commit }) {
    firebase
      .firestore()
      .collection("config")
      .doc("lists")
      .get()
      .then((listsDocRef) => {
        if (listsDocRef.exists) {
          commit("setLists", listsDocRef.data());
        }
      });
  },
};

// mutations
const mutations = {
  clearCachedGameCardImageData() {
    Vue.$utilService.clearLocalCache(CommonConstants.LF_GAME_CARDS_CACHE);
  },
  setAssets(state, assetsConfig) {
    //console.log(assetsConfig);
    state.assets = assetsConfig;
  },
  setGameCards(state, gameCardsData) {
    let allGameCards = {};

    for (const gcType in gameCardsData) {
      let gcTypeItems = gameCardsData[gcType];

      if (Array.isArray(gcTypeItems)) {
        allGameCards[gcType] = gcTypeItems.sort((a, b) => {
          return Vue.$utilService.sortAlphaNumeric(a.order, b.order);
        }).filter((gc) => {
          return gc.active == true;
        });
      }
    }

    state.gameCards = allGameCards;
  },
  setLists(state, listsConfig) {
    //console.log(listsConfig);
    let allLists = listsConfig;

    if (allLists && Array.isArray(allLists.nations)) {
      allLists.nations.sort();
      state.nations = allLists.nations;
    }

    if (allLists && Array.isArray(allLists.gameCards)) {
      const gcTypes = [];

      // Expecting an array of strings
      // Expected string template: <<order>>~<<gameCardKey>>~<<label>> (e.g. '1~gameRules~Game Rules')
      allLists.gameCards.sort().forEach((gcType) => {
        const gcParts = gcType.split("~");

        if (Array.isArray(gcParts) && gcParts.length >= 3) {
          gcTypes.push({
            order: gcParts[0],
            key: gcParts[1],
            label: gcParts[2],
          });
        }
      });
      state.gameCardTypes = gcTypes;
    }

    state.lists = allLists;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
