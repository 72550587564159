import firebase from "firebase/app";
import "firebase/firestore";
import Vue from "vue";

const sortAlphaNumeric = (itemA, itemB) => {
  let aTitle = itemA.label;
  let bTitle = itemB.label;
  return aTitle.localeCompare(bTitle, "en", { numeric: true });
};

// initial state
const state = {
  vesselSetKeys: [],
  vesselSetsDictionary: {},
  filterKeyword: "",
  selected: {},
  pendingSelected: null,
};

// getters
const getters = {
  getFilteredVesselSets: state => filterVal => {
    let items = state.vesselSetKeys;
    // Filter the list
    if (filterVal && filterVal.length) {
      items = items.filter(item => {
        return item.label.toLowerCase().indexOf(filterVal.toLowerCase()) >= 0;
      });
    }
    return items;
  },
};

// actions
const actions = {
  addNewVesselSet({ commit, dispatch, rootState }, newVesselSetName) {
    let db = firebase.firestore();
    let currentUser = rootState.user.current;

    //Add a new document
    db
      .collection("vessel-sets")
      .add({
        userRef: currentUser.id,
        user: currentUser.email,
        desc: "",
        vessels: [],
      })
      .then(docRef => {
        //Now that the new document has been created, add the reference to the user's user-data record.
        let itemsToMerge = {};
        itemsToMerge[docRef.id] = newVesselSetName;
        let userDataToMerge = { vesselSets: itemsToMerge };
        let userDataDocRef = db.collection("user-data").doc(currentUser.id);

        //Merge the new vessel set information into user-data
        userDataDocRef
          .set(userDataToMerge, { merge: true })
          .then(() => {
            commit("setPendingSelected", {
              key: docRef.id,
              label: newVesselSetName,
            });

            //Now we need to make sure the main vessel sets list is showing up-to-date information
            //Get current state of user-data
            dispatch("user/refreshUserData", null, { root: true });
          })
          .catch(error => {
            console.error(
              "Error writing new vessel set data to user-data: ",
              error
            );
          });
      })
      .catch(error => {
        console.error("Error adding new Vessel Set: ", error);
      });
  },
  cleanup({ commit }) {
    commit("cleanup");
  },
  deleteVesselSet({ commit, dispatch, rootState }, vesselSet) {
    let db = firebase.firestore();
    let currentUser = rootState.user.current;

    db
      .collection("vessel-sets")
      .doc(vesselSet.key)
      .delete()
      .then(function() {
        //Delete the reference in user-data
        let userDataDocRef = db.collection("user-data").doc(currentUser.id);

        userDataDocRef.get().then(userDataRef => {
          if (userDataRef.exists) {
            let currentUserData = userDataRef.data();
            if (Object.prototype.hasOwnProperty.call(currentUserData.vesselSets, vesselSet.key)) {
              delete currentUserData.vesselSets[vesselSet.key];

              userDataDocRef
                .update(currentUserData)
                .then(() => {
                  commit("clearSelected");

                  Vue.toasted.show("Vessel Set successfully deleted!", {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 3000,
                  });

                  //Now we need to make sure the main vessel sets list is showing up-to-date information
                  //Get current state of user-data
                  dispatch("user/refreshUserData", null, {
                    root: true,
                  });
                })
                .catch(error => {
                  console.error("Error updating user-data: ", error);
                });
            }
          }
        });
      })
      .catch(function(error) {
        console.error("Error removing vessel set document: ", error);
      });
  },
  setFilter({ commit }, keyword) {
    commit("setFilter", keyword);
  },
  setVesselSetKeys({ commit, state, dispatch }, sets) {
    let keys = [];
    if (typeof sets === "object") {
      let pendingSelectedMatchFound = false;
      for (var prop in sets) {
        if (Object.prototype.hasOwnProperty.call(sets, prop)) {
          keys.push({ key: prop, label: sets[prop] });

          if (state.pendingSelected && state.pendingSelected.key === prop) {
            pendingSelectedMatchFound = true;
          }
        }
      }

      if (pendingSelectedMatchFound) {
        dispatch("setSelected", state.pendingSelected);
        commit("setPendingSelected", null);
      }
    }
    keys.sort(sortAlphaNumeric);
    commit("setVesselSetKeys", keys);
  },
  setSelected({ commit }, vesselSet) {
    if (
      state.vesselSetsDictionary &&
      Object.prototype.hasOwnProperty.call(state.vesselSetsDictionary, vesselSet.key)
    ) {
      //Use the previously loaded vessel set data
      commit("setSelected", state.vesselSetsDictionary[vesselSet.key]);
    } else {
      //Load the vessel set's data from the DB
      firebase
        .firestore()
        .collection("vessel-sets")
        .doc(vesselSet.key)
        .get()
        .then(vesselSetRef => {
          if (vesselSetRef.exists) {
            let vesselSetRefData = vesselSetRef.data();

            let vesselSetVesselKeys = [];
            vesselSetRefData.vessels.forEach(vessel => {
              vesselSetVesselKeys.push({
                key: vessel,
                label: vessel.replace("_", " - "),
              });
            });

            let selectedVesselSet = {
              key: vesselSet.key,
              label: vesselSet.label,
              desc: vesselSetRefData.desc,
              vessels: vesselSetVesselKeys,
            };

            commit("setSelected", selectedVesselSet);
          } else {
            console.error(
              "The document for vessel set " +
                vesselSet.key +
                " does not exist."
            );
          }
        })
        .catch(function(error) {
          console.error(
            "There was a problem loading the document for vessel set " +
              vesselSet.key +
              ": " +
              error
          );
        });
    }
  },
  updateVesselSet({ commit, state, rootState, dispatch }, updateData) {
    let db = firebase.firestore();
    let currentUser = rootState.user.current;
    let updateDataVesselsSet = !!Array.isArray(
      updateData.vessels
    ) /*&& updateData.vessels.length*/;

    let vesselSetToMerge = { vessels: updateData.vessels };
    if (updateDataVesselsSet) {
      vesselSetToMerge.vessels = updateData.vessels;
    }
    if (typeof updateData.sDesc === "string") {
      vesselSetToMerge.desc = updateData.sDesc;
    }

    //Update the vessels list for the existing vessel set document
    if (updateData.vessels || updateData.sDesc) {
      db
        .collection("vessel-sets")
        .doc(updateData.key)
        .set(vesselSetToMerge, { merge: true })
        .then(() => {
          //Update the associated dictionary reference
          let currentVesselSetRef = state.vesselSetsDictionary[updateData.key];
          let vesselSetsVesselKeys = [];

          if (updateDataVesselsSet) {
            updateData.vessels.forEach(vessel => {
              vesselSetsVesselKeys.push({
                key: vessel,
                label: vessel.replace("_", " - "),
              });
            });
          }

          let updatedVesselSet = {
            key: currentVesselSetRef.key,
            label: updateData.sName || currentVesselSetRef.label,
            desc:
              typeof updateData.sDesc === "string"
                ? updateData.sDesc
                : currentVesselSetRef.desc,
            vessels: updateDataVesselsSet
              ? vesselSetsVesselKeys
              : currentVesselSetRef.vessels,
          };

          commit("updateVesselSetsDictionary", updatedVesselSet);
          commit("setSelected", updatedVesselSet);
        })
        .catch(error => {
          console.error("Error updating vessel set: ", error);
        });
    }

    //If the vessel set label changed, update the label for the vessel set in user-data
    if (updateData.sName) {
      let userDataDocRef = db.collection("user-data").doc(currentUser.id);

      //Get a fresh copy of user-data
      userDataDocRef.get().then(userDataRef => {
        if (userDataRef.exists) {
          let currentUserData = userDataRef.data();
          if (Object.prototype.hasOwnProperty.call(currentUserData.vesselSets, updateData.key)) {
            //Update the vessel set's label
            currentUserData.vesselSets[updateData.key] = updateData.sName;

            //Save the updated user-data back to the DB
            userDataDocRef
              .update(currentUserData)
              .then(() => {
                //Now we need to make sure the main vessel sets list is showing up-to-date information
                //Get current state of user-data
                dispatch("user/refreshUserData", null, {
                  root: true,
                });
              })
              .catch(error => {
                console.error(
                  "Error writing new vessel set name to user-data: ",
                  error
                );
              });
          }
        }
      });
    }
  },
};

// mutations
const mutations = {
  cleanup(state) {
    state.vesselSetsDictionary = {};
    state.filterKeyword = "";
    state.selected = {};
  },
  clearSelected(state) {
    state.selected = {};
  },
  setFilter(state, keyword) {
    state.filterKeyword = keyword;
  },
  setVesselSetKeys(state, keys) {
    state.vesselSetKeys = keys;
  },
  setPendingSelected(state, vesselSet) {
    //vesselSet - {key, label}
    state.pendingSelected = vesselSet;
  },
  setSelected(state, vesselSet) {
    if (
      state.vesselSetsDictionary &&
      !Object.prototype.hasOwnProperty.call(state.vesselSetsDictionary, vesselSet.key)
    ) {
      state.vesselSetsDictionary[vesselSet.key] = vesselSet;
    }

    state.selected = vesselSet;
  },
  setVesselSets(state, vesselSets) {
    state.vesselSetKeys = Array.isArray(vesselSets)
      ? vesselSets.sort(sortAlphaNumeric)
      : [];
  },
  updateVesselSetsDictionary(state, vesselSet) {
    state.vesselSetsDictionary[vesselSet.key] = vesselSet;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
