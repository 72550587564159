import firebase from "firebase/app";
import "firebase/auth";
import Vue from "vue";
import Router from "vue-router";
import store from "./store";

//Views
import Admin from "@/views/Admin.vue";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import MiniTables from "@/views/MiniTables.vue";
import VesselSets from "@/views/VesselSets.vue";
import Register from "@/views/Register.vue";
import UnsupportedBrowser from "@/views/UnsupportedBrowser.vue";
import UserProfile from "@/views/UserProfile.vue";
import Vessels from "@/views/Vessels.vue";
import VesselsDataSheet from "@/views/VesselsDataSheet.vue";

Vue.use(Router);
const theStore = store;
const router = new Router({
  routes: [
    {
      path: "*",
      redirect: "/"
    },
    {
      path: "/",
      name: "/",
      component: Home
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/unsupported",
      name: "unsupportedBrowser",
      component: UnsupportedBrowser
    },
    {
      path: "/bsbw-administration",
      name: "admin",
      component: Admin,
      meta: {
        ra: true,
        rsa: true
      }
    },
    {
      path: "/tables-blank-cards",
      name: "Tables/Blank Cards",
      component: MiniTables,
      meta: {
        ra: true
      }
    },
    {
      path: "/vessel-sets",
      name: "vessel-sets",
      component: VesselSets,
      meta: {
        ra: true
      }
    },
    {
      path: "/vessels-roll-out",
      name: "vessels-roll-out",
      component: VesselsDataSheet,
      meta: {
        ra: true,
        rsa: true
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: UserProfile,
      meta: {
        ra: true
      }
    },
    {
      path: "/vessels",
      name: "vessels",
      component: Vessels,
      meta: {
        ra: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const ra = to.matched.some(r => r.meta.ra);
  const rsa = to.matched.some(r => r.meta.rsa);
  const fromRa = from && from.matched.some(r => r.meta.ra);

  next();

  if (!ra) {
    if (to.redirectedFrom && fromRa) {
      //In this case, we were already in a valid site route
      // but the requested route was not recognized. Take
      // the user to the dashboard screen.
      next("/");
    } else {
      //theStore.dispatch("user/logOut", false);
      next();
    }
  } else {
    const au = firebase.auth().currentUser;

    if (ra && !au) {
      theStore.dispatch("user/logOut");
      next("/");
    } else if (rsa && au && !theStore.state.user.isSa) {
      next(from && from.name ? from.name : "/");
    } else {
      next();
    }
  }
});

export default router;
