<template>
  <div class="app-main vessels-data-sheet">
    <main>
      <b-navbar class="p-2" type="light">
        <b-navbar-brand class="mb-0 p-0" tag="h1">Vessels Roll Out Table</b-navbar-brand>

        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-button
              v-bind:variant="filtersApplied ? 'success' : 'outline-primary'"
              class="mr-2"
              size="sm"
              aria-label="Filters"
              v-b-toggle.filter-sidebar
              title="Show Filters"
            >
              <i class="fas fa-filter"></i>
            </b-button>
            
            <b-button
              class="h-100 mr-2"
              variant="outline-primary"
              size="sm"
              v-bind:disabled="inDm"
              v-b-modal.vesselsJsonModal
            >
              JSON
            </b-button>

            <b-button
              class="d-flex align-items-baseline h-100"
              variant="outline-primary"
              size="sm"
              v-on:click="print"
              alt="Print"
            >
              <i class="fas fa-print mr-1"></i>Print
            </b-button>
          </b-nav-form>
        </b-navbar-nav>
      </b-navbar>

      <div class="content">
        <p v-if="!showLoadingIndicator">{{ filterResultsLabel }}</p>

        <div class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th colspan="1" scope="colgroup" class="sticky-col">&nbsp;</th>
                <th colspan="1" scope="colgroup"></th>
                <th colspan="18" scope="colgroup">Stats</th>
                <th colspan="9" scope="colgroup">Primary Guns (1 Primary Gun)</th>
                <th colspan="9" scope="colgroup">Secondary Guns (1 Primary Gun)</th>
                <th colspan="9" scope="colgroup">Primary Guns (2 Primary Guns)</th>
                <th colspan="9" scope="colgroup">Semi Guns (2 Primary Guns)</th>
                <th colspan="9" scope="colgroup">Secondary Guns (2 Primary Guns)</th>
                <th colspan="7" scope="colgroup">Quick Fire Guns</th>
                <th colspan="5" scope="colgroup">Torpedoes</th>
                <th colspan="4" scope="colgroup">Additional Detail</th>
              </tr>
              <tr>
                <th colspan="1" scope="colgroup" class="sticky-col">&nbsp;</th>
                <th colspan="1" scope="colgroup"></th>
                <th colspan="4" scope="colgroup"></th>
                <th colspan="8" scope="colgroup">Armor</th>
                <th colspan="6" scope="colgroup"></th>
                <th colspan="3" scope="colgroup"></th>
                <th colspan="4" scope="colgroup">Penetration Ranges</th>
                <th colspan="2" scope="colgroup"></th>
                <th colspan="3" scope="colgroup"></th>
                <th colspan="4" scope="colgroup">Penetration Ranges</th>
                <th colspan="2" scope="colgroup"></th>
                <th colspan="3" scope="colgroup"></th>
                <th colspan="4" scope="colgroup">Penetration Ranges</th>
                <th colspan="2" scope="colgroup"></th>
                <th colspan="3" scope="colgroup"></th>
                <th colspan="4" scope="colgroup">Penetration Ranges</th>
                <th colspan="2" scope="colgroup"></th>
                <th colspan="3" scope="colgroup"></th>
                <th colspan="4" scope="colgroup">Penetration Ranges</th>
                <th colspan="2" scope="colgroup"></th>
                <th colspan="3" scope="colgroup"></th>
                <th colspan="2" scope="colgroup">Penetration Ranges</th>
                <th colspan="2" scope="colgroup"></th>
                <th colspan="5" scope="colgroup"></th>
                <th colspan="4" scope="colgroup"></th>
              </tr>
              <tr>
                <th
                  v-for="col of stickyColumns"
                  :key="col.id"
                  class="sticky-col"
                >
                  <div>{{ col.label }}</div>
                </th>
                <th v-for="col of columns" :key="col.id">
                  <div>{{ col.label }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="vessel of tableItemsForDisplay"
                :key="vessel.key"
                v-on:click="selectRow(vessel)"
                v-bind:class="{
                  selected: selectedItem && selectedItem.key === vessel.key,
                }"
              >
                <th
                  scope="col"
                  v-for="col of stickyColumns"
                  :key="col.id"
                  class="sticky-col"
                >
                  {{ vesselLabelFn(vessel, col) }}
                </th>
                <td
                  v-for="col of columns"
                  :key="col.key"
                  :variant="col.variant"
                >
                  <div
                    v-bind:style="col.tdLabelStyle"
                    v-bind:title="col.sectionLabel + col.label"
                  >
                    {{ vesselLabelFn(vessel, col) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="loading-indicator" v-if="showLoadingIndicator">
            <div class="sk-chase">
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
            </div>
            <div>Loading Vessel Data...</div>
          </div>
        </div>
      </div>
    </main>

    <b-sidebar
      id="filter-sidebar"
      v-model="filterPanelOpen"
      title="Vessel Filters"
      bg-variant="light"
      text-variant="dark"
      shadow
      backdrop
      body-class="p-3"
    >
      <b-form-group label="View by Nation" label-class="font-weight-bold">
        <div class="d-flex justify-content-end align-items-baseline">
          <span class="mr-2">Select</span>
          <b-button variant="link" class="p-0" v-on:click="selectAllNations()"
            >All</b-button
          >
          <span class="mx-1">/</span>
          <b-button variant="link" class="p-0" v-on:click="selectedNations = []"
            >None</b-button
          >
        </div>

        <b-form-checkbox-group
          v-model="selectedNations"
          :options="nations"
          value-field="key"
          name="nationFilter"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>

      <b-form-group label="View by Vessel Dataset Version" label-class="font-weight-bold">
        <div class="d-flex justify-content-end align-items-baseline">
          <span class="mr-2">Select</span>
          <b-button variant="link" class="p-0" v-on:click="selectAllVersions()"
            >All</b-button
          >
          <span class="mx-1">/</span>
          <b-button
            variant="link"
            class="p-0"
            v-on:click="selectedVersions = []"
            >None</b-button
          >
        </div>

        <b-form-checkbox-group
          v-model="selectedVersions"
          :options="versions"
          value-field="key"
          name="versionFilter"
          stacked
        ></b-form-checkbox-group>

        <div class="d-flex justify-content-end mt-3">
          <b-button
            class="mr-3"
            variant="primary"
            size="sm"
            v-on:click="executeFilters()"
            aria-controls="filter-sidebar"
            :aria-expanded="filterPanelOpen ? 'true' : 'false'"
            >Apply Filters</b-button
          >

          <b-button
            variant="outline-secondary"
            size="sm"
            v-on:click="resetFilters()"
            >Reset Filters</b-button
          >
        </div>
      </b-form-group>
    </b-sidebar>

    <b-modal
      id="vesselsJsonModal"
      title="Vessels JSON Output"
      header-bg-variant="dark"
      header-text-variant="light"
      @shown="initializeJsonModal"
      ok-only
      ok-title="Close"
      size="lg"
      centered>
      
      <b-card>
          <div class="json-output">{{jsonOutput}}</div>

          <b-button
            variant="primary"
            slot="footer"
            size="sm"
            v-on:click="copyToClipboard"
          >Copy to Clipboard</b-button>
        </b-card>
    </b-modal>
  </div>
</template>

<script>
  import Vue from "vue";

  export default {
    name: "VesselsDataSheet",
    data() {
      return {
        tableItemsFromDb: [],
        tableItemsForDisplay: [],
        selectedItem: null,
        filterPanelOpen: false,
        nations: [],
        selectedNations: [],
        versions: [],
        showVersion: false,
        showDisplayName: false,
        selectedVersions: [],
        jsonOutput: "",
        showLoadingIndicator: false,
        stickyColumns: [
          {
            id: "1",
            key: "cls",
            label: "Class",
            stickyColumn: true,
            sortable: true,
            formatter: (value, key, item) => {
              let displayName = this.showDisplayName ? item._name : item.cls;
              return this.showVersion ? displayName + "  (" + item._version + ")" : displayName;
            },
          },
        ],
        columns: [
          {
            id: "1-1",
            key: "_active",
            sectionLabel: "",
            label: "Active",
            sortable: true,
            formatter: (value, key, item) => {
              return item._active == true ? "Active" : "Inactive";
            }
          },
          {
            id: "2",
            key: "type",
            sectionLabel: "",
            label: "Type",
            sortable: true,
          },
          {
            id: "3",
            key: "nat",
            sectionLabel: "",
            label: "Nation",
            sortable: true,
          },
          {
            id: "5",
            key: "pts",
            sectionLabel: "",
            label: "Points",
            sortable: true,
          },
          {
            id: "6",
            key: "hull",
            sectionLabel: "",
            label: "Hull",
            sortable: true,
            sortDirection: "desc",
          },
          {
            id: "7",
            key: "ap1",
            sectionLabel: "Armor - ",
            label: "Primary",
            sortable: true,
            sortDirection: "desc",
            variant: "info",
          },
          {
            id: "8",
            key: "as1",
            sectionLabel: "Armor - ",
            label: "Secondary",
            sortable: true,
            sortDirection: "desc",
            variant: "info",
          },
          {
            id: "14",
            key: "sat",
            sectionLabel: "Armor - ",
            label: "Armor",
            sortable: true,
            sortDirection: "desc",
            variant: "info",
          },
          {
            id: "9",
            key: "qf",
            sectionLabel: "Armor - ",
            label: "QF",
            sortable: true,
            sortDirection: "desc",
            variant: "info",
          },
          {
            id: "10",
            key: "wl",
            sectionLabel: "Armor - ",
            label: "Water Line",
            sortable: true,
            sortDirection: "desc",
            variant: "info",
          },
          {
            id: "11",
            key: "uh",
            sectionLabel: "Armor - ",
            label: "Upper Hull",
            sortable: true,
            sortDirection: "desc",
            variant: "info",
          },
          {
            id: "12",
            key: "conn",
            sectionLabel: "Armor - ",
            label: "Conn",
            sortable: true,
            sortDirection: "desc",
            variant: "info",
          },
          {
            id: "13",
            key: "deck",
            sectionLabel: "Armor - ",
            label: "Deck",
            sortable: true,
            sortDirection: "desc",
            variant: "info",
          },
          {
            id: "14a",
            key: "vit",
            sectionLabel: "",
            label: "Vitals",
            sortable: true,
          },
          {
            id: "15",
            key: "vitB",
            sectionLabel: "",
            label: "Vitals (x1.6)",
            formatter: (value, key, item) => {
              //let vit = item.vit * 1.5;
              //return +(Math.round(vit + "e+2") + "e-2");
              return item.vit > 0 ? Math.round(1.6 * item.vit) : "";
            },
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
          },
          {
            id: "16",
            key: "spd",
            sectionLabel: "",
            label: "Move",
            sortable: true,
          },
          {
            id: "17",
            key: "acl",
            sectionLabel: "",
            label: "Accel",
            sortable: true,
          },
          {
            id: "18",
            key: "cir",
            sectionLabel: "",
            label: "Circle",
            sortable: true,
          },
          {
            id: "19",
            key: "ram",
            sectionLabel: "",
            label: "Ram",
            sortable: true,
          },
          {
            id: "20",
            key: "gpno",
            sectionLabel: "Primary Guns - ",
            label: "No.",
            sortable: true,
            variant: "info",
          },
          {
            id: "21",
            key: "gpsz",
            sectionLabel: "Primary Guns - ",
            label: 'Size"',
            sortable: true,
            variant: "info",
          },
          {
            id: "22",
            key: "gpc",
            sectionLabel: "Primary Guns - ",
            label: "Class",
            formatter: (value, key, item) => {
              return item && item.gpc
                ? this.$_.upperFirst(item.gpc).substr(0, 1)
                : "";
            },
            sortable: true,
            variant: "info",
          },
          {
            id: "23",
            key: "gps",
            sectionLabel: "Primary Guns - ",
            label: "S",
            sortable: true,
            variant: "info",
          },
          {
            id: "24",
            key: "gpm",
            sectionLabel: "Primary Guns - ",
            label: "M",
            sortable: true,
            variant: "info",
          },
          {
            id: "25",
            key: "gpl",
            sectionLabel: "Primary Guns - ",
            label: "L",
            sortable: true,
            variant: "info",
          },
          {
            id: "26",
            key: "gpe",
            sectionLabel: "Primary Guns - ",
            label: "E",
            sortable: true,
            variant: "info",
          },
          {
            id: "27",
            key: "gpd",
            sectionLabel: "Primary Guns - ",
            label: "Dmg",
            sortable: true,
            variant: "info",
          },
          {
            id: "28",
            key: "gpr",
            sectionLabel: "Primary Guns - ",
            label: "RoF",
            sortable: true,
            variant: "info",
          },
          {
            id: "29",
            sectionLabel: "Secondary Guns - ",
            key: "gsno",
            label: "No.",
            sortable: true,
          },
          {
            id: "30",
            key: "gssz",
            sectionLabel: "Secondary Guns - ",
            label: 'Size"',
            sortable: true,
          },
          {
            id: "31",
            key: "gsc",
            sectionLabel: "Secondary Guns - ",
            label: "Class",
            formatter: (value, key, item) => {
              return item && item.gsc
                ? this.$_.upperFirst(item.gsc).substr(0, 1)
                : "";
            },
            sortable: true,
          },
          {
            id: "32",
            key: "gss",
            sectionLabel: "Secondary Guns - ",
            label: "S",
            sortable: true,
          },
          {
            id: "33",
            key: "gsm",
            sectionLabel: "Secondary Guns - ",
            label: "M",
            sortable: true,
          },
          {
            id: "34",
            key: "gsl",
            sectionLabel: "Secondary Guns - ",
            label: "L",
            sortable: true,
          },
          {
            id: "35",
            key: "gse",
            sectionLabel: "Secondary Guns - ",
            label: "E",
            sortable: true,
          },
          {
            id: "36",
            key: "gsd",
            sectionLabel: "Secondary Guns - ",
            label: "Dmg",
            sortable: true,
          },
          {
            id: "37",
            key: "gsr",
            sectionLabel: "Secondary Guns - ",
            label: "RoF",
            sortable: true,
          },
          {
            id: "37.1.1",
            key: "3g_gpno",
            sectionLabel: "Primary Guns - ",
            label: "No.",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.2",
            key: "3g_gpsz",
            sectionLabel: "Primary Guns - ",
            label: 'Size"',
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.3",
            key: "3g_gpc",
            sectionLabel: "Primary Guns - ",
            label: "Class",
            formatter: (value, key, item) => {
              return item && Object.prototype.hasOwnProperty.call(item, '3g_gpc') && typeof item['3g_gpc'] === 'string' && item['3g_gpc'].length > 1
                ? this.$_.upperFirst(item['3g_gpc']).substr(0, 1)
                : "";
            },
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.4",
            key: "3g_gps",
            sectionLabel: "Primary Guns - ",
            label: "S",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.5",
            key: "3g_gpm",
            sectionLabel: "Primary Guns - ",
            label: "M",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.6",
            key: "3g_gpl",
            sectionLabel: "Primary Guns - ",
            label: "L",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.7",
            key: "3g_gpe",
            sectionLabel: "Primary Guns - ",
            label: "E",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.8",
            key: "3g_gpd",
            sectionLabel: "Primary Guns - ",
            label: "Dmg",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.9",
            key: "3g_gpr",
            sectionLabel: "Primary Guns - ",
            label: "RoF",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.10",
            sectionLabel: "Secondary Guns - ",
            key: "3g_gsno",
            label: "No.",
            sortable: true,
          },
          {
            id: "37.1.11",
            key: "3g_gssz",
            sectionLabel: "Secondary Guns - ",
            label: 'Size"',
            sortable: true,
          },
          {
            id: "37.1.12",
            key: "3g_gsc",
            sectionLabel: "Secondary Guns - ",
            label: "Class",
            formatter: (value, key, item) => {
              return item && Object.prototype.hasOwnProperty.call(item, '3g_gsc') && typeof item['3g_gsc'] === 'string' && item['3g_gsc'].length > 1
                ? this.$_.upperFirst(item['3g_gsc']).substr(0, 1)
                : "";
            },
            sortable: true,
          },
          {
            id: "37.1.13",
            key: "3g_gss",
            sectionLabel: "Secondary Guns - ",
            label: "S",
            sortable: true,
          },
          {
            id: "37.1.14",
            key: "3g_gsm",
            sectionLabel: "Secondary Guns - ",
            label: "M",
            sortable: true,
          },
          {
            id: "37.1.15",
            key: "3g_gsl",
            sectionLabel: "Secondary Guns - ",
            label: "L",
            sortable: true,
          },
          {
            id: "37.1.16",
            key: "3g_gse",
            sectionLabel: "Secondary Guns - ",
            label: "E",
            sortable: true,
          },
          {
            id: "37.1.17",
            key: "3g_gsd",
            sectionLabel: "Secondary Guns - ",
            label: "Dmg",
            sortable: true,
          },
          {
            id: "37.1.18",
            key: "3g_gsr",
            sectionLabel: "Secondary Guns - ",
            label: "RoF",
            sortable: true,
          },
          {
            id: "37.1.19",
            sectionLabel: "Tertiary Guns - ",
            key: "3g_gtno",
            label: "No.",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.20",
            key: "3g_gtsz",
            sectionLabel: "Tertiary Guns - ",
            label: 'Size"',
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.21",
            key: "3g_gtc",
            sectionLabel: "Tertiary Guns - ",
            label: "Class",
            formatter: (value, key, item) => {
              return item && Object.prototype.hasOwnProperty.call(item, '3g_gtc') && typeof item['3g_gtc'] === 'string' && item['3g_gtc'].length > 1
                ? this.$_.upperFirst(item['3g_gtc']).substr(0, 1)
                : "";
            },
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.22",
            key: "3g_gts",
            sectionLabel: "Tertiary Guns - ",
            label: "S",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.23",
            key: "3g_gtm",
            sectionLabel: "Tertiary Guns - ",
            label: "M",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.24",
            key: "3g_gtl",
            sectionLabel: "Tertiary Guns - ",
            label: "L",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.25",
            key: "3g_gte",
            sectionLabel: "Tertiary Guns - ",
            label: "E",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.26",
            key: "3g_gtd",
            sectionLabel: "Tertiary Guns - ",
            label: "Dmg",
            sortable: true,
            variant: "info",
          },
          {
            id: "37.1.27",
            key: "3g_gtr",
            sectionLabel: "Tertiary Guns - ",
            label: "RoF",
            sortable: true,
            variant: "info",
          },
          {
            id: "38",
            key: "qfg",
            sectionLabel: "QF Guns - ",
            label: "Qfire",
            sortable: true,
          },
          {
            id: "38-1",
            key: "qfsz",
            sectionLabel: "QF Guns - ",
            label: 'Size"',
            sortable: true,
          },
          {
            id: "38-2",
            key: "qfc",
            sectionLabel: "QF Guns - ",
            label: "Class",
            sortable: true,
          },
          {
            id: "38-3",
            key: "qfs",
            sectionLabel: "QF Guns - ",
            label: "S",
            sortable: true,
          },
          {
            id: "38-4",
            key: "qfm",
            sectionLabel: "QF Guns - ",
            label: "M",
            sortable: true,
          },
          {
            id: "38-7",
            key: "qfd",
            sectionLabel: "QF Guns - ",
            label: "Dmg",
            sortable: true,
          },
          {
            id: "39",
            key: "gat",
            sectionLabel: "",
            label: "Gatling",
            sortable: true,
          },
          {
            id: "40",
            key: "tno",
            sectionLabel: "Torpedoes - ",
            label: "No.",
            sortable: true,
            variant: "info",
          },
          {
            id: "41",
            key: "tsz",
            sectionLabel: "Torpedoes - ",
            label: "Size",
            sortable: true,
            variant: "info",
          },
          {
            id: "42",
            key: "tc",
            sectionLabel: "Torpedoes - ",
            label: "Class",
            formatter: (value, key, item) => {
              return item && item.tc
                ? this.$_.upperFirst(item.tc).substr(0, 1)
                : "";
            },
            sortable: true,
            variant: "info",
          },
          {
            id: "43",
            key: "tf",
            sectionLabel: "Torpedoes - ",
            label: "Fast",
            sortable: true,
            variant: "info",
          },
          {
            id: "44",
            key: "ts",
            sectionLabel: "Torpedoes - ",
            label: "Slow",
            sortable: true,
            variant: "info",
          },
          {
            id: "45",
            key: "yr",
            sectionLabel: "",
            label: "Year",
            sortable: true,
          },
          {
            id: "46",
            key: "crew",
            sectionLabel: "",
            label: "Crew",
            sortable: true,
          },
          {
            id: "47a",
            key: "vic",
            sectionLabel: "",
            label: "Other Vessels",
            sortable: true,
          },
          {
            id: "47",
            key: "det",
            sectionLabel: "",
            label: "Details",
            sortable: true,
          },
        ],
      };
    },
    computed: {
      filtersApplied() {
        let nationsFiltered =
          Array.isArray(this.nations) &&
          Array.isArray(this.selectedNations) &&
          this.nations.length != this.selectedNations.length;
        let versionsFiltered =
          Array.isArray(this.versions) &&
          Array.isArray(this.selectedVersions) &&
          this.versions.length != this.selectedVersions.length;

        return nationsFiltered || versionsFiltered;
      },
      inDm: {
        get() {
          return this.$store.state.user.inDm;
        },
      },
      selectedVessel: function () {
        return this.$store.state.vessels.selected;
      },
      filterResultsLabel: function () {
        return Array.isArray(this.tableItemsFromDb) &&
          Array.isArray(this.tableItemsForDisplay)
          ? "(Showing " +
              this.tableItemsForDisplay.length +
              " of " +
              this.tableItemsFromDb.length +
              " vessels)"
          : "";
      },
    },
    methods: {
      executeFilters() {
        this.tableItemsForDisplay = this.tableItemsFromDb.filter((vessel) => {
          return (
            this.selectedNations.indexOf(vessel.nat) >= 0 &&
            this.selectedVersions.indexOf(vessel._version) >= 0
          );
        });
        this.filterPanelOpen = false;
      },
      loadVesselsFromDb() {
        Vue.$vesselsService.loadAllVesselsFromDb().then(
          (vesselsFromDb) => {
            this.tableItemsFromDb = this.tableItemsForDisplay = vesselsFromDb;
            this.prepFilters(this.tableItemsFromDb);
          },
          (err) => {
            console.error(err);
          }
        );
      },
      resetFilters() {
        this.selectAllNations();
        this.selectAllVersions();
        this.executeFilters();
      },
      selectRow(vessel) {
        this.selectedItem = vessel;
      },
      prepFilters(vesselData) {
        if (Array.isArray(vesselData)) {
          this.nations = this.$_.uniqBy(
            vesselData.map((vessel) => {
              return {
                key: vessel.nat,
                text: vessel.nat,
              };
            }),
            "key"
          );

          this.versions = this.$_.uniqBy(
            vesselData.map((vessel) => {
              return {
                key: vessel._version,
                text: vessel._version,
              };
            }),
            "key"
          );

          if (Array.isArray(this.versions)) {
            this.versions = this.versions.sort(
              Vue.$utilService.sortByKeyAlphaNumeric
            );
          }

          this.selectAllNations();
          this.selectAllVersions();
        }
      },
      print() {
        if (!this.inDm) {
          this.$store.dispatch("print/openPrintPreview", {
            vessels: this.tableItemsForDisplay,
            mode: "roll-out-table",
            vesselCardShowDisplayName: this.showDisplayName
          });
        }
      },
      selectAllNations() {
        this.selectedNations = this.nations.map((nation) => nation.key);
      },
      selectAllVersions() {
        this.selectedVersions = this.versions.map((version) => version.key);
      },
      vesselLabelFn(vessel, columnDef) {
        if (!vessel || !columnDef) {
          return "";
        }

        if (this.$_.isFunction(columnDef.formatter)) {
          // formatter: value, key, item
          return columnDef.formatter(null, columnDef.key, vessel);
        } else if (columnDef.key && Object.prototype.hasOwnProperty.call(vessel, columnDef.key)) {
          let displayVal = vessel[columnDef.key];
          return displayVal; // || "";
        }

        return "";
      },
      initializeJsonModal() {
        let now = new Date();
        let output = this.tableItemsForDisplay.map(vessel => {
          return Object.assign({}, vessel, {
            _lastUpdated: now.toISOString()
          });
        });

        this.jsonOutput = JSON.stringify(output, null, 4);
      },
      copyToClipboard() {
        const el = document.createElement('textarea');
        el.addEventListener('focusin', e => e.stopPropagation());
        el.value = this.jsonOutput;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.clipboardSuccessHandler();
      },
      clipboardSuccessHandler() {
        this.$toasted.show("Clipboard updated!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 3000
        });
      },
    },
    created() {
      let self = this;
      self.showLoadingIndicator = true;
      Vue.$vesselsService.loadAllVesselsFromCache().then(
        (vesselsFromCache) => {
          this.tableItemsFromDb = this.tableItemsForDisplay = vesselsFromCache;
          this.prepFilters(this.tableItemsFromDb);
          self.showLoadingIndicator = false;
        },
        (err) => {
          console.error(err);
          self.showLoadingIndicator = false;
        }
      );
    },
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";

  #vesselsJsonModal {
    .card .card-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .json-output {
      max-height: 400px;
      overflow: auto;
      padding: 1rem;
      white-space: pre;
    }
  }

  .vessels-data-sheet {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    main {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 0;

      nav {
        flex: 0 0 auto;
        background-color: $controlBackground;
        border-bottom: $navBorder;
      }

      .content {
        position: relative;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        min-width: 300px;
        padding: 1rem;

        .nowrap {
          white-space: nowrap;
        }

        .loading-indicator {
          display: flex;
          flex-direction: row;
          margin: 1rem 0;

          .sk-chase {
            width: 20px;
            height: 20px;
            padding: 2px;
            margin: 2px 6px;
            position: relative;
            animation: sk-chase 2.5s infinite linear both;
          }

          .sk-chase-dot {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            animation: sk-chase-dot 2s infinite ease-in-out both;
          }

          .sk-chase-dot:before {
            content: "";
            display: block;
            width: 25%;
            height: 25%;
            background-color: $bsbw_blue_800;
            border-radius: 100%;
            animation: sk-chase-dot-before 2s infinite ease-in-out both;
          }

          .sk-chase-dot:nth-child(1) {
            animation-delay: -1.1s;
          }
          .sk-chase-dot:nth-child(2) {
            animation-delay: -1s;
          }
          .sk-chase-dot:nth-child(3) {
            animation-delay: -0.9s;
          }
          .sk-chase-dot:nth-child(4) {
            animation-delay: -0.8s;
          }
          .sk-chase-dot:nth-child(5) {
            animation-delay: -0.7s;
          }
          .sk-chase-dot:nth-child(6) {
            animation-delay: -0.6s;
          }
          .sk-chase-dot:nth-child(1):before {
            animation-delay: -1.1s;
          }
          .sk-chase-dot:nth-child(2):before {
            animation-delay: -1s;
          }
          .sk-chase-dot:nth-child(3):before {
            animation-delay: -0.9s;
          }
          .sk-chase-dot:nth-child(4):before {
            animation-delay: -0.8s;
          }
          .sk-chase-dot:nth-child(5):before {
            animation-delay: -0.7s;
          }
          .sk-chase-dot:nth-child(6):before {
            animation-delay: -0.6s;
          }

          @keyframes sk-chase {
            100% {
              transform: rotate(360deg);
            }
          }

          @keyframes sk-chase-dot {
            80%,
            100% {
              transform: rotate(360deg);
            }
          }

          @keyframes sk-chase-dot-before {
            50% {
              transform: scale(0.4);
            }
            100%,
            0% {
              transform: scale(1);
            }
          }
        }

        .table-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          overflow-x: auto;
          overflow-y: auto;

          table {
            border: 1px solid #ccc;
            border-collapse: separate;
            border-spacing: 0;

            th,
            td {
              padding: 4px 8px;
              min-width: 30px;
              white-space: nowrap;
              border: 1px solid #ccc;
            }

            thead {
              th {
                position: -webkit-sticky; /* for Safari */
                position: sticky;
                top: 0;
                background-color: #78a4bf;
                color: #000;

                &:first-child {
                  left: 0;
                  z-index: 1;
                }
              }
            }

            tbody {
              tr {
                background-color: #fff;

                &:nth-child(even):not(.selected) {
                  th,
                  td {
                    background-color: #f1f1f1;
                  }
                }

                &.selected {
                  background-color: #81bce0;

                  th {
                    background-color: #81bce0;
                  }
                }

                &:not(.selected) td[variant="info"] {
                  background-color: #d3e0e9;
                }

                &:hover {
                  cursor: pointer;
                }

                th {
                  position: -webkit-sticky; /* for Safari */
                  position: sticky;
                  left: 0;
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
