<template>
  <div>
    <img :src="flagImgPath" alt="Nation Flag" v-bind:title="flagImgLabel" @error="fixBrokenLink" v-if="flagImgPath !== null && (!flagImgData || showAll === true)">
    <img :src="flagImgData" alt="Nation Flag (base64)" v-bind:title="flagImgLabel" v-if="flagImgData !== null || showAll === true">
  </div>
</template>

<script>
  import Vue from "vue";
  import firebase from "firebase/app";
  import "firebase/storage";
  import CommonConstants from '../common/constants';

  let assets = require.context("@/assets", true, /\.jpg$/);

  export default {
    name: "FlagImg",
    props: ["nation", "showAll"],
    emits: ['loaded'],
    data: function() {
      return {
        flagImgPath: null,
        flagImgLabel: null,
        flagImgData: null
      };
    },
    methods: {
      fixBrokenLink() {
        this.setDefaultPlaceholder();
      },
      setDefaultPlaceholder() {
        this.flagImgPath = assets("./placeholder.jpg");
        this.flagImgData = null;
      },
      saveToLocalCache(cacheItemKey, cacheItemData) {
        Vue.$utilService.setLocalCacheItem(CommonConstants.LF_FLAG_IMG_CACHE, cacheItemKey, cacheItemData);
      },
      downloadImageFromGCPStorageBucket(imgRef, imgStorageBucketRef, imgStorageBucketMetadataLastUpdated) {
        let self = this;
        
        Vue.$utilService.downloadImageFromGCPStorageBucket(imgRef, imgStorageBucketRef, imgStorageBucketMetadataLastUpdated, CommonConstants.LF_FLAG_IMG_CACHE)
          .then(
            (resp) => {
              self.flagImgData = resp.imgData;
              self.$emit('loaded', { imgRef: imgRef, imgData: resp.imgData, isPlaceholder: false });
            },
            () => {
              self.setDefaultPlaceholder();
              self.$emit('loaded', { imgRef: imgRef, imgData: null, isPlaceholder: true });
            }
          );
      },
      setFlagImgLabel() {
        this.flagImgLabel = (typeof this.nation === 'string' && this.nation.length > 0) ? this.nation : "Flag Image";
      },
      setFlagImgPath() {
        let self = this;

        if(this.nation) {
          try {
            let assetsConfig = this.$store.state.config.assets;
            let imgKey = this.nation;
            let imgBasePath = assetsConfig !== null ? assetsConfig.flags : 'flags/';
            let imgExt = assetsConfig !== null ? assetsConfig.flagsImgExt : '.jpg';

            // Check if an asset exists for this flag in the GCP storage bucket
            let imgStorageBucketRef = firebase.storage().ref().child(imgBasePath + imgKey + imgExt);
            imgStorageBucketRef.getMetadata()
              .then(
                (metadataResp) => {
                  // File exists in GCP storage bucket.
                  self.flagImgPath = assetsConfig !== null ? (assetsConfig.base + imgBasePath + imgKey + imgExt) : ("https://storage.cloud.google.com/" + metadataResp.bucket + "/" + metadataResp.fullPath);
                  self.flagImgData = null;

                  // Check for corresponding data in the browser's local cache
                  Vue.$utilService.getLocalCacheItem(CommonConstants.LF_FLAG_IMG_CACHE, imgKey)
                    .then((cachedData) => {
                      if(cachedData && cachedData.cacheItemData && cachedData.cacheItemData.gcpStorageLastUpdated === metadataResp.updated && cachedData.cacheItemData.data) {
                        // Use the img data stored in the browser's local cache
                        self.flagImgData = cachedData.cacheItemData.data;
                        self.$emit('loaded', { imgRef: imgKey, imgData: cachedData.cacheItemData.data, isPlaceholder: false });
                      } else {
                        // We need to update the browser's local cache. Download the image from the GCP storage bucket.
                        self.downloadImageFromGCPStorageBucket(imgKey, imgStorageBucketRef, metadataResp.updated);
                      }
                    }, (e) => {
                      if(e.status === 404) {
                        // A corresponding record was not found in the browser's local storage. Download the image from the GCP storage bucket.
                        self.downloadImageFromGCPStorageBucket(imgKey, imgStorageBucketRef, metadataResp.updated);
                      }
                    });
                },
                (/* metadataErr */) => {
                  // There was a problem accessing the requested file metadata in the GCP storage bucket
                  self.setDefaultPlaceholder();
                  self.$emit('loaded', { imgRef: imgKey, imgData: null, isPlaceholder: true });
                });

          } catch (e) {
            this.setDefaultPlaceholder();
            self.$emit('loaded', { imgRef: null, imgData: null, isPlaceholder: true });
          }
        } else {
          this.setDefaultPlaceholder();
          self.$emit('loaded', { imgRef: null, imgData: null, isPlaceholder: true });
        }
      }
    },
    watch: {
      nation: function(/*nation*/) {
        this.setFlagImgPath();
        this.setFlagImgLabel();
      }
    },
    created() {
      this.setFlagImgPath();
      this.setFlagImgLabel();
    }
  };
</script>
