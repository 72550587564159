<template>
  <footer class="app-footer">
    <div class="app-footer-content">
      <span>This site is owned, operated, and copyright by Deadly Warfare Games. By registering, accessing, and using this Account, you are agreeing to Deadly Warfare's&nbsp;</span><span class="conditions-link" v-b-modal.conditionsModal>conditions of use</span><span>.</span>
    </div>

    <b-modal
      id="conditionsModal"
      title="Conditions of Use"
      header-bg-variant="dark"
      header-text-variant="light"
      ok-only
      ok-title="Close"
      centered
    ><TermsOfUse></TermsOfUse></b-modal>
  </footer>
</template>

<script>
  import TermsOfUse from "../components/TermsOfUse.vue";
  export default {
    name: "AppFooter",
    components: { TermsOfUse }
  };
</script>

<style lang="scss">
  .app-footer {
    display: block;
    position: relative;
    padding: 10px;
    background-color: #343a40;

    .app-footer-content {
      text-align: center;
      font-size: 0.8em;
      color: #dcdcdc;

      .conditions-link {
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
</style>
