<template>
  <div>
    <b-modal
      id="editVesselSetModal"
      title="Edit Vessel Set"
      size="lg"
      header-bg-variant="dark"
      header-text-variant="light"
      @ok="saveVesselSet"
      ok-title="Save"
      cancel-variant="outline-secondary"
      v-bind:ok-disabled="inDm"
      @shown="initializeVesselSet"
    >
      <b-alert
        :show="invalidMsgDismissCountDown"
        dismissible
        variant="danger"
        @dismissed="invalidMsgDismissCountDown = 0"
        @dismiss-count-down="invalidMsgCountDownChanged"
        >Please enter a valid name in order to continue.</b-alert
      >

      <form>
        <b-alert variant="primary" v-if="!inDm" show>
          <span>Be sure to click the&nbsp;</span>
          <b>Save</b>
          <span
            >&nbsp;button at the bottom of this modal to commit changes you've
            made to your vessel set.</span
          >
        </b-alert>
        <b-alert variant="danger" v-if="inDm == true" show
          >You are viewing this modal in Preview mode. Saving changes to this
          vessel set has been disabled.</b-alert
        >
        <b-form-group
          id="vesselSetName"
          label="Vessel Set Name"
          label-class="font-weight-bold"
          label-for="vesselSetNameInput"
        >
          <p class="small">Maximum Length: 50 characters</p>
          <b-form-input
            id="vesselSetNameInput"
            type="text"
            ref="vesselSetName"
            v-model.trim="sName"
            :state="vesselSetNameIsValid"
            placeholder="Enter a name for your vessel set"
            aria-describedby="sNameLiveFeedback"
            maxlength="50"
          ></b-form-input>

          <b-form-invalid-feedback id="sNameLiveFeedback"
            >The Vessel Set name must be unique. Another set with that name
            already exists.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          id="vesselSetDesc"
          label="Vessel Set Description"
          label-class="font-weight-bold"
          label-for="vesselSetDescInput"
        >
          <p class="small">Maximum Length: 300 characters</p>
          <b-form-textarea
            id="vesselSetDescInput"
            type="text"
            v-model="sDesc"
            placeholder="Enter a description for your vessel set"
            :rows="3"
            :max-rows="6"
            maxlength="300"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="vesselSetVessels"
          label="Vessels"
          label-class="font-weight-bold"
          label-for="vesselSetVesselsList"
        >
          <div class="mb-3 d-flex justify-content-end">
            <b-button
              class="d-flex align-items-baseline"
              variant="outline-primary"
              size="sm"
              v-on:click="toggleSelectedVesselsList"
            >
              <div v-if="!showSelectedVesselsList">
                <i class="fas fa-toggle-off mr-2"></i>Show Selected Vessels
              </div>
              <div v-if="showSelectedVesselsList">
                <i class="fas fa-toggle-on mr-2"></i>Show All Vessels
              </div>
            </b-button>
          </div>

          <div class="small mb-3">
            Selected vessel count: {{ selectedVessels.length }}
          </div>

          <b-alert variant="primary" show
            >Click a vessel in the list below to toggle its selected state. A
            Blue list item indicates the vessel will be included in this vessel
            set.</b-alert
          >

          <b-list-group v-if="showSelectedVesselsList" class="mb-3" tag="ul">
            <b-list-group-item
              v-bind:class="{ active: vessel.selected }"
              v-for="vessel of selectedVessels"
              :key="vessel.data.key"
              tag="li"
              button
              v-on:click="toggleSelectedVessel($event, vessel)"
            >
              {{ getVesselLabel(vessel.data.key) }}
            </b-list-group-item>
          </b-list-group>

          <b-alert
            :show="showSelectedVesselsList && !selectedVessels.length"
            variant="info"
            >There are no vessels selected.</b-alert
          >

          <div v-show="!showSelectedVesselsList">
            <b-container fluid class="mb-3">
              <b-row class="my-1">
                <b-col sm="3">
                  <label for="dataVer">Dataset Version:</label>
                </b-col>
                <b-col sm="9">
                  <b-form-select
                    v-model="filterVersion"
                    :options="filterVersionOptions"
                  ></b-form-select>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="3">
                  <label for="vNat">Nation:</label>
                </b-col>
                <b-col sm="9">
                  <b-form-select
                    v-model="filterNation"
                    :options="filterNationOptions"
                  ></b-form-select>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="3">
                  <label for="vClass">Class:</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    id="vClass"
                    type="text"
                    v-model="filterKeyword"
                    size="sm"
                    placeholder="Vessel name/class"
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12 d-flex justify-content-end">
                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    v-on:click="clearFilter"
                    :disabled="
                      !filterKeyword.length &&
                      !filterNation.length &&
                      !filterVersion.length
                    "
                    >Clear Filters</b-button
                  >
                </b-col>
              </b-row>
            </b-container>

            <b-alert
              :show="
                (filterKeyword.length ||
                  filterNation.length ||
                  filterVersion.length) &&
                !filteredVessels.length
              "
              variant="danger"
              >No vessels were found that match your filter.</b-alert
            >

            <div class="mb-3 d-flex justify-content-end">
              <b-button
                class="d-flex align-items-baseline mr-2"
                variant="outline-primary"
                size="sm"
                v-on:click="deSelectAllVessels"
              >
                <i class="far fa-square mr-2"></i>Select None
              </b-button>

              <b-button
                class="d-flex align-items-baseline"
                variant="outline-primary"
                size="sm"
                v-on:click="selectAllVessels($event)"
              >
                <i class="far fa-check-square mr-2"></i>Select All
              </b-button>
            </div>

            <b-list-group id="vesselSetVesselsList">
              <b-list-group-item
                v-bind:class="{ active: vessel.selected }"
                button
                v-for="vessel of filteredVessels"
                :key="vessel.data.key"
                v-on:click="toggleSelectedVessel($event, vessel)"
                >{{ getVesselLabel(vessel.data.key) }}</b-list-group-item
              >
            </b-list-group>
          </div>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import Vue from "vue";

  export default {
    name: "EditVesselSetModal",
    props: ["vesselSet"],
    data: function () {
      return {
        invalidMsgDismissSecs: 4,
        invalidMsgDismissCountDown: 0,
        sName: "",
        sNameOrig: "",
        sDesc: "",
        sDescOrig: "",
        showSelectedVesselsList: false,
        vessels: [],
        filterKeyword: "",
        filterVersion: "",
        filterVersionOptions: [],
        filterNation: "",
        filterNationOptions: [{ value: "", text: "Select a nation" }],
      };
    },
    computed: {
      inDm: {
        get() {
          return this.$store.state.user.inDm;
        },
      },
      vesselSetNameIsValid() {
        if (!this.sName.length) {
          return null;
        }

        if (this.sName === this.sNameOrig) {
          return true;
        }

        let matchingVesselSet = this.sName.length
          ? this.$store.state.sets.vesselSetKeys.find(
              (vesselSet) => vesselSet.label === this.sName
            )
          : null;
        return matchingVesselSet === undefined;
      },
      filteredVessels() {
        let classFilterEntered =
          this.filterKeyword && this.filterKeyword.length > 0;
        let nationFilterEntered =
          this.filterNation && this.filterNation.length > 0;
        let versionFilterEntered =
          this.filterVersion && this.filterVersion.length > 0;

        let items = this.vessels;

        // Filter the list
        if (classFilterEntered) {
          items = items.filter((vessel) => {
            let vesselMetadata = Vue.$vesselsService.getVesselMetadata(
              vessel.data.key
            );

            return (
              vesselMetadata &&
              vesselMetadata.cls
                .toLowerCase()
                .indexOf(this.filterKeyword.toLowerCase()) >= 0
            );
          });
        }

        if (nationFilterEntered) {
          items = items.filter((vessel) => {
            let vesselMetadata = Vue.$vesselsService.getVesselMetadata(
              vessel.data.key
            );

            return (
              vesselMetadata &&
              vesselMetadata.nat.toLowerCase() === this.filterNation.toLowerCase()
            );
          });
        }

        if (versionFilterEntered) {
          items = items.filter((vessel) => {
            let vesselMetadata = Vue.$vesselsService.getVesselMetadata(
              vessel.data.key
            );

            return (
              vesselMetadata &&
              vesselMetadata._version.toLowerCase() ===
                this.filterVersion.toLowerCase()
            );
          });
        }

        return items;
      },
      selectedVessels() {
        return this.vessels.filter((vessel) => vessel.selected);
      },
    },
    methods: {
      initializeVesselSet() {
        this.clearFilter();
        this.showSelectedVesselsList = false;

        if (this.vesselSet) {
          this.sName = this.sNameOrig = this.vesselSet.label;
          this.sDesc = this.sDescOrig = this.vesselSet.desc;
          let vesselsForDisplay = [];
          this.filterVersionOptions = Vue.$vesselsService.getVesselsDataVersions();
          let nations = [];
          let allVessels = Vue.$vesselsService.getVesselsMetadata();

          if (Array.isArray(allVessels)) {
            let vesselSetVessels = Array.isArray(this.vesselSet.vessels)
              ? this.vesselSet.vessels.map((vessel) => vessel.key)
              : [];

            allVessels.forEach((aVessel) => {
              vesselsForDisplay.push({
                selected: vesselSetVessels.indexOf(aVessel.key) !== -1,
                data: { key: aVessel.key, label: aVessel.label },
              });

              nations.push(aVessel.nat);
            });
          }

          nations = [...new Set(nations)];
          this.filterNationOptions = this.filterNationOptions.concat(nations);
          this.vessels = vesselsForDisplay;
          this.$refs.vesselSetName.focus();
        }
      },
      clearFilter() {
        this.filterKeyword = "";
        this.filterNation = "";
        this.filterVersion = "";
      },
      saveVesselSet(event) {
        if (this.inDm) {
          return;
        }
        if (!this.vesselSetNameIsValid) {
          event.preventDefault();
          this.invalidMsgDismissCountDown = this.invalidMsgDismissSecs;
          return;
        }
        let vesselSetUpdateData = {
          key: this.vesselSet.key,
          sName: null,
          sDesc: null,
          vessels: null,
        };

        let allVessels = Vue.$vesselsService.getVesselsMetadata();

        if (Array.isArray(allVessels) && allVessels.length) {
          vesselSetUpdateData.vessels = this.vessels
            .filter((vessel) => vessel.selected)
            .map((vessel) => vessel.data.key);
        }

        if (this.sName !== this.sNameOrig) {
          vesselSetUpdateData.sName = this.sName;
        }

        if (this.sDesc !== this.sDescOrig) {
          vesselSetUpdateData.sDesc = this.sDesc;
        }

        this.$store.dispatch("sets/updateVesselSet", vesselSetUpdateData);
      },
      toggleSelectedVessel(event, vessel) {
        event.preventDefault();
        vessel.selected = !vessel.selected;
      },
      invalidMsgCountDownChanged(dismissCountDown) {
        this.invalidMsgDismissCountDown = dismissCountDown;
      },
      toggleSelectedVesselsList() {
        this.showSelectedVesselsList = !this.showSelectedVesselsList;
      },
      getVesselLabel(vesselKey) {
        return Vue.$vesselsService.getVesselLabel(vesselKey);
      },
      selectAllVessels(event) {
        event.preventDefault();
        if (Array.isArray(this.filteredVessels)) {
          this.filteredVessels.forEach((vessel) => {
            vessel.selected = true;
          });
        }
      },
      deSelectAllVessels() {
        if (Array.isArray(this.filteredVessels)) {
          this.filteredVessels.forEach((vessel) => {
            vessel.selected = false;
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  @import "../assets/bsbw.globals.scss";
  #editVesselSetModal {
    .modal-body {
      min-height: 0;
      max-height: 80vh;
      overflow-y: auto;

      form {
        .alert {
          font-size: 0.8em;
          padding: 10px;
        }
      }

      .vessels-filter {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 20px;

        input {
          margin-right: 10px;
        }
      }

      .list-group {
        max-height: 400px;
        overflow-y: auto;

        .list-group-item {
          flex: 0 0 auto;
          &:hover {
            background-color: $bsbw_blue_400;
            cursor: pointer;
          }

          &.active {
            background-color: $bsbw_blue_800;
            border-color: $bsbw_blue_800;
          }
        }
      }
    }
  }
</style>
