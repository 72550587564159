<template>
  <div class="app-main mini-tables">
    <main>
      <b-navbar type="light" variant="light" toggleable="sm" class="p-2">
        <b-navbar-brand class="mb-0 p-0" tag="h1">Tables/Blank Cards</b-navbar-brand>

        <b-navbar-toggle target="actions-collapse"></b-navbar-toggle>

        <b-collapse is-nav id="actions-collapse">
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-button
                class="d-flex align-items-baseline h-100"
                variant="outline-primary"
                size="sm"
                v-on:click="print"
                alt="Print"
              >
                <i class="fas fa-print mr-1"></i>Print
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <div class="content">
        <b-tabs v-model="activeTabIndex" v-if="gameCards && gameCardTypes">
          <b-tab
            v-for="gcType of gameCardTypes"
            v-bind:key="gcType.key"
            v-bind:title="gcType.label"
          >
            <div class="tab-content">
              <b-card
                no-body
                v-for="gameCard of gameCards[gcType.key]"
                :key="gameCard.key"
                :title="gameCard.label"
              >
                <h2 class="h4">
                  {{ gameCard.label }}
                </h2>
                <div class="mt-2">
                  <GameCardImg
                    :game-card-type="gcType"
                    :game-card-config="gameCard"
                    game-card-ext-type="JPG"
                    :inline-style="gameCardInlineStyle"
                  ></GameCardImg>
                </div>
              </b-card>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </main>
  </div>
</template>

<script>
import GameCardImg from "../components/GameCardImg";

export default {
  name: "MiniTables",
  components: { GameCardImg },
  data() {
    return {
      activeTabIndex: 0,
      gameCardInlineStyle: {
        width: "100%",
        maxWidth: "465px",
        height: "auto",
      },
    };
  },
  computed: {
    gameCards: {
      get() {
        return this.$store.state.config.gameCards;
      },
    },
    gameCardTypes: {
      get() {
        return this.$store.state.config.gameCardTypes;
      },
    },
  },
  methods: {
    print() {
      this.$store.dispatch("print/openPrintPreview", {
        mode: "mini-tables"
      });
    },
  },
};
</script>

<style lang="scss">
@import "../assets/bsbw.globals.scss";

.mini-tables {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  main {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;

    nav {
      flex: 0 0 auto;
      background-color: $controlBackground;
      border-bottom: $navBorder;
    }

    .content {
      position: relative;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      min-width: 300px;
      padding: 1rem;

      .tabs {
        .nav-tabs {
          border-bottom: 3px solid $bsbw_blue_800;

          .nav-item .nav-link.active {
            color: #fff;
            background-color: $bsbw_blue_800;
            border-color: $bsbw_blue_800;
          }
        }
      }

      .tab-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

        .card {
          display: block;
          min-width: 8rem;
          margin: 1.5rem;
          padding: 1rem;
          background-color: #fff;
          font-family: sans-serif;
          box-shadow:
            1.1px 1.1px 1.4px rgba(0, 0, 0, 0.011),
            2.7px 2.7px 3.3px rgba(0, 0, 0, 0.016),
            5px 5px 6.3px rgba(0, 0, 0, 0.02),
            8.9px 8.9px 11.2px rgba(0, 0, 0, 0.024),
            16.7px 16.7px 20.9px rgba(0, 0, 0, 0.029),
            40px 40px 50px rgba(0, 0, 0, 0.04)
          ;
          border-radius: 0.25rem;
        }
      }
    }
  }
}
</style>
