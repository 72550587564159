<template>
  <div>
    <b-modal
      id="addVesselSetModal"
      title="Add New Vessel Set"
      header-bg-variant="dark"
      header-text-variant="light"
      @ok="addVesselSet"
      ok-title="Add"
      cancel-variant="outline-secondary"
      @shown="initializeModal"
    >
      <b-alert
        :show="invalidMsgDismissCountDown"
        dismissible
        variant="danger"
        @dismissed="invalidMsgDismissCountDown=0"
        @dismiss-count-down="invalidMsgCountDownChanged"
      >Please enter a valid name in order to continue.</b-alert>

      <form>
        <div class="form-group">
          <b-form-input
            type="text"
            v-model.trim="sName"
            :state="vesselSetNameIsValid"
            placeholder="Enter name"
            aria-describedby="sNameLiveFeedback"
            ref="vesselSetName"
            maxlength="50"
          ></b-form-input>

          <b-form-invalid-feedback
            id="sNameLiveFeedback"
          >The name must be unique. A vessel set with that name already exists.</b-form-invalid-feedback>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: "AddVesselSetModal",
    data: function() {
      return {
        invalidMsgDismissSecs: 4,
        invalidMsgDismissCountDown: 0,
        sName: ""
      };
    },
    computed: {
      vesselSetNameIsValid() {
        if (!this.sName.length) {
          return null;
        }

        let matchingVesselSet = this.sName.length
          ? this.$store.state.sets.vesselSetKeys.find(
              vesselSet => vesselSet.label === this.sName
            )
          : null;
        return matchingVesselSet === undefined;
      }
    },
    methods: {
      addVesselSet(event) {
        if (!this.vesselSetNameIsValid) {
          event.preventDefault();
          this.invalidMsgDismissCountDown = this.invalidMsgDismissSecs;
          return;
        }
        this.$store.dispatch("sets/addNewVesselSet", this.sName);
      },
      initializeModal() {
        this.sName = "";
        this.$refs.vesselSetName.focus();
      },
      invalidMsgCountDownChanged(dismissCountDown) {
        this.invalidMsgDismissCountDown = dismissCountDown;
      }
    }
  };
</script>
